import { Component, OnInit } from '@angular/core'
import { Router } from '@angular/router'
import { UserEditService } from '../services/user-edit.service'
import { AdminLayoutComponent } from '../layout/admin-layout/admin-layout.component'
import { MatIconRegistry } from '@angular/material/icon'
import { DomSanitizer } from '@angular/platform-browser'
import { MatSnackBar } from '@angular/material/snack-bar'
import Swal from 'sweetalert2'

@Component({
  selector: 'app-user-edit',
  templateUrl: './user-edit.component.html',
  styleUrls: ['./user-edit.component.css'],
})
export class UserEditComponent implements OnInit {
  passwordChange: any
  userid: any
  isChecked: boolean = false
  userFirstName: any
  userLastName: any
  userEmail: any
  dataInfo: any
  userDataInfo: any
  show: boolean = false
  showConfirm: boolean = false

  constructor(
    private router: Router,
    private userEditService: UserEditService,
    private hi_UserNameUpdate: AdminLayoutComponent,
    private iconRegistry: MatIconRegistry,
    sanitizer: DomSanitizer,
    private snackBar: MatSnackBar,
  ) {
    iconRegistry.addSvgIcon('eye-open', sanitizer.bypassSecurityTrustResourceUrl('/assets/icons/eye.svg'),)
    iconRegistry.addSvgIcon('eye-closed', sanitizer.bypassSecurityTrustResourceUrl('/assets/icons/eye-off.svg'),)
  }

  ngOnInit(): void {
    this.userid = 0
    this.getUser()
    let rolUser: any = localStorage.getItem('rol')
    if (!localStorage.getItem('token')) {
      this.router.navigate(['/login'])
    } /*else if(rolUser == 2) {
      this.router.navigate(['/reports-invoice'])
    }*/
    //this.passwordChange = true
  }

  alertValidate(messageAlert: string) {
    this.snackBar.open(messageAlert, 'X', {
      duration: 3000,
      panelClass: ['red-snackbar'],
      verticalPosition: 'top', // Allowed values are  'top' | 'bottom'
      horizontalPosition: 'center',
    })
  }

  allowPasswordChange() {
    this.isChecked = !this.isChecked

    if (this.isChecked) {
      this.passwordChange = true
    } else {
      this.passwordChange = false
    }
  }

  ShowPassword() {
    this.show = !this.show
  }
  ShowPasswordTwo() {
    this.showConfirm = !this.showConfirm
  }

  create(registerForm: any) {
    this.userEditService.createUser(registerForm).subscribe({
      //next/error: is the modern way of doing requests
      //if you do the old way ".subscribe((res) => {res})" and try to pass more than one response subscribe will appear depricated.
      next: (data) => {
        //  console.log('Welcome dood!')

        this.router.navigate(['/companyregister', data])
      },
      error: (error) => {
        //This runs when the "isAuth" middleware returns 400 bad request as a result of session being invalid
        console.log('Error: ')
        console.log(error)
        //  return this.router.navigate(['/login']);
      },
    })
  }

  getUser() {
    console.log('In the get User')
    this.userEditService.getUserData().subscribe({
      next: (data) => {
        this.dataInfo = data
        console.log('Obtained User info: ')
        console.log(this.dataInfo)
        console.log(this.dataInfo.userlastname)
        //Sets the fields of the profile with current users info
        this.userFirstName = this.dataInfo.userfirstname
        this.userLastName = this.dataInfo.userlastname
        this.userEmail = this.dataInfo.useremail
        this.hi_UserNameUpdate.getUser()
      },
      error: (error) => {
        console.log('Error:')
        console.log(error)
        localStorage.removeItem('token')
        localStorage.removeItem('rol')
        return this.router.navigate(['/login'])
      },
    })
  }

  edit(editForm: any) {
    let langVery = sessionStorage.getItem('lang');
    if (this.passwordChange == true) {
      if (editForm.password == '' || editForm.confirmpassword == '') {
        this.alertValidate(langVery == 'es' ? 'Por favor rellenar los campos de contraseña' : 'Please fill in the password fields')
      } else if (editForm.password != editForm.confirmpassword) {
        this.alertValidate(langVery == 'es' ? 'Las contraseñas no coinciden' : 'Passwords do not match')
      } else if (editForm.firstname == '' || editForm.lastname == '' || editForm.email == '') {
        this.alertValidate(langVery == 'es' ? 'Por favor rellenar todos los campos' : 'Please fill in all the fields')
      } else {
        this.userEditService.editUser(editForm).subscribe({
          next: (data) => {
            console.log('Changes to user?: ')
            this.getUser()
            console.log(data)
            Swal.fire({
              icon: 'success',
              title: langVery == 'es' ? 'Bien!' : 'Good!',
              text: langVery == 'es' ? 'Tu contraseña fue actualizada con éxito!' : 'Your password was successfully updated!',
            })
          },
          error: (error) => {
            console.log('Error: ')
            console.log(error)
          },
        })
      }
    } else {
      if (editForm.firstname == '' || editForm.lastname == '' || editForm.email == '') {
        this.alertValidate(langVery == 'es' ? 'Por favor rellenar todos los campos' : 'Please fill in all the fields')
      } else {
        this.userEditService.editUser(editForm).subscribe({
          next: (data) => {
            console.log('Changes to user?: ')
            this.getUser()
            console.log(data)
            Swal.fire({
              icon: 'success',
              title: langVery == 'es' ? 'Bien!' : 'Good!',
              text: langVery == 'es' ? 'Tu datos fueron actualizados con éxito!' : 'Your data was successfully updated!',
            })
          },
          error: (error) => {
            console.log('Error: ')
            console.log(error)
          },
        })
      }
    }
  }
}
