import { Component, OnInit } from '@angular/core'
import { LoginService } from '../services/login.service'
import { Router } from '@angular/router'
import { DomSanitizer } from '@angular/platform-browser'
import { MatIconRegistry } from '@angular/material/icon'
import { Hello } from '../hello'
import Swal from 'sweetalert2'
import { TranslateService } from '@ngx-translate/core'

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css'],
})
export class LoginComponent implements OnInit {
  //This seems to be akin to hooks from react
  content: string = ''
  value: any
  userInfo: any
  deletecookie: any
  useremail: string
  userpassword: string
  loggedIn: boolean
  show: boolean = false
  loginFail: boolean = false

  //Use this to attach an import to a variable
  constructor(
    private loginService: LoginService,
    private router: Router,
    private iconRegistry: MatIconRegistry,
    private translate: TranslateService,
    sanitizer: DomSanitizer,
  ) {
    iconRegistry.addSvgIcon(
      'eye-open',
      sanitizer.bypassSecurityTrustResourceUrl('/assets/icons/eye.svg'),
    )
    iconRegistry.addSvgIcon(
      'eye-closed',
      sanitizer.bypassSecurityTrustResourceUrl('/assets/icons/eye-off.svg'),
    )
  }

  //This seems to funciton like a useEffect()
  ngOnInit(): void {
   /* let langVery = sessionStorage.getItem('lang');
    Swal.fire({
      icon: 'info',
     showDenyButton: true,
      title:  langVery == 'es' ? 'Bienvenido/a' : 'Welcome',
      html: langVery == 'es' ? '<p>La aplicación está en mantenimiento, intente luego. Disculpe los inconvenientes.</p>' : '<p>The application is under maintenance, please try again later. Sorry for the inconvenience.</p>',
      //denyButtonText: `Go to english version`,
      allowOutsideClick: false,
      showConfirmButton: false,
      allowEscapeKey: false,
      denyButtonText: `Go to english version`,
    }).then((result) => {
      if (result.isConfirmed) {
        //Swal.fire('Saved!', '', 'success')
      } else if (result.isDenied) {
        this.changeLang('en')
        Swal.fire({
          icon: 'info',
          title: 'Welcome',
          html: '<p>The application is under maintenance, please try again later. Sorry for the inconvenience.</p>',
          allowOutsideClick: false,
          showConfirmButton: false,
          allowEscapeKey: false,
        })
      }
    })*/
  }

  changeLang(lang: string) {
    let botonES = document.getElementById("es");
    let botonEN = document.getElementById('en');
    if (lang == 'es') {
      botonES.className = "nav-link btn active"
      botonEN.className = "nav-link btn"
    }
    else {
      botonES.className = "nav-link btn"
      botonEN.className = "nav-link btn active"
    }
    sessionStorage.setItem('lang', lang);
    this.translate.use(lang);
  }

  newChangeLabel() {
    let rez
    this.loginService.getTest().subscribe((data) => {
      rez = data
      console.log('This is the response: ' + data)
      this.content = rez
    })
  }

  ShowPassword() {
    this.show = !this.show
  }

  SignIn() {
    const user = {
      useremail: this.useremail,
      userpassword: this.userpassword,
    }
    // console.log(user); //this outputs {username: string, password: string}
    this.loginService.userLog(this.useremail, this.userpassword).subscribe({
      //next/error: is the modern way of doing requests
      //if you do the old way ".subscribe((res) => {res})" and try to pass more than one object subscribe will appear depricated.
      next: (res) => {
        //  console.log(res);
        var token: any = res
        if (token) {
          localStorage.setItem('token', token.token)
          localStorage.setItem('rol', token.rol)
          console.log('RES.DATA:')
          console.log(token.rol)
          console.log(res)
          if(token.rol == 2) {
            this.router.navigate(['/reports-invoice'])
          } else {
            this.router.navigate(['/payment'])  
          }
          
        } else {
          console.log('You shall not pass!!!')
        }
      },
      error: (error) => {
        this.loginFail = true
        console.log('Error: ')
        console.log('You stink')
        console.log(error)
      },
    })
  }
}
