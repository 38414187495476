<div class="container">
  <div class="row justify-content-center">
    <h1 class="h1 text-primary text-center">
      {{ 'Forgot_your_password' | translate }}
    </h1>
    <h5 class="h5 text-center">
      {{ 'Please_enter_your_e-mail_below_to_continue' | translate }}
    </h5>
    <br />
    <br />
    <div class="col"></div>

    <div class="col justify-content-center">
      <div class="card">
        <div class="card-header text-center">
          <img
            src="../../assets/logo_conwaste_new.png"
            class="card-img-top"
            style="width: 300px !important;"
            alt=""
          />
        </div>
        <div class="card-body">
          <!--<form class="form" action="https://localhost:3000/register" method="POST" autocomplete="off">-->
          <form #gfg="ngForm" class="form" (ngSubmit)="resetPassword(gfg.value)">
            <div class="form-group">
              <label for="Title">{{ 'E-mail' | translate }}</label>
              <div class="input-group">
                <span class="input-group-text" id="basic-addon5">
                  <i class="bi bi-envelope-fill"></i>
                </span>
                <input type="email" class="form-control" name="email" #EmailReset />
              </div>
            </div>
            <br />
            <div class="text-center">
              <button type="button" (click)="resetPassword(gfg.value)" [disabled]="buttonSend"
              class="btn btn-r btn-lg btn-primary">{{ 'Reset_my_password' | translate }}</button>-
              <!--<input
                type="submit"
                class="btn btn-r btn-lg btn-primary"
                value="{{ 'Reset_my_password' | translate }}"
              />
              <input
                routerLink="/reset"
                type="submit"
                class="btn btn-r btn-lg btn-primary"
                value="{{ 'Reset_my_password' | translate }}"
              />-->
            </div>
         </form>
        </div>
      </div>
    </div>
    <div class="col"></div>
  </div>
</div>
