<div class="container">
  <div class="row justify-content-center">
    <h5 class="h5 text-left">{{ 'Pay_my_invoice' | translate }}</h5>
    <br />
    <br />
    <div>
      <label for="cliente">{{ 'Search' | translate }}</label>
    </div>
    <div width="100%">
      <form #registerForm="ngForm" class="form" autocomplete="off">
        <select #company id="company" [(ngModel)]="listcompany" name="company" class="classic" placeholder="Selecciona">
          <option selected>Selecciona</option>
          <option *ngFor="let comp of companies" [ngValue]="comp">
            {{ comp.companyname }}
          </option>
        </select>

        <input #fromdate name="fromdate"  ngModel type="date" style="margin-left: 2%;" />
        <input #todate name="todate" ngModel type="date" style="margin-left: 2%;" />

        <button style="margin-left: 2%;" (click)="searchInvoice(registerForm.value)" class="btn btn-md btn-primary">
          {{ 'Search' | translate }}
        </button>
        <button style="margin-left: 1%;" class="btn btn-md btn-primary" (click)="
            InvoiceData();
            company.value = '';
            fromdate.value = '';
            todate.value = ''
          ">
          {{ 'RefreshTable' | translate }}
          <span class="material-icons" style="display: inline-block;vertical-align: middle;">refresh</span>
        </button>
        <button style="margin-left: 1%;" class="btn btn-md btn-primary" (click)="getCartItems()">
          {{ 'Cart' | translate }}
          <!-- <label *ngIf="list_length">({{ payAmountArray.length }})</label> -->
          <label *ngIf="conteoCarrito">({{ cantidadCarrito }})</label>
          <span class="material-icons" style="display: inline-block;vertical-align: middle;">shopping_cart</span>
        </button>
        <br />
      </form>
    </div>

    <ng-template #content let-modal>
      <div class="modal-header">
        <h4 class="modal-title" id="modal-basic-title">
          {{ 'List_of_Invoices' | translate }}
        </h4>
        <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')"></button>
      </div>
      <div class="modal-body">
        <form>
          <div class="mb-3">
            <div class="input-group">
              <div style="height: 380px; overflow-y: auto;">
                <ol class="biglist space_list" id="list">
                  <ng-container *ngFor="let invoice of payAmountArray">
                    <li>
                      <ul class="nospace">
                        <div>
                          <li>
                            {{ 'Invoice_Number:' | translate }}
                            {{ invoice.invoice_num }}
                          </li>

                          <li>
                            {{ 'Invoice_Amount:' | translate }}
                            ${{ invoice.invoice_amount }}
                          </li>
                          <li>
                            {{ 'Amount_to_pay:' | translate }}
                            {{ invoice.amount_to_pay | currency }}
                          </li>
                        </div>
                        <div>
                          <button style="font-size: small; text-align: center;" type="button"
                            class="material-icons-outlined btn btn-lg btn-danger" (click)="removeInvoice(invoice)">
                            X
                          </button>
                        </div>
                      </ul>
                    </li>
                  </ng-container>
                </ol>
              </div>
            </div>
          </div>
        </form>
      </div>
    </ng-template>

    <div class="col justify-content-center">
      <br />
      <div class="card">
        <div class="card-header">
          <p class="h6">{{ 'Invoices' | translate }}</p>
        </div>
        <div class="card-body text-center">
          <div class="table-responsive">
            <table class="table" id="myTable">
              <thead>
                <tr>
                  <th>
                    {{ 'CustomerName' | translate }}
                  </th>
                  <th>
                    {{ 'Company_Name2' | translate }}
                  </th>
                  <th>
                    {{ 'Invoice_Number' | translate }}
                  </th>
                  <th>
                    {{ 'Invoice_Date' | translate }}
                  </th>
                  <th>
                    {{ 'Invoice_Amount' | translate }}
                  </th>
                  <th>
                    {{ 'PendingAmount' | translate }}
                  </th>
                  <th>
                    {{ 'Amount_to_pay' | translate }}
                  </th>
                  <th>
                    {{ 'Acciones' | translate }}
                  </th>
                </tr>
              </thead>
              <tbody *ngFor="let item of dataToShow">
                <tr *ngIf="dataAvailable">
                  <!--<td>
                    {{ item.Customer.customername }}
                  </td>
                  <td>
                    {{ item.invcompid.companyname }}
                  </td>
                  <td>
                    {{ item.invoicenumber }}
                  </td>
                  <td>
                    {{ item.invoicedate.date | date }}
                  </td>
                  <td>
                    ${{ item.amount }}
                  </td>
                  <td>
                    $ {{item.pendingAmount}}
                  </td>-->
                  <td>
                    {{ item.customername }}
                  </td>
                  <td>
                    {{ item.companyname }}
                  </td>
                  <td>
                    {{ item.invoicenumber }}
                  </td>
                  <td>
                    {{ item.invoicedate | date: "dd-MM-yyyy":"UTC" }}
                  </td>
                  <td>
                    ${{ item.amount | number}}
                  </td>
                  <td>
                    $ {{item.pendingAmount}}
                  </td>
                  <td>
                    <input type="number" #PayMount [(ngModel)]="item.amountToPay"
                      (keyup)="inputAmountToPayValidation(item)" (change)="inputOnChange(item)" min="0"
                      placeholder=" 0.00" />
                  </td>
                  <td>
                    <button (click)="AddToCart(item)" class="btn btn-sm btn-primary">
                      <mat-icon>done</mat-icon>
                    </button>
                  </td>
                </tr>
              </tbody>
              <tr *ngIf="dataUnavailable">
                <th colspan="7">{{ 'NoEnvoicesToShow' | translate }}</th>
              </tr>
            </table>

            <div>
              <div class="d-flex justify-content-between p-2 column" style="
                  align-items: center;
                  justify-content: center;
                  display: block;
                ">
                <ngb-pagination [collectionSize]="collectionSize" [(page)]="page" [pageSize]="pageSize"
                  (pageChange)="refreshInvoices()" [maxSize]="5"></ngb-pagination>
              </div>
              <div class="card_for_label column">
                <div class="card" style="height: 40px;color: green;font-weight: 500;">
                  <label style="position: relative; top: 5px;">
                    <!--Total: {{ totalSumInvoice | currency }}-->
                    {{'cartTotal' | translate }}: {{ totalSumInvoiceToCart | currency }}
                  </label>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="input-group mt-2 pt-3 justify-content-end">
        <!-- <button [disabled]="isDisabled" type="button" (click)="sendInvoiceList()" class="btn btn-lg btn-primary">
          {{ 'Process' | translate }}
        </button> -->
      </div>
    </div>
  </div>
</div>