import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { last } from 'rxjs';

@Component({
  selector: 'app-auth-layout',
  templateUrl: './auth-layout.component.html',
})
export class AuthLayoutComponent implements OnInit {
  year = new Date().getFullYear()
  lang: string = '';
  constructor(private translate: TranslateService) {

  }
  // Funcion para cambiar el leenguaje
  changeLang(lang: string) {
    let botonES = document.getElementById("es");
    let botonEN = document.getElementById('en');
    if (lang == 'es') {
      botonES.className = "nav-link btn active"
      botonEN.className = "nav-link btn"
    }
    else {
      botonES.className = "nav-link btn"
      botonEN.className = "nav-link btn active"
    }
    sessionStorage.setItem('lang', lang);
    this.translate.use(lang);
  }

  ngOnInit() {
    this.lang = sessionStorage.getItem('lang');
    this.changeLang(this.lang)
  }
}