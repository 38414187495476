import { NgModule } from '@angular/core'
import { BrowserModule } from '@angular/platform-browser'
import { FormsModule } from '@angular/forms'
import { AppRoutingModule } from './app-routing.module'
import { AppComponent } from './app.component'
//https://www.itsolutionstuff.com/post/how-to-use-jquery-datatable-in-angularexample.html
//import { DataTablesModule } from 'angular-datatables'
import { RegisterComponent } from './register/register.component'
import { LoginComponent } from './login/login.component'
import { AuthLayoutComponent } from './layout/auth-layout/auth-layout.component'
import { AdminLayoutComponent } from './layout/admin-layout/admin-layout.component'
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component'
import { ResetPasswordComponent } from './reset-password/reset-password.component'
import { PaymentComponent } from './payment/payment.component'
import { PayComponent } from './pay/pay.component'
import { InvoiceHistoryComponent } from './invoice-history/invoice-history.component'
import { PayInvoiceHistoryComponent } from './pay-invoice-history/pay-invoice-history.component'
import { CompanyRegisterComponent } from './company-register/company-register.component'
import { PaymentMethodComponent } from './payment-method/payment-method.component'
import { CompanyRegisterLoginComponent } from './company-register-login/company-register-login.component'

import { TranslateHttpLoader } from '@ngx-translate/http-loader'
import { TranslateLoader, TranslateModule } from '@ngx-translate/core'

import {
  HttpClient,
  HttpClientModule,
  HTTP_INTERCEPTORS,
} from '@angular/common/http'
import { NgbModule } from '@ng-bootstrap/ng-bootstrap'
import { StripeComponent } from './stripe/stripe/stripe.component'
import { UserEditComponent } from './user-edit/user-edit.component'
import { NotifierModule } from 'angular-notifier'
import { BrowserAnimationsModule } from '@angular/platform-browser/animations'
import { MatTooltipModule } from '@angular/material/tooltip'
import { MatSnackBarModule } from '@angular/material/snack-bar'
import { MatIconModule } from '@angular/material/icon'
import { MDBBootstrapModule } from 'angular-bootstrap-md';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader'
import { SearchFilterPipe } from './pipe/searchTable';
import { ReportsComponent } from './reports/reports.component';
import { ReportPaymentComponent } from './report-payment/report-payment.component';
import { ReportAccountComponent } from './report-account/report-account.component';
import { PhoneFormatPipe } from './pipe/phone-format.pipe';
import { UsersComponent } from './users/users.component'
import { StripeReportComponent } from './stripe-report/stripe-report.component'

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, 'assets/i18n/', '.json')
}

@NgModule({
  declarations: [
    AppComponent,
    RegisterComponent,
    LoginComponent,
    AuthLayoutComponent,
    AdminLayoutComponent,
    ForgotPasswordComponent,
    ResetPasswordComponent,
    PaymentComponent,
    PayComponent,
    InvoiceHistoryComponent,
    PayInvoiceHistoryComponent,
    CompanyRegisterComponent,
    PaymentMethodComponent,
    CompanyRegisterLoginComponent,
    StripeComponent,
    UserEditComponent,
    ReportsComponent,
    ReportPaymentComponent,
    ReportAccountComponent,
    PhoneFormatPipe,
    UsersComponent,
    StripeReportComponent,
    //SearchFilterPipe,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    MDBBootstrapModule.forRoot(),
    //DataTablesModule,
    HttpClientModule,
    NotifierModule.withConfig({
      // Custom options in here
      position: {
        horizontal: {
          position: 'left',
          distance: 12,
        },
        vertical: {
          position: 'bottom',
          distance: 12,
          gap: 10,
        },
      },
    }),
    FormsModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
    NgbModule,
    MatTooltipModule,
    MatSnackBarModule,
    MatIconModule,
    BrowserAnimationsModule,
    NgxSkeletonLoaderModule,
  ],
  providers: [],
  bootstrap: [AppComponent],
})
export class AppModule {}
