<div class="container">
  <div class="row justify-content-center">
    <h5 class="h5 text-left">{{ 'Invoice_History' | translate }}</h5>
    <!--<div>
      <button (click)="exportAsXLSX()" class="btn btn-primary">{{ 'ReporteH' | translate }}</button>
    </div>-->
    <br />
    <div width="100%" style="display: none;">
      <form
        #registerForm="ngForm"
        (ngSubmit)="searchInvoice(registerForm.value)"
        class="form"
        autocomplete="off"
      >
        <select
          #company
          id="company"
          [(ngModel)]="listcompany"
          name="company"
          class="classic"
        >
          <option *ngFor="let comp of companies" value="{{ comp | json }}">
            {{ comp.companyname }}
          </option>
        </select>

        <input
          #fromdate
          name="fromdate"
          ngModel
          type="date"
          style="margin-left: 2%;"
        />
        <input
          #todate
          name="todate"
          ngModel
          type="date"
          style="margin-left: 2%;"
        />
        <button
          style="margin-left: 2%;"
          type="submit"
          class="btn btn-lg btn-primary"
        >
          {{ 'Search' | translate }}
        </button>
      </form>
    </div>
    <br />
    <div class="col justify-content-center">
      <div class="card">
        <div class="card-header">
          <p class="h6">{{ 'My_Invoices' | translate }}</p>
        </div>
        <div class="card-body text-center">
          <div class="table-responsive">
            <table class="table" id="contentToConvert">
              <thead>
                <tr>
                  <th>ID</th>
                  <th>{{ 'Invoice_Number' | translate }}</th>
                  <th>{{ 'Company_Name2' | translate }}</th>
                  <th>{{ 'Invoice_Date' | translate }}</th>
                  <th>{{ 'Invoice_Amount' | translate }}</th>
                  <th>{{ 'status' | translate }}</th>
                  <th>{{ 'Invoices' | translate }}</th>
                </tr>
              </thead>
              <tbody >
                <tr *ngFor="let item of dataToShow">
                  <td>{{ item.InvoiceID }}</td>
                  <td>{{ item.invoicenumber }}</td>
                  <td>{{ item.companyname }}</td>
                  <td>{{ item.invoicedate | date }}</td>
                  <td>${{ item.amount | number }}</td>
                  <td>{{ item.status | translate }}</td>
                  <td>
                    <!--<button class="btn btn-primary" (click)="enviarData(item)" style="margin-left: 10px;">
                      <span class="material-symbols-outlined">visibility</span>
                    </button>-->
                    <button class="btn btn-primary" (click)="openLg(uploadDocument);readData(item)" style="margin-left: 10px;">
                      <!--<span class="material-symbols-outlined">preview</span>
                      <span class="material-symbols-outlined">visibility</span>-->
                      {{ 'Views_Invoice' | translate }}
                    </button>
                     <!--<button class="btn btn-success" (click)="convetToPDF()" style="margin-left: 10px;">
                      <span class="material-symbols-outlined">download</span>
                    </button>-->
                  </td>
                </tr>
              </tbody>
            </table>
            <div>
              <div class="d-flex justify-content-between p-2 column" style="
                  align-items: center;
                  justify-content: center;
                  display: block;
                ">
                <ngb-pagination [collectionSize]="collectionSize" [(page)]="page" [pageSize]="pageSize"
                  (pageChange)="refreshInvoices()" [maxSize]="15"></ngb-pagination>
              </div>
            </div>
          </div>
          <p>{{msjAlert}}</p>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- HTML DE LA FACTURA -->
<ng-template #uploadDocument let-modal>
  <div class="container mt-3 " id="imprimirTodo" style="background-color: white;border-radius: 15px;" >
    <div class="container-fluid p-2 text-black text-center">
      <div class="row" style="justify-content: center;">
        <div class="col-10">
          <h2 class="invoice-title">FACTURA <span style="text-transform: uppercase;" class="dateInvoiceYear"></span></h2>
        </div>
      </div>
    </div>
    <div style="margin-left:5%;margin-right:5%;margin-bottom: 30px;padding-bottom: 30px;">
      <div class="row">
        <div class="col-sm-4">
          <img src="assets/img/logo_conwaste_new.png" style="width:80%;">
          <p class="p-no-margin">PO Box 366518</p>
          <p class="p-no-margin">San Juan, PR 00936</p>
          <p class="p-no-margin">Telephone: 787-273-7639</p>
        </div>
        <div class="col-sm-3 text-center">
          <img src="assets/img/frame-1.png" style="width:70%;">
          <p style="font-size: 10px;">https://micuenta.conwastepr.com/</p>
        </div>
        <div class="col-sm-5">
          <p class="p-no-margin-2">No. de Factura : <span class="noInvoice colorS"></span></p>
          <p class="p-no-margin-2" >Página :  <span class="page-number">1</span></p>
          <p class="p-no-margin-2">Fecha de Factura : <span class="dateInvoice colorS"></span></p>
          <p class="p-no-margin-2">Número de Cliente : <span class="CustomerNumberInvoice colorS"></span></p>
          <p class="p-no-margin-2">Site Number : <span class="SiteNumberSet colorS"></span></p>
          <p class="p-no-margin-2">Número de Orden (PO) : 0</p>
          <p class="p-no-margin-2">Número de Contrato : <span class="contractor colorS"></span></p>
        </div>
      </div>
      <div class="row">
        <div class="col-sm-8">
          <p class="nameCustomer" style="margin:0"></p>
          <p style="margin:0">Bill To:</p>
          <p class="p-no-margin-3 billToAdress"></p>
        </div>
        <div class="col-sm-4">
          <p class="paid payOrPending"></p>
        </div>
      </div>
      <div class="row" style="margin-top: 0;">
        <div class="col-12">
          <table class="table table-bordered" style="border: 1px solid black;">
            <thead>
              <tr class="trTh">
                <th>Fecha</th>
                <th>Descripción</th>
                <th>Referencia</th>
                <th>Precio</th> 
                <th>Cantidad</th>
                <th>Total</th>
              </tr>
            </thead>
            <tbody class="listTable"></tbody>
            <tr>
              <td colspan="6" style="text-align:center;font-size: 10px;">
                Accede a nuestra nueva plataforma de pago <a href="https://micuenta.conwastepr.com">MICUENTA.CONWASTEPR.COM</a>, donde podrá administrar sus cuentas, acceder a facturas y realizar pagos.Para crear su cuenta, debe utilizar el número de teléfono 
                <strong class="numeroTelefono">(904) 254-2246</strong> y su número de cliente <strong class="CustomerNumberInvoice">113144</strong>.De tener alguna situación, favor escribirnos a 
                <a href="mailto:info@conwastepr.com">info@conwastepr.com</a>.
              </td>
            </tr>
          </table>
          <hr />
        </div>
      </div>
      <div class="row hideScond">
        <div class="col-8">
          <div class="row">
            <div class="col-2">
              <p style="font-weight: bold;">Account Status</p>
            </div>
            <div class="col-10">
              <p style="margin:0;font-size:12px;">Payment due upon receipt of this invoice. 1.5% per month (18% per annum) late charge on balances over 30 days from date of invoice. Payments received after invoice date are not reflected. To ensure proper credit, please include your acount number on your check or include the bottom portion of this invoice.</p>
              <p></p>
            </div>
          </div>
          <div class="row row-down">
            <div class="col-3 col-down">
              <p>Corriente</p>
              <p class="corriente"></p>
            </div>
            <div class="col-3 col-down">
              <p>31 - 60 Dias</p>
              <p class="step1"></p>
            </div>
            <div class="col-3 col-down">
              <p>61 - 90 Dias</p>
              <p class="step2"></p>
            </div>
            <div class="col-3 col-down">
              <p>90 o mas</p>
              <p class="step3"></p>
            </div>
          </div>
        </div>
        <div class="col-4">
          <table class="table table-bordered" style="width:100%;float: right;">
            <tr>
              <th style="color: black;background-color: lightgray;">SUBTOTAL</th>
              <td class="subTotalFactura">$ 0.00</td>
            </tr>
            <tr>
              <th style="color: black;background-color: lightgray;">IVU MUNICIPAL</th>
              <td class="ivuMunicipal">$ 0.00</td>
            </tr>
            <tr>
              <th style="color: black;background-color: lightgray;">IVU ESTATAL</th>
              <td class="ivuEstatal">$ 0.00</td>
            </tr>
            <tr>
              <th style="color: black;background-color: lightgray;">IVU TOTAL</th>
              <td class="ivuTotal">$ 0.00</td>
            </tr>
            <tr>
              <th style="color: black;background-color: lightgray;">TOTAL</th>
              <td class="totalAmount">$ 0.00</td>
            </tr>
          </table>
        </div>
      </div>
      <div class="row hideScond">
        <div class="col-sm-4">
          <p class="p-no-margin-2">No. de Factura : <span class="noInvoice colorS"></span></p>
          <p class="p-no-margin-2" >Página :  <span class="page-number">1</span></p>
          <p class="p-no-margin-2">Fecha de Factura : <span class="dateInvoice colorS"></span></p>
          <p class="p-no-margin-2">Número de Cliente : <span class="CustomerNumberInvoice colorS"></span></p>
          <p class="p-no-margin-2">Site Number : <span class="SiteNumberSet colorS"></span></p>
          <p class="p-no-margin-2">Número de Orden (PO) : <span class="colorS">0</span></p>
        </div>
        <div class="col-sm-4">
          <p style="font-weight:bold;">Por favor, remitir el pago a:</p>
          <p style="font-size:12px;">CONSOLIDATED WASTE SERVICES CORP. PO BOX 366518 San Juan, P. R. 00936</p>
        </div>
        <div class="col-sm-4">
          <p style="font-size:12px;">NOTA: FAVOR IDENTIFICAR SU PAGO CON</p>
          <p style="font-size:12px;">EL NUMERO DE FACTURA CORRESPONDIENTE</p>
          <p style="font-size:12px;" style="font-weight:bold;">Factura <span class="dateInvoiceYear"></span></p>
        </div>
      </div>
    </div>
    </div>
    <div class="row" style="padding: 10px;">
      <div class="col">
        <button type="button" class="btn btn-danger closeModal" (click)="modal.close('close click')">
          <span class="material-symbols-outlined" style="vertical-align: middle;">close</span> {{ 'Cancel' | translate }}
        </button>
      </div>
      <!--<div class="col">
        <button type="button" class="btn btn-warning" (click)="printDiv('imprimirTodo')">
          <span class="material-symbols-outlined" style="vertical-align: middle;">print</span> Imprimir
        </button>
      </div>-->
      <div class="col">
        <button style="float: right;" (click)="convetToPDF()" class="btn btn-success">
          <span class="material-symbols-outlined" style="vertical-align: middle;">download</span> {{ 'download' | translate }}
        </button>
      </div>
    </div>
</ng-template>
