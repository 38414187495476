import { Component, OnInit } from '@angular/core'
import { TranslateService } from '@ngx-translate/core'
import { Router, RouterLinkActive } from '@angular/router'
import { AdminLayoutService } from './admin-layout.service'
import { UserEditService } from '../../services/user-edit.service'
//const jwt = require('jsonwebtoken')

@Component({
  selector: 'app-admin-layout',
  templateUrl: './admin-layout.component.html',
})
export class AdminLayoutComponent implements OnInit {
  year = new Date().getFullYear()
  lang: string = ''
  sessionDestroy: any
  userName: string
  userid: any
  isChecked: boolean = false
  userFirstName: any
  userLastName: any
  userEmail: any
  dataInfo: any
  userDataInfo: any
  token: any
  rol: any

  constructor(
    private translate: TranslateService,
    private adminLayoutService: AdminLayoutService,
    private userEditService: UserEditService,
    private router: Router,
  ) {}
  // Funcion para cambiar el leenguaje
  changeLang(lang: string) {
    let botonES = document.getElementById('es')
    let botonEN = document.getElementById('en')
    if (lang == 'es') {
      botonES.className = 'nav-link btn active'
      botonEN.className = 'nav-link btn'
    } else {
      botonES.className = 'nav-link btn'
      botonEN.className = 'nav-link btn active'
    }
    sessionStorage.setItem('lang', lang)
    this.translate.use(lang)
  }

  ngOnInit() {
    this.lang = sessionStorage.getItem('lang')
    this.rol = localStorage.getItem('rol')
    this.changeLang(this.lang)
    this.getUser()
    this.token = localStorage.getItem('token')

    if(!localStorage.getItem('token')){
      this.router.navigate(['/login'])
    }

    /*jwt.verify(token, secret, (err, decoded) => {
      if (err) {
        console.log(err);
        return res.sendStatus(403);
      }
      user_ID = decoded.userid;
    })*/
  }

  redirectClick() {
    this.router.navigate(['/user-edit'])
  }

  userInfo() {
    this.adminLayoutService.getUserInfo().subscribe({
      next: (data) => {
        this.userName = data

        //  console.log('Welcome dood!')
      },
      error: (error) => {
        //This runs when the "isAuth" middleware returns 400 bad request as a result of session being invalid
        console.log('Error: ')
        console.log('You Stink')
        console.log(error)
        //return this.router.navigate(['/login'])
      },
    })
  }

  getUser() {
    console.log('In the get User')
    this.userEditService.getUserData().subscribe({
      next: (data) => {
        this.dataInfo = data
        console.log('Admin-layout Obtained User info: ')
        this.userFirstName = this.dataInfo.userfirstname
      },
      error: (error) => {
        console.log('Error:')
        console.log(error)
        localStorage.removeItem('token')
         localStorage.removeItem('rol')
         return this.router.navigate(['/login'])
      },
    })
  }

  Logout() {
    //This is missing a method to destroy session
    this.adminLayoutService.destroySession().subscribe((data) => {
      console.log(data)
    })
    localStorage.removeItem('token')
    localStorage.removeItem('rol')
    return this.router.navigate(['/login'])
  }
}
