<div class="container">
  <div class="row justify-content-center">
    <h5 class="h5 text-left">{{ 'Reporte-invoice' | translate }}</h5>
    <form #filterForm="ngForm" class="row form" autocomplete="off"
      style="margin-top: 10px;margin-bottom: 15px;width: 100%;">
      <!--<div class="col-md-3">
        <label for="cliente">{{ 'filter' | translate }}</label>
        <select #filterBy (change)="filterByOnChange(filterBy.value)" name="company" id="filterBy" class="form-select"
          aria-label="Selecciona">
          <option selected>Selecciona</option>
          <option value="CustomerName">{{ 'CustomerName' | translate }}</option>
          <option value="CustomerID">{{ 'NoAccount' | translate }}</option>
          <option value="InvoiceNumber">{{ 'Invoice_Number' | translate }}</option>
        </select>
      </div>
      <div class="col-md-3">
        <label for="cliente">{{ 'List' | translate }}</label>
        <select #company id="company" [(ngModel)]="listcompany" name="company" class="form-select"
          aria-label="Selecciona">
          <option selected>{{ 'Select' | translate }}</option>
          <option *ngFor="let comp of list" [ngValue]="comp">
            {{ comp }}
          </option>
        </select>
      </div>-->
      <div class="col-md-2">
        <label for="cliente">{{ 'To' | translate }}</label>
        <input #fromdate name="fromdate" id="from" class="form-control" ngModel type="date" />
      </div>
      <div class="col-md-2">
        <label for="cliente">{{ 'From' | translate }}</label>
        <input #todate name="todate" id="to" class="form-control" ngModel type="date" />
      </div>
      <div class="col-md-2">
        <button (click)="pendingInvoiceReportByDate(filterForm.value)" class="btn btn-primary" style="margin-top: 25px;">{{ 'Search' | translate }}</button>
      </div>
      <!----<div class="col-md-2">
        <button (click)="refreshData()" class="btn btn-warning" style="margin-top: 25px;">{{ 'RefreshTable' | translate }}</button>
      </div>--->
    </form>


    <div class="col justify-content-center">
      <div class="card">
        <div class="card-header">
        </div>
        <div class="card-body text-center">
          <div class="table-responsive">
            <table class="table" id="mytableInvoice">
              <thead>
                <tr>
                  <th>{{ 'Company_Name2' | translate }}</th>
                  <th>{{ 'CustomerName' | translate }}</th>
                  <th>{{ 'NoAccount' | translate }}</th>
                  <th>{{ 'Invoice_Date' | translate }}</th>
                  <!--<th>{{ 'Invoice_Date' | translate }}</th>-->
                  <th>{{ 'Invoice_Number' | translate }}</th>
                  <th>{{ 'Invoice_Amount' | translate }}</th>
                  <th>{{ 'DayInvoice' | translate }}</th>
                </tr>
              </thead>
              <tbody>
                <th *ngIf="pendingInvoiceReportModel.length == 0" colspan="7">{{ 'NoEnvoicesToShow' | translate }}</th>
                <tr *ngFor="let info of pendingInvoiceReportModel">
                  <td>{{info.CompanyName}}</td>
                  <td>{{info.CustomerName}}</td>
                  <td>{{info.CustomerID}}</td>
                  <td>{{info.Date | date: "yyyy-MM-dd":"UTC"}}</td>
                  <!--<td>{{info.Date | date: "dd-MM-yyyy":"UTC"}}</td>-->
                  <td>{{info.InvoiceNumber}}</td>
                  <td>{{info.Amount | number : '1.2-2'}}</td>
                  <td>{{ info. dias_transcurridos}}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>