<div class="container">

  <!--Modal Privacy-->
  <div mdbModal #privacyModalTwo="mdbModal" class="modal fade top" id="frameModalTop" tabindex="-1" role="dialog"
    aria-labelledby="myModalLabel" aria-hidden="true" style="overflow-y: auto">
    <!--Dialog-->
    <div class="modal-dialog modal-notify modal-info" role="document">
      <div class="modal-content">
        <div class="modal-header text-center">
          <h4 class="modal-title white-text w-100 font-weight-bold py-2">
            {{"Privacy"| translate}}
          </h4>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="privacyModalTwo.hide()">
            <span aria-hidden="true" class="white-text">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          Modal Body
        </div>
      </div>
    </div>
  </div>

  <!--Modal Terms_y_Conditions-->
  <div mdbModal #Terms_y_ConditionsTwo="mdbModal" class="modal fade top" id="frameModalTop" tabindex="-1" role="dialog"
    aria-labelledby="myModalLabel" aria-hidden="true" style="overflow-y: auto">
    <!--Dialog-->
    <div class="modal-dialog modal-notify modal-info" role="document">
      <div class="modal-content">
        <div class="modal-header text-center">
          <h4 class="modal-title white-text w-100 font-weight-bold py-2">
            {{"Terms_y_Conditions"| translate}}
          </h4>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close"
            (click)="Terms_y_ConditionsTwo.hide()">
            <span aria-hidden="true" class="white-text">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          Modal Body
        </div>
      </div>
    </div>
  </div>


  <div class="row justify-content-center">
    <h1 class="h1 text-primary text-center">
      1. {{ 'Create_your_account' | translate }}
    </h1>
    <!--<h5 class="h5 text-center">{{ 'Its_free_and_easy' | translate }}</h5>-->
    <br />
    <br />
    <div class="col"></div>

    <div class="col justify-content-center">
      <div class="card">
        <div class="card-header text-center">
          <img src="../../assets/logo_conwaste_new.png" class="card-img-top" style="width: 300px !important;" alt="" />
        </div>
        <div class="card-body">
          <form #registerForm="ngForm" (ngSubmit)="create(registerForm.value)" class="form" autocomplete="off">
            <div class="form-group">
              <label for="Title">{{ 'First_name' | translate }}</label>
              <div class="input-group">
                <span class="input-group-text" id="basic-addon3">
                  <i class="bi bi-person-fill"></i>
                </span>
                <input type="text" class="form-control First_nameVal" name="firstname" ngModel #First_nameVal />
              </div>
            </div>

            <div class="form-group">
              <label for="Title">{{ 'Last_name' | translate }}</label>
              <div class="input-group">
                <span class="input-group-text" id="basic-addon4">
                  <i class="bi bi-person-fill"></i>
                </span>
                <input type="text" class="form-control Last_nameVal" name="lastname" ngModel #Last_nameVal />
              </div>
            </div>

            <div class="form-group">
              <label for="Title">{{ 'E-mail' | translate }}</label>
              <div class="input-group">
                <span class="input-group-text" id="basic-addon5">
                  <i class="bi bi-envelope-fill"></i>
                </span>
                <input type="email" placeholder="someone@example.com" class="form-control" name="email" ngModel
                  #EmailVal />
              </div>
            </div>

            <div class="form-group">
              <label for="Title">{{ 'Password' | translate }}</label>
              <div class="input-group">
                <span class="input-group-text" id="basic-addon6">
                  <i class="bi bi-lock-fill"></i>
                </span>
                <input [type]="show ? 'text' : 'password'" matTooltip="{{ 'toltipPass1' | translate}}
                  {{ 'toltipPass2' | translate}}
                  {{ 'toltipPass3' | translate}}
                  {{ 'toltipPass4' | translate}}
                  {{ 'toltipPass5' | translate}}
                  {{ 'toltipPass6' | translate}}" matTooltipClass="my-tooltip" matTooltipPosition="right"
                  class="form-control" name="password" #PasswordVal ngModel />
                <span class="input-group-text" id="basic-addon6">
                  <mat-icon type="button" svgIcon="{{ show ? 'eye-open' : 'eye-closed' }}" (click)="ShowPassword()">
                  </mat-icon>
                </span>
              </div>
            </div>

            <div class="form-group">
              <label for="Title">{{ 'Confirm_password' | translate }}</label>
              <div class="input-group">
                <span class="input-group-text" id="basic-addon7">
                  <i class="bi bi-lock-fill"></i>
                </span>
                <input [type]="showConfirm ? 'text' : 'password'" class="form-control" name="confirmpassword"
                  #confirmpasswordVal ngModel />
                <span class="input-group-text" id="basic-addon6">
                  <mat-icon type="button" svgIcon="{{ showConfirm ? 'eye-open' : 'eye-closed' }}"
                    (click)="ShowPasswordTwo()"></mat-icon>
                </span>
              </div>
            </div>
            <div class="form-check text-center">
              <br />
              <input class="form-check-input" type="checkbox" value="" required id="flexCheckIndeterminate" />
              <label class="form-check-label" for="flexCheckIndeterminate">
                {{
                'By_creating_an_account_it_means_you_agree_to_the' | translate
                }}
                <a href="https://www.conwastepr.com/es/condiciones-y-terminos-de-uso/" target="_blank">
                  <strong>{{ 'Terms_y_Conditions' | translate }}</strong>
                </a>
                {{ 'and_our' | translate }}
                <a href="https://www.conwastepr.com/es/politica-de-privacidad/" target="_blank">
                  <strong>{{ 'Privacy_Policy' | translate }}</strong>
                </a>
              </label>
            </div>
            <!--Other fields-->
            <div class="text-center">
              <br />
              <input type="submit" class="btn btn-r btn-lg btn-primary" value="{{ 'Register' | translate }}" />
            </div>
          </form>
        </div>
      </div>
    </div>
    <div class="col"></div>
  </div>
</div>