<div class="container">
    <div class="row justify-content-center">
        <h1 class="h1 text-primary text-center">{{"Reset_my_password" | translate}}</h1>
        <h5 class="h5 text-center">{{"Please_enter_your_password" | translate}}</h5>
        <br>
        <br>
        <div class="col"></div>

        <div class="col justify-content-center">
            <div class="card">
                <div class="card-header text-center">
                    <img src="../../assets/logo_conwaste_new.png" class="card-img-top" style="width: 300px !important;"
                        alt="">
                </div>
                <div class="card-body">
                    <form class="form" action="https://localhost:3000/register" method="POST" autocomplete="on">

                        <div class="form-group">
                            <label for="Title">{{"Enter_password" | translate}}</label>
                            <div class="input-group">
                                <span class="input-group-text" id="basic-addon5">
                                    <i class="bi bi-lock-fill"></i>
                                </span>
                                <input type="password" class="form-control" name="Email">
                            </div>
                        </div>

                        <div class="form-group">
                            <label for="Title">{{"Re-enter_password" | translate}}</label>
                            <div class="input-group">
                                <span class="input-group-text" id="basic-addon6">
                                    <i class="bi bi-lock-fill"></i>
                                </span>
                                <input type="password" class="form-control" name="email">
                            </div>
                        </div>
                        <div class="text-center">
                            <br>
                            <input routerLink="/login" type="submit" class="btn btn-r btn-lg btn-primary" value="{{'Change_my_password' | translate}}" />
                        </div>
                    </form>
                </div>
            </div>
        </div>
        <div class="col"></div>
    </div>
</div>