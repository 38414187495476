import {
  HttpClient,
  HttpErrorResponse,
  HttpHeaders,
  HttpParams,
} from '@angular/common/http'
import { Injectable } from '@angular/core'
import { Observable, of, throwError, tap, Subject } from 'rxjs'
import { catchError, retry, map } from 'rxjs/operators'
import { Hello } from '../hello'
import { IP } from '../IP'

@Injectable({
  providedIn: 'root',
})
export class UserEditService {
  //It seems that you must name the "global variable" here
  private apiUrl = IP
  passwordChange: any
  userid: any

  constructor(private http: HttpClient) {
    //and then declare the type of said variable here
  }

  createUser(userdata: any) {
    let value
    console.log('In getInvoice function')
    value = this.http.post(
      this.apiUrl + '/userlog/createUser',
      { newuser: userdata },
      {},
    )
    console.log(value)
    return value
    //.pipe()
  }

  getUserData() {
    let value
    value = this.http.get(
      IP + '/userlog/getUserData',
      //+ localStorage.getItem('item'),
      {
        headers: {
          token: localStorage.getItem('token'),
        },
      },
    )
    console.log('In service of get User')
    console.log(value)

    return value
  }

  editUser(userdata: any) {
    let value
    var email = userdata.email
    var password = userdata.password
    var confirmpassword = userdata.confirmpassword
    var emailformat = /\S+@\S+.\S+/
    var passwordformat = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*])[0-9a-zA-Z!@#$%^&*]{8,16}$/
    var userID = localStorage.getItem('item')
    var email_validated = emailformat.test(email)
    var password_validated = passwordformat.test(password)

    console.log('In EditUser function')

    if (password != undefined && password == confirmpassword) {
      if (email_validated == true) {
        value = this.http.post(
          this.apiUrl + '/userlog/editUserWithPass',
          { useredit: userdata },
          {
            headers: {
              token: localStorage.getItem('token'),
            },
          },
        )
        console.log(value)
      } else {
        console.log('Email and password no good >:(')
      }
    } else {
      if (email_validated == true) {
        value = this.http.post(
          this.apiUrl + '/userlog/editUser',
          { useredit: userdata },
          {
            headers: {
              token: localStorage.getItem('token'),
            },
          },
        )
        console.log(value)
      } else {
        console.log('Email looking bad >:O')
      }
    }
    return value
  }

  httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
  }
}
