<div class="noneLoader">
  <div class="container-fluid">
    <header class="d-flex flex-wrap py-3 mb-4 border-bottom">
      <a routerLink="#" class="d-flex align-items-center mb-3 mb-md-0 me-md-auto text-dark text-decoration-none">
        <h1 class="px-4 text-primary">
          <img src="../../../assets/logo_conwaste_new.png" width="270px" alt="" />
        </h1>
      </a>
      <ul class="nav nav-pills navbar-secondary">
        <li class="nav-item">
          <button (click)="changeLang('en')" class="nav-link btn" id="en" aria-current="page">
            <img src="../../../assets/USA-icon.png" width="30px" alt="" />
            <span>En</span>
          </button>
        </li>
        <li class="nav-item">
          <button (click)="changeLang('es')" type="button" id="es" class="nav-link btn active">
            <img src="../../../assets/Flags-Puerto-rico.ico" width="30px" alt="" />
            <span>Es</span>
          </button>
        </li>
        <li class="nav-item">
          <button type="button" (click)="redirectClick()" class="nav-link btn">
            <h5>{{ "Hi" | translate }}, {{ userFirstName }}</h5>
          </button>
        </li>
        <li class="nav-item">
          <button type="button" (click)="Logout()" class="nav-link btn">
            <i class="bi bi-box-arrow-right h4"></i>
          </button>
        </li>
      </ul>
    </header>
  </div>
  <div class="row" style="margin-left:0;margin-right:0;">
    <div class="col-xs-2 col-sm-2 col-md-2 col-lg-2">
      <div class="list-group text-center">
        <a id="payment" *ngIf="rol == 1" class="py-5 list-group-item list-group-item-action" aria-current="page"
          routerLink="/payment" routerLinkActive="active">
          {{ "Pay_my_invoice" | translate }}
        </a>
        <a id="history" *ngIf="rol == 1" class="py-5 list-group-item list-group-item-action" routerLink="/history"
          routerLinkActive="active">
          {{ "Invoice_History" | translate }}
        </a>
        <a id="payhistory" *ngIf="rol == 1" class="py-5 list-group-item list-group-item-action" routerLink="/payhistory"
          routerLinkActive="active">
          {{ "Payments_History" | translate }}
        </a>
        <a id="registerCompany" *ngIf="rol == 1" class="py-5 list-group-item list-group-item-action"
          routerLink="/companyregisterapp" routerLinkActive="active">
          {{ "Register_cuentas" | translate }}
        </a>

        <a id="paymentmethod" *ngIf="rol == 1" class="py-5 list-group-item list-group-item-action"
          routerLink="/paymentmethod" routerLinkActive="active">
          {{ "Payment_Methods" | translate }}
        </a>
        <a id="reportsinvoice" *ngIf="rol == 2" class="py-5 list-group-item list-group-item-action"
          routerLink="/reports-invoice" routerLinkActive="active">
          {{ "Reporte-invoice" | translate }}
        </a>
        <a id="reportsinvoice" *ngIf="rol == 2" class="py-5 list-group-item list-group-item-action"
          routerLink="/reports-payment" routerLinkActive="active">
          {{ "Reporte-payment" | translate }}
        </a>
        <a id="reportsinvoice" *ngIf="rol == 2" class="py-5 list-group-item list-group-item-action"
        routerLink="/reports-stripe" routerLinkActive="active">
        {{ "stripe-reports" | translate }}
      </a>
        <a id="reportsinvoice" *ngIf="rol == 2" class="py-5 list-group-item list-group-item-action"
          routerLink="/reports-account" routerLinkActive="active">
          {{ "Reporte-account" | translate }}
        </a>
        <a id="user-admin" *ngIf="rol == 2" class="py-5 list-group-item list-group-item-action"
          routerLink="/user-admin" routerLinkActive="active">
          {{ "adminUser" | translate }}
        </a>
      </div>
    </div>
    <div class="col-xs-10 col-sm-10 col-md-10 col-lg-10">
      <router-outlet></router-outlet>
    </div>
  </div>
  <div class="clearfix"></div>
  <!-- Footer de la pagina -->
  <div class="container step-1">
    <footer class="bd-footer py-5 my-5">
      <ul class="nav justify-content-center nav-fill border-bottom pb-3 mb-3">
        <li class="nav-item">
          <a href="https://www.conwastepr.com/es/contactanos/" target="_blank" class="nav-link px-2 text-muted">
            {{ "Support" | translate }}
          </a>
        </li>
        <li class="nav-item">
          <a href="https://www.conwastepr.com/es/politica-de-privacidad/" target="_blank"
            class="nav-link px-2 text-muted">
            {{ "Privacy" | translate }}
          </a>
        </li>
        <li class="nav-item">
          <a href="https://www.conwastepr.com/es/condiciones-y-terminos-de-uso/" target="_blank"
            class="nav-link px-2 text-muted">
            {{ "Terms_y_Conditions" | translate }}
          </a>
        </li>
        <li class="nav-item">
          <a href="https://conwastepr.com" class="nav-link px-2 text-muted">
            <strong>Conwastepr.com</strong>
          </a>
        </li>
      </ul>
      <p class="text-center text-muted">©{{ year }},{{ "CopyR" | translate }}</p>
    </footer>
  </div>
</div>