import {
  HttpClient,
  HttpErrorResponse,
  HttpHeaders,
  HttpParams,
} from '@angular/common/http'
import { Injectable } from '@angular/core'
import { IP } from '../IP'

@Injectable({
  providedIn: 'root',
})
export class CompanyService {
  //It seems that you must name the "global variable" here

  constructor(private http: HttpClient) {
    //and then declare the type of said variable here
  }

  getCompanies() {
    let value
    console.log('In getCompany function')
    value = this.http.get(IP + '/company/getcompanies', {})
    console.table(value)
    return value
    //.pipe()
  }

  httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
  }
}
