import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { IP, IPSTRIPE, BasicAutorizationStripe } from '../IP'

@Injectable({
  providedIn: 'root'
})
export class ReportService {
  private apiUrl = IP;
  private apiStripe = IPSTRIPE;

  constructor(private http: HttpClient) { }

  pendingInvoiceReport() {
    return this.http.get(this.apiUrl + '/report/pendingInvoice', {
      headers: {
        token: localStorage.getItem('token'),
      },
    });
  }

  pendingInvoiceReportByDate(from: any, to: any, customername: string) {
    return this.http.get(this.apiUrl + '/report/pendingInvoiceByDate/'+from+'/'+to, {
      headers: {
        token: localStorage.getItem('token'),
      },
    });
  }

  paymentReport() {
    return this.http.get(this.apiUrl + '/report/payment', {
      headers: {
        token: localStorage.getItem('token'),
      },
    });
  }

  accountReport() {
    return this.http.get(this.apiUrl + '/report/accounts', {
      headers: {
        token: localStorage.getItem('token'),
      },
    });
  }

  accountReportWithFilter(item: any) {
    return this.http.post(this.apiUrl + '/report/accountReportWithFilter',
      {
        filter: item.filter,
        value: item.value
      },
      {
        headers: {
          token: localStorage.getItem('token'),
        },
      });
  }

  paymentReportWithFilter(item: any) {
    return this.http.post(this.apiUrl + '/report/paymentReportWithFilter',
      {
        filter: item.filter,
        value: item.value
      },
      {
        headers: {
          token: localStorage.getItem('token'),
        },
      });
  }

  pendingInvoiceReportWithFilter(item: any) {
    return this.http.post(this.apiUrl + '/report/pendingInvoiceReportWithFilter',
      {
        filter: item.filter,
        value: item.value
      },
      {
        headers: {
          token: localStorage.getItem('token'),
        },
      });
  }

  filterBy(value: string) {
    return this.http.post(this.apiUrl + '/report/filterby',
      {
        filter: value
      },
      {
        headers: {
          token: localStorage.getItem('token'),
        },
      });
  }

  paymentFilterby(value: string) {
    return this.http.post(this.apiUrl + '/report/paymentFilterby',
      {
        filter: value
      },
      {
        headers: {
          token: localStorage.getItem('token'),
        },
      });
  }

  pendingInvoiceFilterby(value: string) {
    return this.http.post(this.apiUrl + '/report/pendingInvoiceFilterby',
      {
        filter: value
      },
      {
        headers: {
          token: localStorage.getItem('token'),
        },
      });
  }

  stripeReport(){
    let urlProduccion = 'https://micuenta.conwastepr.com:3050'
    return this.http.get(this.apiStripe + '/listpayment', {
      headers: {
        Authorization: BasicAutorizationStripe,
        token: localStorage.getItem('token'),
      },
    });
  }

  allList() {
    return this.http.post(this.apiUrl + '/report/payment', {
      headers: {
        token: localStorage.getItem('token'),
      },
    });
  }
}