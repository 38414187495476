import {
  HttpClient,
  HttpErrorResponse,
  HttpHeaders,
  HttpParams,
} from '@angular/common/http'
import { Injectable } from '@angular/core'
import { json } from 'express'
import { Observable, of, throwError, tap, Subject } from 'rxjs'
import { catchError, retry, map } from 'rxjs/operators'
import { Hello } from '../hello'
import { IP } from '../IP'

@Injectable({
  providedIn: 'root',
})
export class RegisterCompanyService {
  //It seems that you must name the "global variable" here
  private apiUrl = IP

  constructor(private http: HttpClient) {
    //and then declare the type of said variable here
  }

  validHaveCompanyInfo(data: any){
    let value
    //console.log(data);
    //console.log(data.customernum);
    value = this.http.post(IP + '/company/validHaveCompanyInfo',
      { data: data },
    )
    console.log(value)
    return value
  }

  validPhoneNumber(data: any, phone: any){
    let value
    data.phone = phone
    // console.log(data)
    value = this.http.post(IP + '/company/validPhoneNumber',
      { data: data },
    )
    console.log(value)
    return value
  }

  
  validSiteNumber(data: any, phoneSite: any, siteNumber: any){
    let value
    data.sitePhoneNumber = phoneSite
    data.site = siteNumber
    value = this.http.post(IP + '/company/validSiteNumber',
      { data: data },
    )
    console.log(value)
    return value
  }

  createUserCompanies(companydata: any) {
    let value
    // console.log('In getInvoice function')
    value = this.http.post(IP + '/company/createUserCompany',
      { companies: companydata },
      {
        headers: {
          token: localStorage.getItem('token'),
        },
      },
    )
    console.log(value)
    return value
    //.pipe()
  }

  editCreateOrDeleteUserCompany(companyTrue: any, companyFalse: any) {
    console.log('In editUserCompanies function from registercompany.service')
    //let id = localStorage.getItem('item')
    console.log(companyTrue)
    console.log(companyFalse)

    let edit = this.http.post(
      IP + '/company/editCreateOrDeleteUserCompany',
      {
        companyTrue: companyTrue,
        companyFalse: companyFalse,
      },
      {
        headers: {
          token: localStorage.getItem('token'),
        },
      },
    )
    console.log(edit)
    return edit
  }

  getCompaniesFromUser() {
    let value
    console.log('In getInvoice function')
    value = this.http.get(
      IP + '/company/getCompaniesFromUser',
      //+ localStorage.getItem('item'),
      {
        headers: {
          token: localStorage.getItem('token'),
        },
      },
    )
    console.log(value)
    return value
  }

  httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
  }
}
