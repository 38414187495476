import { Component, OnInit, Inject, NgModule } from '@angular/core'
import { Router, ActivatedRoute } from '@angular/router'
import { CompanyService } from '../services/company.service'
import { RegisterCompanyService } from '../services/registercompany.service'
import { MatSnackBar } from '@angular/material/snack-bar'
import Swal from 'sweetalert2'

@Component({
  selector: 'app-register',
  templateUrl: './company-register.component.html',
  styleUrls: ['./company-register.component.css'],
})
export class CompanyRegisterComponent implements OnInit {
  defaultOption: any
  companies: any
  findCompID: any
  newCompanies: any
  userCompaniesList: any
  availableCompanies: any
  userid: any
  enableEdit: boolean
  enableSave: boolean
  thisdata: any
  compname: any
  phonenumber: any
  numcliente: any
  userCompID: any
  listcompany: any
  companySelection: any
  compUpdated: any
  companyId: any
  companyPhone: any
  idForUpdate: any
  idForCreate: any
  enableRemove: boolean
  disable: boolean
  NoComp: boolean
  optionPlaceholder: any
  saveMessage: string = 'Your companies have been saved!'
  saveAction: any = 'X'
  showLabel: boolean
  returnedData: any
  selectedCompanies: any

  constructor(
    private companyService: CompanyService,
    private router: Router,
    private route: ActivatedRoute,
    private registerCompanyService: RegisterCompanyService,
    private snackBar: MatSnackBar,
  ) { }

  ngOnInit(): void {
    this.enableSave = true
    this.enableRemove = true
    this.NoComp = true
    this.getCompanies()
    this.getUserCompany()
    this.userCompaniesList = []
    this.companySelection = []
    this.showLabel = false
    this.returnedData = []
    this.selectedCompanies = []
    let rolUser: any = localStorage.getItem('rol')
    if (!localStorage.getItem('token')) {
      this.router.navigate(['/login'])
    } else if(rolUser == 2) {
      this.router.navigate(['/reports-invoice'])
    }
  }

  alertValidate(messageAlert: string) {
    this.snackBar.open(messageAlert, 'X', {
      duration: 3000,
      panelClass: ['red-snackbar'],
      verticalPosition: 'top', // Allowed values are  'top' | 'bottom'
      horizontalPosition: 'center',
    })
  }

  notifySave() {
    let langVery = sessionStorage.getItem('lang');
    //Notification save
    this.snackBar.open(langVery == 'es' ? '¡¡Tus cuentas se han guardado!!' : 'Your accounts have been saved!', this.saveAction, {
      //duration that the notification will be on screen
      duration: 3000,
      //class for notification so that you can style it in the global css (ps. you should use the global css file or else styling won't work on it)
      panelClass: ['green-snackbar'],
    })
  }



  onClickEdit(comp: any) {
    this.enableEdit = true
    this.enableSave = false
    this.enableRemove = false
    this.disable = true
    console.log(comp)

    //Filters the list of companies so that only the company name of the selected user company appears as an option
    let filteredComp = this.companies.filter((company: any) => {
      return company.companyid == comp.companyid
    })
    console.log(filteredComp)

    //replaces the available companies with the filtered ones
    this.availableCompanies = filteredComp

    //Set the "No companies" option when the array has no values
    if (this.availableCompanies.length == 0) {
      this.NoComp = true
    } else {
      this.NoComp = false
    }

    this.companySelection = comp.companyid
    this.userCompID = comp.usercompid
    this.phonenumber = comp.telefono
    this.numcliente = comp.customernumber
    this.listcompany = comp
  }

  addCompany2(company: any, phone: any, customernum: any) {
    let langVery = sessionStorage.getItem('lang');
    //Validates if all the info was inputted before adding it to the list
    if (company && phone && customernum) {
      console.log('IN ADD COMPANY: ')
      console.log(company)

      let datos = {
        company: company,
        phone: phone,
        customernum: customernum
      }

      this.registerCompanyService.validHaveCompanyInfo(datos).subscribe({
        next: (data) => {
          console.log(data);
          this.returnedData = data
          if (this.returnedData.error == 1) {
            this.alertValidate(langVery == 'es' ? 'El número de teléfono o de cliente no existen' : 'The phone or customer number does not exist')
          } else {

            //compItem finds the company name using company id from the list of companies
            let compItem = this.companies.find((item: any) => {
              return item.companyid == company
            })
            console.log(compItem)

            //Adds a new user company to the list
            var newCompCustomer = {
              companyid: company,
              telefono: phone,
              customernumber: customernum,
              name: compItem.companyname,
            }

            this.userCompaniesList.push(newCompCustomer)

            //Disables edit
            this.enableEdit = false
            //Enables the save button at the bottom of the container
            this.enableSave = true
            //resets the inputs
            this.phonenumber = ''
            this.numcliente = ''

            console.log(this.availableCompanies)
            /*const index = this.availableCompanies.findIndex(
              (comp: any) => comp.companyid == company,
            )
            if (index > -1) {
              this.availableCompanies.splice(index, 1) //2nd parameter means to remove one item only in this case
            }*/
            console.log('This Splice: ')
            console.log(this.availableCompanies)

            //Set the "No companies" option when the array has no values
            if (this.availableCompanies.length == 0) {
              this.NoComp = true
            } else {
              this.NoComp = false
              this.showLabel = true;
            }
          }
        },
        error: (error) => {
          console.log('Error: ')
          console.log(error)
        },
      })
    } else {
      this.alertValidate(langVery == 'es' ? 'Por favor rellenar todos los campos' : 'Please fill in all the fields')
    }
  }

  addCompany(company: any, phone: any, customernum: any) {
    let langVery = sessionStorage.getItem('lang');
    //Validates if all the info was inputted before adding it to the list
    if (company == '') {
      this.alertValidate(langVery == 'es' ? 'Por favor seleccionar una compañía' : 'Please select a company')
    } else if (phone.length < 9) {
      console.log(phone.length);
      this.alertValidate(langVery == 'es' ? 'El campo teléfono debe tener 10 caracteres' : 'The phone field must have 10 characters')
    } else if (customernum == '' || customernum.length < 3) {
      this.alertValidate(langVery == 'es' ? 'El campo numeró cliente no puede ir vacío' : 'The customer number field cannot be empty')
    } else {
      let datos = {
        company: company,
        phone: phone,
        customernum: customernum
      }
      this.registerCompanyService.validHaveCompanyInfo(datos).subscribe({
        next: (data) => {
          console.log(data);
          this.returnedData = data
          if (this.returnedData.error == 1) {
            this.alertValidate(langVery == 'es' ? 'El numero de telefono o de cliente no existen' : '')
          } else {
            let compItem = this.companies.find((item: any) => {
              console.log(item.companyid == company)
              return item.companyid == company
            })
            console.log(compItem)
            var newCompCustomer = {
              companyid: company,
              telefono: phone,
              customernumber: customernum,
              name: compItem.companyname,
            }

            this.selectedCompanies.push(newCompCustomer)
            this.enableEdit = false
            this.enableSave = true
            this.phonenumber = ''
            this.numcliente = ''

            console.log(this.availableCompanies)
            const index = this.availableCompanies.findIndex(
              (comp: any) => comp.companyid == company,
            )

            this.availableCompanies = this.availableCompanies.filter((comp: any) => {
              return comp.companyid != company
            })

            console.log('This Splice: ')
            console.log(this.availableCompanies)

            if (this.availableCompanies.length == 0) {
              this.NoComp = true
            } else {
              this.NoComp = false
              this.showLabel = true;
            }
          }
        },
        error: (error) => {
          console.log('Error: ')
          console.log(error)
        },
      })
    }
  }

  //Removes the selected user company from the list
  removeCompany(company: any) {
    // Uses the id of the company that is going to be removed and filters
    // the list to get the company
    let removedComp = this.companies.filter((comp: any) => {
      console.log(company)
      console.log('Company filter: ')
      console.log(comp)
      console.log(comp.companyid)
      console.log(company.companyid)

      console.log('Checking company ids: ')
      console.log(comp.companyid == company.companyid)
      return comp.companyid == company.companyid
    })
    console.log('removedCOmp:')
    console.log(removedComp)

    //With the item obtained from removedComp we then find the id in specific to use for the select
    let removedCompID = removedComp.find((comp: any) => {
      console.log('Removed Comp ID: ')
      console.log(comp.companyid)
      return comp.companyid
    })

    console.log('Available Companies: ')
    var checkIdInList = this.companies.find((id: any) => {
      console.log(id.companyid === removedCompID)
      return id.companyid == removedCompID
    })
    console.log(checkIdInList)
    //Here we push the id of the removed company to the select so that it can be used again
    if (!checkIdInList) {
      this.availableCompanies.push(removedCompID)
      console.log(this.availableCompanies)
    }

    //Heres the function to actually remove the user company from the list in the front end
    //make sure to leave this for last or else the push method will give an empty array
    this.userCompaniesList = this.userCompaniesList.filter(
      (comp: any) => comp !== company,
    )
    console.log('Removed Company: ')
    console.log(company)

    //Set the "No companies" option when the array has no values
    if (this.availableCompanies.length == 0) {
      this.NoComp = true
    } else {
      this.NoComp = false
    }
  }

  cancelEdit() {
    this.enableEdit = false
    this.enableRemove = true
    this.disable = false
    this.NoComp = true
    //Enables the save button at the bottom of the container
    this.enableSave = true
    //resets the inputs
    this.phonenumber = ''
    this.numcliente = ''
    //This reapplies all the companies to availableCompanies
    //this.availableCompanies = this.companies
    this.availableCompanies = this.availableCompanies.filter(
      (comp: any) => comp == this.findCompID,
    )
    console.log('Company ID: ' + this.findCompID)
  }

  saveUserCompanies() {
    this.registerCompanyService
      .createUserCompanies(this.userCompaniesList)
      .subscribe({
        //next/error: is the modern way of doing requests
        //if you do the old way ".subscribe((res) => {res})" and try to pass more than one response subscribe will appear depricated.
        next: (data) => {
          console.log('estoy aqui')
        },
        error: (error) => {
          //This runs when the "isAuth" middleware returns 400 bad request as a result of session being invalid
          console.log(
            'Error in saveUserCompanies(company-register.component): ',
          )
          console.log(error)
          //this.router.navigate(['/login'])
        },
      })
  }

  saveEditCompany(company: any, phone: any, customernum: any) {
    console.log('saveEditCompany ran')
    this.enableRemove = true
    this.disable = false

    console.log(this.userCompID)
    //updateItem finds the user company that was selected for editing
    let updateItem = this.userCompaniesList.find(
      (item: any) => item === this.listcompany,
    )

    //compItem finds the company name using company id from the list of companies
    let compItem = this.companies.find((item: any) => {
      return item.companyid == company
    })

    //Creates a new user company to overwrite the old one
    var newEditComp = {
      usercompid: this.userCompID,
      companyid: company,
      telefono: phone,
      customernumber: customernum,
      name: compItem.companyname,
    }

    //index gets the index id of the selected user company
    let index = this.userCompaniesList.indexOf(updateItem)
    //This then uses the index obtained to find where the old user company was in the list and replace it with the new one
    this.userCompaniesList[index] = newEditComp

    console.log(this.userCompaniesList[1].name)
    console.log('Blah')

    //Disables edit
    this.enableEdit = false
    //Enables the save button at the bottom of the container
    this.enableSave = true
    //resets the inputs
    this.phonenumber = ''
    this.numcliente = ''
    //This reapplies all the companies to availableCompanies
    //this.availableCompanies = this.companies

    //This is for
    const index2 = this.availableCompanies.findIndex(
      (comp: any) => comp.companyid == company,
    )
    if (index2 > -1) {
      this.availableCompanies.splice(index2, 1) //2nd parameter means to remove one item only in this case
    }
    console.log('This Splice: ')
    console.log(this.availableCompanies)
  }

  saveFinalEdit() {
    let langVery = sessionStorage.getItem('lang');
    console.log('Final Edit:')
    console.log(this.userCompaniesList)

    let userCompID = this.userCompaniesList.usercompid
    console.log(this.userCompaniesList)
    console.log('userCompID: '+this.userCompaniesList[0].companyid)

    let compWithUserCompID = this.userCompaniesList.filter((company: any) => {
      return company.usercompid != null
    })
    console.log(compWithUserCompID)

    let compNullUserCompID = this.userCompaniesList.filter((company: any) => {
      return company.usercompid == null
    })
    console.log(compNullUserCompID)

   /* let compWithUserCompID = this.userCompaniesList.filter((company: any) => {
      return company.companyid != null
    })
    console.log('[1]: '+compWithUserCompID)

    let compNullUserCompID = this.userCompaniesList.filter((company: any) => {
      console.log(company)
      console.log(company.companyid)
      //return company.companyid == null
      return false
    })*/


    if (compNullUserCompID == '' /*|| compWithUserCompID == ''*/) {

      console.log('No hay data aqui')
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: langVery == 'es' ? 'No tienes información para guardar' : 'You have no information to save',
        //footer: '<a href="">Why do I have this issue?</a>'
      })
    } else {
      this.registerCompanyService
        .editCreateOrDeleteUserCompany(compWithUserCompID, compNullUserCompID)
        .subscribe({
          next: (data) => {
            //console.log('Estoy aqui coño 1111')
            console.log(data)
            this.getCompanies()
            this.getUserCompany()
            //this.notifySave()
            Swal.fire({
              icon: 'success',
              title: langVery == 'es' ? 'Bien!' : 'Good!',
              text: langVery == 'es' ? 'Tu cuenta fue agregada con éxito' : 'Your account was added successfully',
              //footer: '<a href="">Why do I have this issue?</a>'
            })
            console.log('estoy aqui')
          },
          error: (error) => {
            //console.log('Estoy aqui coño 2222')
            console.log('Error in saveUserCompanies(company-register.component): ',)
            console.log(error)
            //this.router.navigate(['/login'])
          },
        })
    }
  }

  //Get companies of the User to fill the list
  getUserCompany() {
    this.registerCompanyService.getCompaniesFromUser().subscribe({
      next: (data) => {
        this.thisdata = data
        console.log('ThisData:')
        console.log(this.thisdata)

        this.userCompaniesList = this.thisdata.map((userComp: any) => {
          return {
            usercompid: userComp.usercompid,
            companyid: userComp.companyid,
            telefono: userComp.phonenumber,
            customernumber: userComp.customernumber,
            name: userComp.Company.companyname,
          }
        })

        //Find company id from the list of companies
        this.findCompID = this.userCompaniesList.map((comp: any) => {
          return comp.companyid
        })
        console.log('Company ID: ' + this.findCompID)

        //Check if there are any companies, if there are not initialize an empty array for the select,
        //if there are companies available to choose then filter them to show only the ones the user hasn't registered to.
        //if (10 >= 0) {
        this.availableCompanies = this.companies.filter((comp: any) => {
          console.log(comp.companyid != this.findCompID)
          return this.findCompID
          //return comp.companyid != this.findCompID
        })
        //} else {
        //this.availableCompanies = []
        //}
      },
      error: (error) => {
        console.log(error)
        //this.router.navigate(['/login'])
      },
    })
  }

  //Get companies available to place as options in the select component
  getCompanies() {
    this.companyService.getCompanies().subscribe({
      //next/error: is the modern way of doing requests
      //if you do the old way ".subscribe((res) => {res})" and try to pass more than one response subscribe will appear depricated.
      next: (data) => {
        console.log('getCompanies ran:')
        console.log(data)
        this.companies = data
        //this.availableCompanies = data
      },
      error: (error) => {
        //This runs when the "isAuth" middleware returns 400 bad request as a result of session being invalid
        console.log('Error: ')
        console.log(error)
        //return this.router.navigate(['/login'])
      },
    })
  }
}
