import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { ForgotPassword } from '../services/forgot-password.service';
import Swal from 'sweetalert2'

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.css']
})
export class ForgotPasswordComponent implements OnInit {

  returnedData: any
  buttonSend: boolean = false;

  constructor(
    private snackBar: MatSnackBar,
    private router: Router,
    private ForgotPasswordService: ForgotPassword,
  ) { }
  @ViewChild('EmailReset') EmailReset: ElementRef<HTMLInputElement>;

  ngOnInit(): void {
    this.returnedData = []
  }

  alertValidate(messageAlert: string) {
    this.snackBar.open(messageAlert, 'X', {
      duration: 3000,
      panelClass: ['red-snackbar'],
      verticalPosition: 'top', // Allowed values are  'top' | 'bottom'
      horizontalPosition: 'center',
    })
  }

  resetPassword(data: any) {
    let langVery = sessionStorage.getItem('lang');
    let emailReset = this.EmailReset.nativeElement.value
    let validSet, emailRegex = /^[-\w.%+]{1,64}@(?:[A-Z0-9-]{1,63}\.){1,125}[A-Z]{2,63}$/i;
    if (emailRegex.test(emailReset)){validSet = 1;} else {validSet = 2;}

    if(emailReset == '' || validSet == 2){
      this.alertValidate(langVery == 'es' ? 'Este correo electrónico es inválido' : 'This email is invalid')
    } else {
      this.buttonSend = true
      console.log(data);
      this.ForgotPasswordService.resetPassword(emailReset).subscribe({
        next:(data: any) => {
          this.returnedData = data;
          console.log(this.returnedData);
          this.buttonSend = false
          Swal.fire({
            icon: 'success',
            title: langVery == 'es' ? 'Excellent!' : '',
            text: langVery == 'es' ? 'Se ha enviado un correo electrónico con su nueva contraseña temporal' : 'An email has been sent with your new temporary password',
            //footer: '<a>Conwaste</a>'
          }).then((result) => {
            if (result.isConfirmed) {
              this.router.navigate(['/login'])
            }
          })
          
        },
        error:(error: any) => {
          this.buttonSend = false
          this.alertValidate(langVery == 'es' ? 'Este correo electrónico no existe en nuestros registros' : 'This email does not exist in our records')
          console.log(error)
        },
      })
    }
  }


}
