import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ReportService } from '../services/reports.service';
import Swal from 'sweetalert2';
import { PaymentReport } from './dto/payment-report.dto';
import { Bills } from './dto/bills.dto';
import { PaymentService } from '../services/payment.service';
declare const $: any;

@Component({
  selector: 'app-report-payment',
  templateUrl: './report-payment.component.html',
  styleUrls: ['./report-payment.component.css']
})
export class ReportPaymentComponent implements OnInit {
  listcompany: any = 'Selecciona';
  companies: any;
  paymentReportModel: PaymentReport[] = [];
  activeFilter: string = "";
  list: any[] = [];
  minDate: any
  maxDate: any;
  tableTable: any;
  listDetailInvoice: Bills[] = [];

  datosTable: any = {
    dom: 'Bfrtip',
    language: {
      url: sessionStorage.getItem('lang') == 'es' ? "//cdn.datatables.net/plug-ins/1.10.15/i18n/Spanish.json" : "//cdn.datatables.net/plug-ins/1.10.15/i18n/English.json",
      searchBuilder: {
        button: sessionStorage.getItem('lang') == 'es' ? 'Filtrar por' : 'Filter by',
        columns: [3],
        add: sessionStorage.getItem('lang') == 'es' ? 'Agregar condición' : 'Add condition',
        condition: sessionStorage.getItem('lang') == 'es' ? 'condición' : 'condition',
        data: sessionStorage.getItem('lang') == 'es' ? 'Filtrar por' : 'Filter by',
        clearAll: sessionStorage.getItem('lang') == 'es' ? 'Reiniciar' : 'Reset',
        delete: sessionStorage.getItem('lang') == 'es' ? 'Eliminar' : 'Delete',
        left: sessionStorage.getItem('lang') == 'es' ? 'Izquierda' : 'Left',
        logicAnd: sessionStorage.getItem('lang') == 'es' ? 'AND' : 'AND',
        logicOr: sessionStorage.getItem('lang') == 'es' ? 'OR' : 'OR',
        value: sessionStorage.getItem('lang') == 'es' ? 'Listado' : 'List',
        title: {
          0: sessionStorage.getItem('lang') == 'es' ? 'Filtrar por cualquier columna' : 'Filter by any column',
          _: sessionStorage.getItem('lang') == 'es' ? 'Filtrar por cualquier columna (%d)' : 'Filter by any column (%d)'
        },
        conditions: {
          date: {
            after: sessionStorage.getItem('lang') == 'es' ? 'Después de' : 'After',
            before: sessionStorage.getItem('lang') == 'es' ? 'Antes de' : 'Before',
            between: sessionStorage.getItem('lang') == 'es' ? 'Entre' : 'Between',
            empty: sessionStorage.getItem('lang') == 'es' ? 'Vacío' : 'Empty',
            equals: sessionStorage.getItem('lang') == 'es' ? 'Igual a' : 'Equals',
            not: sessionStorage.getItem('lang') == 'es' ? 'Diferente de' : 'Not',
            notBetween: sessionStorage.getItem('lang') == 'es' ? 'No entre' : 'Not Between',
            notEmpty: sessionStorage.getItem('lang') == 'es' ? 'No vacío' : 'Not Empty'
          },
          string: {
            contains: sessionStorage.getItem('lang') == 'es' ? 'Contiene' : 'Contains',
            empty: sessionStorage.getItem('lang') == 'es' ? 'Vacío' : 'empty',
            notEmpty: sessionStorage.getItem('lang') == 'es' ? 'No vacío' : 'Not Empty',
            equals: sessionStorage.getItem('lang') == 'es' ? 'Igual a' : 'equals',
            not: sessionStorage.getItem('lang') == 'es' ? 'No' : 'Not',
            endsWith: sessionStorage.getItem('lang') == 'es' ? 'Termina con' : 'Ends with',
            startsWith: sessionStorage.getItem('lang') == 'es' ? 'Comienza con' : 'Starts With'
          },
          moment: {
            before: sessionStorage.getItem('lang') == 'es' ? 'Antes de' : 'Before',
            after: sessionStorage.getItem('lang') == 'es' ? 'Después de' : 'After',
            equals: sessionStorage.getItem('lang') == 'es' ? 'Igual a' : 'equals',
            not: sessionStorage.getItem('lang') == 'es' ? 'No' : 'Not',
            between: sessionStorage.getItem('lang') == 'es' ? 'Entre' : 'Between',
            notBetween: sessionStorage.getItem('lang') == 'es' ? 'No entre' : 'Not Between',
            empty: sessionStorage.getItem('lang') == 'es' ? 'Vacío' : 'empty',
            notEmpty: sessionStorage.getItem('lang') == 'es' ? 'No vacío' : 'Not Empty'
          },
          number: {
            equals: sessionStorage.getItem('lang') == 'es' ? 'Igual a' : 'equals',
            not: sessionStorage.getItem('lang') == 'es' ? 'Diferente de' : 'Not',
            gt: sessionStorage.getItem('lang') == 'es' ? 'Mayor a' : 'Greater than',
            gte: sessionStorage.getItem('lang') == 'es' ? 'Mayor o igual a' : 'Greater than equal to',
            lt: sessionStorage.getItem('lang') == 'es' ? 'Menor que' : 'Smaller than',
            lte: sessionStorage.getItem('lang') == 'es' ? 'Menor o igual a' : 'less than equal to',
            between: sessionStorage.getItem('lang') == 'es' ? 'Entre' : 'Between',
            notBetween: sessionStorage.getItem('lang') == 'es' ? 'No vacío' : 'Not Empty',
            empty: sessionStorage.getItem('lang') == 'es' ? 'Vacío' : 'empty',
            notEmpty: sessionStorage.getItem('lang') == 'es' ? 'No vacío' : 'Not Empty'
          },
        }
      }
    },
    buttons: [
      'searchBuilder',
      {
        extend: 'pdfHtml5',
        messageTop: 'Reporte de pagos',
      },
      {
        extend: 'csv',
        text: 'Excel',
        charset: 'UTF-16LE',
        bom: true
      },
      'copy', 'print'
    ]
  };


  constructor(
    private router: Router,
    private service: ReportService,
    //private invoice: Invoice,
    private paymentService: PaymentService,
  ) { }

  ngOnInit(): void {
    let rolUser: any = localStorage.getItem('rol');

    if (!localStorage.getItem('token')) {
      this.router.navigate(['/login'])
    } else if (rolUser == 1) {
      this.router.navigate(['/payment'])
    }

    let langVery = sessionStorage.getItem('lang');
    let setting = this.datosTable
    this.listcompany = langVery == 'es' ? 'Seleccionar' : 'Select';
    this.getPaymentReport();
    //this.getDetailPayment(33211)

    setTimeout(function () {
      $(function () {
        $('#mytable').DataTable(setting);
      });
    }, 1000);
  }

  searchInfoOther() {
    let langVery = sessionStorage.getItem('lang');
    let filterBy = $('#filterBy').val()
    let companyInput = $('#company').val()
    let fecha = $('#from').val()
    let setting = this.datosTable
    let [dayFrom, monthFrom, yearFrom] = fecha.split('-');
    let dateFrom = `${yearFrom}-${monthFrom}-${dayFrom}`;
    //console.log('companyInput: '+companyInput+' fecha '+fecha+' filterBy: '+filterBy)

    if (companyInput == null && fecha == '') {
      Swal.fire({
        position: 'center',
        icon: 'warning',
        title: langVery == 'es' ? 'Debes seleccionar un filtro para hacer la búsqueda' : 'You must select a filter to search',
        showConfirmButton: true,
      });
    } else if (filterBy == 'CustomerName' && companyInput != null && fecha == '') {
      console.log('1 by CustomerName: ' + companyInput.substr(4))
      this.clearnDataRefresh()
      setTimeout(function () {
        $(function () {
          $('#mytable').DataTable(setting).columns(1).search(companyInput.substr(4)).draw();
        });
      }, 100);

    } else if (filterBy == 'CustomerID' && companyInput != null && fecha == '') {
      console.log('2 by CustomerID: ' + companyInput.substr(4))
      this.clearnDataRefresh()
      setTimeout(function () {
        $(function () {
          $('#mytable').DataTable(setting).columns(2).search(companyInput.substr(4)).draw();
        });
      }, 100);

    } else if (filterBy == 'InvoiceNumber' && companyInput != null && fecha == '') {
      console.log('3 by InvoiceNumber: ' + companyInput.substr(4))
      this.clearnDataRefresh()
      setTimeout(function () {
        $(function () {
          $('#mytable').DataTable(setting).columns(4).search(companyInput.substr(4)).draw();
        });
      }, 100);

    } else if (filterBy == 'Amount' && companyInput != null && fecha == '') {
      console.log('4 by Amount: ' + companyInput.substr(2))
      this.clearnDataRefresh()
      setTimeout(function () {
        $(function () {
          $('#mytable').DataTable(setting).columns(6).search(companyInput.substr(2)).draw();
        });
      }, 100);

    } else if (companyInput == null && fecha != '') {
      this.clearnDataRefresh()
      setTimeout(function () {
        $(function () {
          $('#mytable').DataTable(setting).columns(3).search(dateFrom).draw();
        });
      }, 900);

    } else if (filterBy == 'CustomerName' && companyInput != null && fecha != '') {
      this.clearnDataRefresh()
      setTimeout(function () {
        $(function () {
          $('#mytable').DataTable(setting).column(1).search(companyInput.substr(4)).column(3).search(dateFrom).draw();
        })
      }, 100);

    } else if (filterBy == 'CustomerID' && companyInput != null && fecha != '') {
      this.clearnDataRefresh()
      setTimeout(function () {
        $(function () {
          $('#mytable').DataTable(setting).column(2).search(companyInput.substr(4)).column(3).search(dateFrom).draw();
        })
      }, 100);

    } else if (filterBy == 'InvoiceNumber' && companyInput != null && fecha != '') {
      this.clearnDataRefresh()
      setTimeout(function () {
        $(function () {
          $('#mytable').DataTable(setting).column(4).search(companyInput.substr(4)).column(3).search(dateFrom).draw();
        })
      }, 100);

    } else if (filterBy == 'Amount' && companyInput != null && fecha != '') {
      this.clearnDataRefresh()
      setTimeout(function () {
        $(function () {
          $('#mytable').DataTable(setting).column(6).search(companyInput.substr(2)).column(3).search(dateFrom).draw();
        })
      }, 100)
    } else {
      $('#mytable').DataTable(setting).search('').draw();
    }
  }


  clearnDataRefresh() {
    $('#mytable').DataTable().destroy();
    //this.getPaymentReport();
  }

  clearTable(){
    $('.mytableDos').DataTable().destroy();
  }

  refreshData() {
    let setting = this.datosTable
    $('#mytable').DataTable().destroy();
    this.clearnDataRefresh()
    setTimeout(function () {
      $(function () {
        $('#mytable').DataTable(setting)
      })
    }, 1000);
  }


  byDateSearch() {
    let from = $('#from').val();
    let [dayFrom, monthFrom, yearFrom] = from.split('-');
    let dateFrom = `${yearFrom}-${monthFrom}-${dayFrom}`;

    //$('#mytable').DataTable().columns(3).search(date).draw();
    //$('#mytable').DataTable().columns(3).search(dateFrom).draw();
  }

  byDateSearchTwo() {
    let from = $('#from').val();
    let [dayFrom, monthFrom, yearFrom] = from.split('-');
    let dateFrom = `${yearFrom}-${monthFrom}-${dayFrom}`;

    let to = $('#to').val();
    let [dayTo, monthTo, yearTo] = from.split('-');
    let dateTo = `${yearTo}-${monthTo}-${dayTo}`;

    $('#mytable').DataTable().columns(3).search(dateFrom).draw();
    // Buscar por cliente y fecha
    //$('#mytable').DataTable().column(3).search(dateFrom).column(4).search(dateTo).draw();

  }


  // Custom filtering function which will search data in column four between two values

  getPaymentReport() {
    this.paymentReportModel = []
    this.service.paymentReport().subscribe({
      next: (data: any) => {
        for (const element of data) {
          let payment = new PaymentReport();
          payment.Amount = element.Amount;
          payment.CompanyName = element.CompanyName;
          payment.CustomerID = element.CustomerID;
          payment.Date = element.Date;
          payment.InvoiceNumber = element.InvoiceNumber;
          payment.CustomerName = element.CustomerName;
          payment.PaymentID = element.PaymentID;
          payment.dias_transcurridos = element.dias_transcurridos;
          payment.nombreCliente = element.nombreCliente;

          this.paymentReportModel.push(payment);
        }

        console.log(this.paymentReportModel);
      },
      error: (error) => {
        console.log(error);
      }
    })
  }

  getDetailPayment(paymentId: any) {
    this.listDetailInvoice = []
    this.paymentService.getDetailPayment(paymentId).subscribe({
      next: (data: any) => {
        //console.log(data)
        for (const element of data) {
          let bills = new Bills();
          bills.amounttopay = element.amounttopay;
          bills.customerid = element.CustomerID;
          bills.idbillspaid = element.idbillspaid;
          bills.invoiceamount = element.InvoiceAmount;
          bills.invoicedate = element.invoicedate;
          bills.invoiceid = element.invoiceid;
          bills.amounttopay = element.AmountToPay;
          bills.invoicekey = element.invoicekey;
          bills.invoicenumber = element.InvoiceNumber;
          bills.paymentid = element.PaymentId;
          bills.userid = element.userid;
          bills.customername = element.CustomerName;
          bills.date = element.Date;
          bills.dias_transcurridos = element.dias_transcurridos;
          bills.companyname = element.CompanyName;
          bills.customernumber = element.CustomerNumber;
          this.listDetailInvoice.push(bills)
        }
        console.log(this.listDetailInvoice);

        setTimeout(function () {
          $(function () {
            $('.mytableDos').DataTable({
              dom: 'Bfrtip',
              language: {
                url: sessionStorage.getItem('lang') == 'es' ? "//cdn.datatables.net/plug-ins/1.10.15/i18n/Spanish.json" : "//cdn.datatables.net/plug-ins/1.10.15/i18n/English.json",
              },
              buttons: [
                'searchBuilder',
                {
                  extend: 'pdfHtml5',
                  messageTop: 'Reporte de pagos',
                },
                {
                  extend: 'csv',
                  text: 'Excel',
                  charset: 'UTF-16LE',
                  bom: true
                },
                'copy', 'print'
              ]
            });
          });
        }, 1000);
        //this.listDetailInvoice = data
      },
      error: (error) => {
        console.log(error);
      }
    })
  }

  filterByOnChange(value: string) {
    this.activeFilter = value;
    this.service.paymentFilterby(value).subscribe({
      next: (data: any) => {
        //debugger
        console.log(data);
        switch (this.activeFilter) {
          case "CustomerName":
            this.list = [];
            for (const element of data.data as Array<any>) {
              this.list.push(element.CustomerName);
            }
            break;
          case "CustomerID":
            this.list = [];
            for (const element of data.data as Array<any>) {
              this.list.push(element.CustomerID);
            }
            break;
          case "InvoiceNumber":
            this.list = [];
            for (const element of data.data as Array<any>) {
              this.list.push(element.InvoiceNumber);
            }
            break;
          case "Amount":
            this.list = [];
            for (const element of data.data as Array<any>) {
              this.list.push(element.Amount);
            }
            break;
          default:
            this.list = [];
            break;
        }
        console.log(this.list);
      },
      error: (error) => {
        console.log(error);
      }
    })
  }

  searchInfo() {
    let item = {
      filter: this.activeFilter,
      value: this.listcompany
    };
    debugger
    this.service.paymentReportWithFilter(item).subscribe({
      next: (data: any) => {
        debugger
        this.paymentReportModel = [];
        for (const element of data) {
          let payment = new PaymentReport();
          payment.Amount = element.Amount;
          payment.CompanyName = element.CompanyName;
          payment.CustomerID = element.CustomerID;
          payment.Date = element.Date;
          payment.InvoiceNumber = element.InvoiceNumber;
          payment.CustomerName = element.CustomerName;

          this.paymentReportModel.push(payment);
        }
        console.log(this.paymentReportModel);
      },
      error: (error) => {
        console.log(error);
      }
    })
  }
  /*
  SELECT TOP 100 c.CompanyName, cc.CustomerName, p.CustomerID, d.Date, p.InvoiceNumber, p.Amount
  FROM [CONWASTE_PAYMENT_APP].[ConwasteApp].[Payment] p
  INNER JOIN [CONWASTE_PAYMENT_APP].[ConwasteApp].[Company] c ON c.CompanyID = p.CompanyID
  INNER JOIN [CONWASTE_PAYMENT_APP].[ConwasteApp].[Customer] cc ON cc.CustomerID = p.customerID
  INNER JOIN [CONWASTE_PAYMENT_APP].[ConwasteApp].[Date] d ON d.DateID = p.DateID
  */

}
