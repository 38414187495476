<div class="container">
  <div class="row justify-content-center">
    <h1 class="h1 text-primary text-center">{{ 'Profile' | translate }}</h1>
    <h5 class="h5 text-center">{{ 'Edit_Note' | translate }}</h5>
    <br />
    <br />
    <div class="col"></div>

    <div class="col justify-content-center">
      <div class="card">
        <div class="card-header text-center">
          <img
            src="../../assets/logo_conwaste_new.png"
            class="card-img-top"
            style="width: 300px !important;"
            alt=""
          />
        </div>
        <div class="card-body">
          <form
            #editForm="ngForm"
            (ngSubmit)="edit(editForm.value)"
            class="form"
            autocomplete="off"
          >
            <div class="form-group">
              <label for="Title">{{ 'First_name' | translate }}</label>
              <div class="input-group">
                <span class="input-group-text" id="basic-addon3">
                  <i class="bi bi-person-fill"></i>
                </span>
                <input
                  type="text"
                  class="form-control"
                  name="firstname"
                  [(ngModel)]="userFirstName"
                />
              </div>
            </div>

            <div class="form-group">
              <label for="Title">{{ 'Last_name' | translate }}</label>
              <div class="input-group">
                <span class="input-group-text" id="basic-addon4">
                  <i class="bi bi-person-fill"></i>
                </span>
                <input
                  type="text"
                  class="form-control"
                  name="lastname"
                  [(ngModel)]="userLastName"
                />
              </div>
            </div>

            <div class="form-group">
              <label for="Title">{{ 'E-mail' | translate }}</label>
              <div class="input-group">
                <span class="input-group-text" id="basic-addon5">
                  <i class="bi bi-envelope-fill"></i>
                </span>
                <input
                  type="email"
                  class="form-control"
                  name="email"
                  [(ngModel)]="userEmail"
                />
              </div>
            </div>

            <div *ngIf="passwordChange" class="form-group">
              <label for="Title">{{ 'Password' | translate }}</label>
              <div class="input-group">
                <span class="input-group-text" id="basic-addon6">
                  <i class="bi bi-lock-fill"></i>
                </span>
                <input
                  [type]="show ? 'text' : 'password'"
                  class="form-control"
                  name="password"
                  ngModel
                  #PasswordVal
                />
                <span class="input-group-text" id="basic-addon6">
                  <mat-icon
                    type="button"
                    svgIcon="{{ show ? 'eye-open' : 'eye-closed' }}"
                    (click)="ShowPassword()"
                  ></mat-icon>
                </span>
              </div>
            </div>

            <div *ngIf="passwordChange" class="form-group">
              <label for="Title">{{ 'Confirm_password' | translate }}</label>
              <div class="input-group">
                <span class="input-group-text" id="basic-addon7">
                  <i class="bi bi-lock-fill"></i>
                </span>
                <input
                  [type]="showConfirm ? 'text' : 'password'"
                  class="form-control"
                  name="confirmpassword"
                  ngModel
                  #confirmpasswordVal
                />
                <span class="input-group-text" id="basic-addon6">
                  <mat-icon
                    type="button"
                    svgIcon="{{ showConfirm ? 'eye-open' : 'eye-closed' }}"
                    (click)="ShowPasswordTwo()"
                  ></mat-icon>
                </span>
              </div>
            </div>
            <div class="form-check">
              <br />
              <input
                class="form-check-input"
                type="checkbox"
                (change)="allowPasswordChange()"
                value=""
                required
                id="flexCheckIndeterminate"
              />
              <label>{{ 'Change_password' | translate }}</label>
            </div>

            <!-- <div class="form-check text-center">
              <br />
              <input
                class="form-check-input"
                type="checkbox"
                value=""
                required
                id="flexCheckIndeterminate"
              />
              <label class="form-check-label" for="flexCheckIndeterminate">
                {{
                  'By_creating_an_account_it_means_you_agree_to_the' | translate
                }}
                <a href="#">
                  <strong>{{ 'Terms_y_Conditions' | translate }}</strong>
                </a>
                {{ 'and_our' | translate }}
                <a href="#">
                  <strong>{{ 'Privacy_Policy' | translate }}</strong>
                </a>
              </label>
            </div> -->
            <!--Other fields-->
            <div class="text-center">
              <br />
              <input
                type="submit"
                class="btn btn-r btn-lg btn-primary"
                value="{{ 'Save' | translate }}"
              />
            </div>
          </form>
        </div>
      </div>
    </div>
    <div class="col"></div>
  </div>
</div>
