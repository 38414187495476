export class Bills {
    amounttopay: number;
    customerid: number;
    idbillspaid: number;
    invoiceamount: number;
    invoicedate: Date
    invoiceid: number;
    invoicekey: number;
    invoicenumber: string;
    paymentid: number;
    userid: number;
    customername: string;
    date: Date;
    dias_transcurridos: number;
    customernumber: number;
    companyname: string;
}