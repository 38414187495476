<div class="container">
  <div class="row justify-content-center">
    <h5 class="h5 text-left">{{ 'Reporte-account' | translate }}</h5>
    <!--<form #filterForm="ngForm" class="row form" autocomplete="off"
      style="margin-top: 10px;margin-bottom: 15px;width: 100%;">
      <div class="col-md-3">
        <label for="cliente">{{ 'filter' | translate }}</label>
        <select #filterBy (change)="filterByOnChange(filterBy.value)" id="filterBy" name="company" class="form-select"
          aria-label="Selecciona">
          <option selected>Selecciona</option>
          <option value="CustomerName">{{ 'CustomerName' | translate }}</option>
          <option value="CustomerNumber">{{ 'NoAccount' | translate }}</option>
        </select>
      </div>
      <div class="col-md-3">
        <label for="cliente">{{ 'List' | translate }}</label>
        <select #company id="company" [(ngModel)]="listcompany" name="company" class="form-select"
          aria-label="Selecciona">
          <option selected>{{ 'Select' | translate }}</option>
          <option *ngFor="let comp of list" [ngValue]="comp">
            {{ comp.CustomerName ?? comp.CustomerNumber }}
          </option>
        </select>
      </div>
      <div class="col-md-1">
        <button class="btn btn-primary" (click)="searchInfoOther()" style="margin-top: 25px;">{{ 'Search' | translate
          }}</button>
      </div>
      <div class="col-md-2">
      </div>
    </form>-->
    <div class="col justify-content-center">
      <div class="card">
        <div class="card-header">
        </div>
        <div class="card-body text-center">
          <div class="table-responsive">
            <table class="table mytable display nowrap " id="mytableAccount">
              <thead>
                <tr>
                  <th>{{ 'Company_Name2' | translate }}</th>
                  <th>{{ 'CustomerName' | translate }}</th>
                  <th>{{ 'NoAccount' | translate }}</th>
                  <th>{{ 'Phone_Number2' | translate }}</th>
                </tr>
              </thead>
              <tbody>
                <th *ngIf="accountReportModel.length == 0" colspan="7">{{ 'NoEnvoicesToShow' | translate }}</th>
                <tr *ngFor="let info of accountReportModel">
                  <td>{{info.CompanyName}}</td>
                  <td>{{info.CustomerName}}</td>
                  <td>{{info.CustomerNumber}}</td>
                  <td>{{info.PhoneNumber | phoneFormat }}</td>
              </tbody>
            </table>
            <!--<div class="d-flex justify-content-between p-2 column" style="align-items: center;justify-content: center;display: block;">
              <ngb-pagination [collectionSize]="collectionSize" [(page)]="page" [pageSize]="pageSize"
                (pageChange)="refreshInvoices()" [maxSize]="5"></ngb-pagination>
            </div>-->
          </div>
        </div>
      </div>
    </div>
  </div>
</div>