import {
  HttpClient,
  HttpErrorResponse,
  HttpHeaders,
  HttpParams,
} from '@angular/common/http'
import { Injectable } from '@angular/core'
import { Observable, of, throwError, tap, Subject } from 'rxjs'
import { catchError, retry, map } from 'rxjs/operators'
import { Hello } from '../hello'
import { IP } from '../IP'

@Injectable({
  providedIn: 'root',
})
export class RegisterService {
  //It seems that you must name the "global variable" here
  private apiUrl = IP

  constructor(private http: HttpClient) {
    //and then declare the type of said variable here
  }

  createUser(userdata: any) {
    console.log('In createUser function')
    let value
    var email = userdata.email
    var password = userdata.password
    var confirmpassword = userdata.confirmpassword
    var emailformat = /\S+@\S+.\S+/
    var passwordformat = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*])[0-9a-zA-Z!@#$%^&*]{8,16}$/
    var userID = localStorage.getItem('item')
    var email_validated = emailformat.test(email)
    var password_validated = passwordformat.test(password)

    
    if (password != '' && password == confirmpassword && password_validated == true && email_validated == true) {
      console.log('Step 1 passed')
      value = this.http.post(
        this.apiUrl + '/userlog/createUser',
        { newuser: userdata },
        {},
      )
      console.log(value)
      console.log(value)
    } else {
      console.log('Email and or password incorrect')
    }
    return value
  }

  logAfterRegister(email: string, password: string) {
    let userData = { useremail: email, userpassword: password}
    console.log('In logAfterRegister function')
    return this.http.post(
      this.apiUrl + '/userlog/getTokenAfterRegister',
      userData,
      {
        withCredentials: true,
      },
    )
  }

  getUsersAdmin() {
    let value;
    value = this.http.get(IP + '/userlog/getUsersAdmin', {
      headers: {
        token: localStorage.getItem('token'),
      },
    });
    console.log(value);
    return value;
  }

  httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
  }
}
