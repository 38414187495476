import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { IP } from '../IP'

@Injectable({
  providedIn: 'root'
})
export class ForgotPassword {

  private apiUrl = IP
  constructor(
    private http: HttpClient
  ) { }
  // fernandohbd10@gmail.com
  resetPassword(data: any) {
    console.log(data)

    let value = this.http.post(
      this.apiUrl + '/userlog/fortgotPass',
      { newuser: data },
      {},
    )
    console.log(value)
    return value
  }
}
