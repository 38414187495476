import {
  Component,
  OnInit,
  AfterViewInit,
  NgZone,
  Inject,
  PLATFORM_ID,
  ViewChild,
  ElementRef,
} from '@angular/core';
import { Stripe } from 'stripe';
import { PaymentService } from 'src/app/services/payment.service';
// import { Calendar } from "@fullcalendar/core";
// import dayGridPlugin from "@fullcalendar/daygrid";
import * as moment from 'moment';
import { isPlatformBrowser } from '@angular/common';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import Swal from 'sweetalert2'

@Component({
  selector: 'app-stripe',
  templateUrl: './stripe.component.html',
  styleUrls: ['./stripe.component.css'],
})
export class StripeComponent implements OnInit {
  //stripe = new Stripe('pk_test_TYooMQauvdEDq54NiTphI7jx')
  @ViewChild('email') inputemail: ElementRef<HTMLInputElement>;
  @ViewChild('cardName') inputcardName: ElementRef<HTMLInputElement>;
  @ViewChild('cardNumber') inputcardNumber: ElementRef<HTMLInputElement>;
  @ViewChild('expMonth') inputcardexpMonth: ElementRef<HTMLInputElement>;
  @ViewChild('cvc') inputcvc: ElementRef<HTMLInputElement>;
  @ViewChild('telefono') inputtelefono: ElementRef<HTMLInputElement>;
  @ViewChild('expYear') inputexpYear: ElementRef<HTMLInputElement>;
  //@ViewChild('expYear') inputexpYear: ElementRef<HTMLInputElement>;


  // The items the customer wants to buy
  items = [{ id: 'xl-tshirt' }];

  selectedInvoices: any;
  totalAmount: any;
  creditCards: any;
  saveMessage: string = 'Your profile has been saved!'
  saveAction: any = 'X'
  invoicesToPay: any;
  checkIfPayment: boolean = false;
  checkIfPaymentTwo: boolean = true;
  selectedItems: number = 0;
  counter: number = 0;
  idStripePayment: string;
  /**/
  customeridNew: any;
  companyid: any;
  invoicekey: any
  userid: any
  AllDatos: any
  pepe: any
  invoicenumber: any
  invoiceamount: any
  invoiceIdSet: any
  invoicesInfo: any[] = [];

  constructor(
    private paymentService: PaymentService,
    private router: Router,
    private snackBar: MatSnackBar
  ) { }

  ngOnInit(): void {
    this.getCreditCards();
    this.getInvoicesToPay();
  }

  alertValidate(messageAlert: string) {
    this.snackBar.open(messageAlert, 'X', {
      duration: 3000,
      panelClass: ['red-snackbar'],
      verticalPosition: 'top', // Allowed values are  'top' | 'bottom'
      horizontalPosition: 'center',
    })
  }

  notifySave() {
    let langVery = sessionStorage.getItem('lang');
    this.snackBar.open(this.saveMessage, this.saveAction, {
      duration: 3000,
      panelClass: ['green-snackbar'],
    })
  }

  createCard(formData: any) {
    console.log(formData);
    let UserStripeId = '';
    let checkAnswer = false;

    let validSet, emailRegex = /^[-\w.%+]{1,64}@(?:[A-Z0-9-]{1,63}\.){1,125}[A-Z]{2,63}$/i;
    if (emailRegex.test(formData.email)) { validSet = 1; } else { validSet = 2; }
    if (formData.email == '' || validSet == 2) {
      this.alertValidate('Este correo electronico es invalido')
    } else if (formData.cardName.trim().length < 3) {
      this.alertValidate('Nombre debe de tener un minimo de 3 caracteres')
    } else if (formData.cardNumber.trim().length < 15) {
      this.alertValidate('El numero de tarjeta es invalido')
    } else if (formData.cvc.trim().length < 3) {
      this.alertValidate('El numero de cvc es invalido')
    } else if (formData.expMonth.trim().length < 2) {
      this.alertValidate('El numero de cvc es invalido')
    } else if (formData.expYear.trim().length < 4) {
      this.alertValidate('El numero de cvc es invalido')
    } else {

    }
  }

  checkedState(event: any, checkBox: any, value: string) {
    if (event.target.checked === true) {
      if (this.counter < 1) {
        this.counter++
        console.log('Value: ' + value);
        this.idStripePayment = value;
        this.checkIfPayment = true
        this.checkIfPaymentTwo = false
      } else {
        event.target.checked = false;
      }
    } else if (this.counter > 0) {
      this.counter--;
      this.checkIfPayment = false
      this.checkIfPaymentTwo = true
    }
  }

  getCreditCards() {
    console.log('get cards');
    this.paymentService.checkStripeId().subscribe({
      next: (res: any) => {
        console.log(res);
        if (res.ans) {
          this.paymentService.getCardList(res.stripeid).subscribe({
            next: (res: any) => {
              console.log(res);
              this.creditCards = res.data;
            },
            error: (error) => {
              console.log('Error: ');
              console.log(error);
            },
          });
        }
      },
      error: (error) => {
        console.log('Error: ');
        console.log(error);
      },
    });
  }

  deleteCard(id: string, customer: any) {
    let langVery = sessionStorage.getItem('lang');
    console.log('Customer: ' + customer + ' id:' + id)
    let datos = {
      id: id,
      customer: customer
    }

    Swal.fire({
      icon: "warning",
      title: langVery == 'es' ? '¿Seguro que quieres eliminar este método de pago?' : 'Are you sure you want to remove this payment method?',
      showDenyButton: true,
      showCancelButton: true,
      confirmButtonText: langVery == 'es' ? 'Si' : 'Yes',
      //denyButtonText: `Don't save`,
    }).then((result) => {
      if (result.isConfirmed) {
        this.paymentService.deleteCard(id, customer).subscribe({
          next: (res: any) => {
            console.log(res);
            this.getCreditCards();
          },
          error: (error) => {
            console.log('Error: ');
            console.log(error);
          },
        });
      }
    })
  }

  deleteInvoiceCarty(value: any) {
    let langVery = sessionStorage.getItem('lang');
    Swal.fire({
      icon: "question",
      title: langVery == 'es' ? '¿Seguro que quieres eliminar esta factura?' : 'Are you sure you want to delete this invoice?',
      showDenyButton: true,
      //showCancelButton: true,
      confirmButtonText: langVery == 'es' ? 'Si' : 'Yes',
      //denyButtonText: `Don't save
    }).then((result) => {
      if (result.isConfirmed) {
        this.paymentService.deleteInvoice(value).subscribe({
          next: (res: any) => {
            console.log(res);
            this.getInvoicesToPay();
          },
          error: (error) => {
            console.log('Error: ');
            console.log(error);
          },
        });
      }
    })
    /*console.log('Customer: ' + customer + ' id:' + id)
    let datos = {
      id: id,
      customer: customer
    }
    Swal.fire({
      title: 'Seguro que quieres eliminar este metodo de pago?',
      showDenyButton: true,
      showCancelButton: true,
      confirmButtonText: 'Si',
      //denyButtonText: `Don't save`,
    }).then((result) => {
      if (result.isConfirmed) {
        this.paymentService.deleteCard(id, customer).subscribe({
          next: (res: any) => {
            console.log(res);
            this.getCreditCards();
          },
          error: (error) => {
            console.log('Error: ');
            console.log(error);
          },
        });
      } 
    })*/
  }

  getInvoicesToPay() {
    let langVery = sessionStorage.getItem('lang');
    this.paymentService.getInvoicesToPay().subscribe({
      next: (res: any) => {
        if (res.values == '') {
          Swal.fire({
            icon: 'error',
            title: langVery == 'es' ? 'No tienes factura en el carrito' : 'You do not have an invoice in the cart',
            confirmButtonText: 'OK',
            allowOutsideClick: false
          }).then((result) => {
            if (result.isConfirmed) {
              this.router.navigate(['/payment'])
            }
          })
        } else {
          for (const invoice of res.values) {
            console.log(res.values)
            let invoiceData = {
              id: invoice.invoiceid,
              amountToPay: invoice.amounttopay
            }

            this.invoicesInfo.push(invoiceData);
          }
          console.log(this.invoicesInfo);
        
          this.invoicesToPay = res.values;
          console.log(res);
          console.log(res.values[0].invoicenumber);
          console.log('CompanyID: '+res.values[0].companyid)
          this.customeridNew = res.values[0].customerid;
          this.invoicekey = res.values[0].invoicekey
          this.userid = res.values[0].userid
          this.companyid = res.values[0].companyid
          this.invoicenumber = res.values[0].invoicenumber
          this.invoiceamount = res.values[0].invoiceamount
          this.invoiceIdSet = res.values[0].invoiceid
          this.totalAmount = res.values.reduce((sum: any, current: any) => {
            return sum + current.amounttopay;
          }, 0);
        }
      },
      error: (error) => {
        console.log('Error: ');
        console.log(error);
      },
    });
  }

  Pay() {
    this.checkIfPaymentTwo = true
    console.log('IdPay: ' + this.idStripePayment)
    let langVery = sessionStorage.getItem('lang');
    ////this.creditCards[0].id,
    this.paymentService.checkStripeId().subscribe({
      next: (res: any) => {
        console.log(res);
        let checkAnswer = res.ans;
        console.log(checkAnswer);
        let UserStripeId = res.stripeid;
        this.paymentService
          .payInvoicesWithStripeId(
            this.totalAmount,
            this.idStripePayment,
            UserStripeId
          )
          .subscribe({
            next: (res: any) => {
              console.log(res);
              this.pepe = 'asd';

              let hoy = new Date();
              let fecha = hoy.getFullYear() + '-' + (hoy.getMonth() + 1) + '-' + hoy.getDate();
              let hora = hoy.getHours() + ':' + hoy.getMinutes() + ':' + hoy.getSeconds();
              let fechaYHoraSet = fecha + ' ' + hora;
              let link = res.charges.data[0].receipt_url;

              console.log(link);
              let datos = {
                dateid: 20000101,
                perioddateid: 1,
                customerid: this.customeridNew,
                paymenttypeid: 39,
                companyid: this.companyid | 0,
                //paymentkey: 1,
                invoicekey: this.invoicekey,
                amount: this.totalAmount,
                stripetransactionid: res.id,
                dateupdated: fechaYHoraSet,
                userid: this.userid,
                invoiceNumber: this.invoicenumber,
                invoiceLink: link
              }
              console.log('invoiceamount: ' + this.invoiceamount)
              console.log(datos);

              this.paymentService.createInvoiceStripe(datos, this.invoicesInfo).subscribe({
                next: (resi: any) => {
                  console.log(resi);
                  this.paymentService.deleteInvoicesToPay(this.totalAmount, this.invoiceamount, this.invoicenumber, this.invoiceIdSet).subscribe({
                    next: (res: any) => {
                      console.log(res);
                      this.checkIfPaymentTwo = false
                      Swal.fire({
                        icon: "success",
                        title: langVery == 'es' ? 'Su pago fue realizado con éxito' : 'Your payment was successful',
                        text: langVery == 'es' ? 'El pago realizado se reflejará al próximo día.' : 'The payment made will be reflected the next day.',
                        //showDenyButton: true,
                        //showCancelButton: true,
                        confirmButtonText: 'OK',
                        //denyButtonText: `Don't save
                      }).then((result) => {
                        if (result.isConfirmed) {
                          this.router.navigate(['/payment'])
                        }
                      });

                    },
                    error: (error) => {
                      console.log('Error: deleteInvoicesToPay()');
                      console.log(error);
                    },
                  });
                },
                error: (error) => {
                  console.log('Error: createInvoiceStripe()');
                  console.log(error);
                },
              });
              this.creditCards = res.data;
            },
            error: (error) => {
              console.log('Error: ');
              console.log(error);
            },
          });
      },
      error: (error) => {
        console.log('Error: ');
        console.log(error);
      },
    });

    console.log(this.creditCards[0]);
  }


  ngAfterViewInit() {
    // Chart code goes in here
  }
}
