<div class="container">
  <h1 class="h1 text-primary text-center">
    2. {{ 'createYourAccount' | translate }}
  </h1>
  <h1 class="h1 text-primary text-center">
    <img src="../../assets/logo_conwaste_new.png" class="card-img-top" style="width: 300px !important;" alt="" />
  </h1>

  <div mdbModal #NumCliAlertModal="mdbModal" class="modal fade top" id="frameModalTop" tabindex="-1" role="dialog"
    aria-labelledby="myModalLabel" aria-hidden="true" style="overflow-y: auto">
    <!--Dialog-->
    <div class="modal-dialog modal-notify modal-info" role="document">
      <div class="modal-content">
        <div class="modal-header text-center">
          <h4 class="modal-title white-text w-100 font-weight-bold py-2">
            {{"ContactoTitleC"| translate}}
          </h4>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close"
            (click)="NumCliAlertModal.hide()">
            <span aria-hidden="true" class="white-text">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <div class="mat-table">
            <div class="mat-header-row">
              <!---<div class="mat-header-cell" style="font-size: 18px;">{{"Phone_Number2"| translate}}</div>-->
              <div class="mat-header-cell" style="font-size: 18px;">{{"E-mail"| translate}}</div>
            </div>
            <div class="mat-row">
              <!--<div class="mat-cell">787-273-7639</div>-->
              <div class="mat-cell">
                <a href="mailto:info@conwastepr.com" target="_blank">info@conwastepr.com</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <br />
  <div class="row justify-content-center">
    <div class="col-12 justify-content-center" style="text-align:center;
    margin:0 auto;">
      <div class="card" style="text-align:center;margin:0 auto;width: 40%;">
        <!--<div class="card-header text-center">
          <img src="../../assets/logo_conwaste_new.png" class="card-img-top" style="width: 300px !important;" alt="" />
        </div>-->
        <div class="card-body">
          <div class="row">
            <div>
              <label for="company">{{ 'Select_Company' | translate }}</label>
              <select #company id="company" name="company" [(ngModel)]="companySelection" class="form-control classic">
                <option *ngIf="NoComp" [ngValue]="null" [disabled]="true">
                  {{ 'No_Company' | translate }}
                </option>
                <option *ngFor="let comp of availableCompanies" [value]="comp.companyid">
                  {{ comp.companyname }}
                </option>
              </select>
            </div>
            <div>
              <label for="NumCliente">{{ 'Client_Number' | translate }}</label>
              <input #NumCliente maxlength="15" type="text" class="form-control" name="NumCliente"
                [(ngModel)]="numcliente" oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');" />
            </div>
             <!-- PHONE NUMBER --->
            <div *ngIf="showPhoneNumber">
              <label for="Telefono">{{ 'Phone_Number' | translate }}</label>
              <input #Telefono type="text" maxlength="10" class="form-control" name="Telefono"
                [(ngModel)]="phonenumber" oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');"/>
            </div>
             <!-- PHONE NUMBER --->
             <!-- SITE NUMBER --->
            <div *ngIf="showSiteNumber">
              <label for="SiteNumber">{{ 'Site_number' | translate }}</label>
              <input #SiteNumber id="siteNumber"  type="text" class="form-control" name="siteNumber"
              oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');"
              [(ngModel)]="siteNumber" />
            </div>
            <!-- SITE NUMBER --->
            <a style="margin:0;margin-top:5px;text-align: left;" #NumCliAlert routerLink="#"
              (click)="NumCliAlertModal.show()" mdbWavesEffect>
              <strong>{{ 'NoRecuerdo' | translate }}</strong>
            </a>
            <div>
              <br />
              <button type="button" style="float: right;" class="btn btn-lg btn-primary" (click)="
                  addCompany(company.value, '', NumCliente.value)">
                {{ 'Add_Company2' | translate }}
              </button>
            </div>
          </div>
        </div>
      </div>
      <div class="row" *ngIf="showLabel" style="margin-top: 20px;">
        <div style="height: auto; overflow-y: auto;">
          <Label for="list" style="font-size: 22px;">
            {{ 'List_Companies_Client' | translate }}
          </Label>
          <div class="mat-table" style="width: 65%; margin: 0 auto;">
            <div class="mat-header-row">
              <div class="mat-header-cell" style="text-align: center;">{{ 'Company_Name' | translate }}</div>
              <div class="mat-header-cell">{{ 'Phone_Number2' | translate }}</div>
              <div class="mat-header-cell" style="text-align: center;">{{ 'Customer_Number' | translate }}</div>
              <div class="mat-header-cell" style="text-align: center;">{{ 'Site_number' | translate }}</div>
              <div class="mat-header-cell">{{ 'Action' | translate }}</div>
            </div>
            <ng-container *ngFor="let comp of selectedCompanies">
              <div class="mat-row">
                <div class="mat-header-cell" style="text-align: center;">{{ comp.name }}</div>
                <div class="mat-header-cell" style="text-align: center;">{{ comp.telefono }}</div>
                <div class="mat-header-cell" style="text-align: center;">{{ comp.customernumber }}</div>
                <div class="mat-header-cell" style="text-align: center;">{{ comp.site }}</div>
                <div class="mat-header-cell">
                  <button style="font-size: small; text-align: center;" type="button"
                    class="material-icons-outlined btn btn-lg btn-danger" (click)="removeCompany(comp)">close</button>
                </div>
              </div>
            </ng-container>
          </div>
        </div>
        <div>
          <br />
          <button type="button" style="float: right;" class="btn btn-lg btn-success" (click)="saveUserCompanies()">
            {{ 'Finalize' | translate }}
          </button>
        </div>
      </div>
    </div>
    <div class="col"></div>
    <notifier-container></notifier-container>
  </div>
</div>