<div class="container">
    <header class="d-flex flex-wrap justify-content-center py-3 mb-4 border-bottom">
        <a href="/login" class="d-flex align-items-center mb-3 mb-md-0 me-md-auto text-dark text-decoration-none">
            <h1 class="text-primary">
                <i class="bi bi-house-door-fill"></i>
            </h1>
        </a>
        <ul class="nav nav-pills">
            <li class="nav-item">
                <button (click)="changeLang('en')" id="en" class="nav-link" aria-current="page">
                    <img src="../../../assets/USA-icon.png" width="30px" alt="">
                    <span>En</span>
                </button>
            </li>
            <li class="nav-item">
                <button (click)="changeLang('es')" id="es" class="nav-link btn active">
                    <img src="../../../assets/Flags-Puerto-rico.ico" width="30px" alt="">
                    <span>Es</span>
                </button>
            </li>
        </ul>
    </header>
</div>
<!-- Modales del Footer -->
<!--Modal Support-->
<div mdbModal #supportModal="mdbModal" class="modal fade top" id="frameModalTop" tabindex="-1" role="dialog"
    aria-labelledby="myModalLabel" aria-hidden="true" style="overflow-y: auto">
    <!--Dialog-->
    <div class="modal-dialog modal-notify modal-info" role="document">
        <div class="modal-content">
            <div class="modal-header text-center">
                <h4 class="modal-title white-text w-100 font-weight-bold py-2">
                    {{"Support"| translate}}
                </h4>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="supportModal.hide()">
                    <span aria-hidden="true" class="white-text">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                Modal Body
            </div>
        </div>
    </div>
</div>

<!--Modal Privacy-->
<div mdbModal #privacyModal="mdbModal" class="modal fade top" id="frameModalTop" tabindex="-1" role="dialog"
    aria-labelledby="myModalLabel" aria-hidden="true" style="overflow-y: auto">
    <!--Dialog-->
    <div class="modal-dialog modal-notify modal-info" role="document">
        <div class="modal-content">
            <div class="modal-header text-center">
                <h4 class="modal-title white-text w-100 font-weight-bold py-2">
                    {{"Privacy"| translate}}
                </h4>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="privacyModal.hide()">
                    <span aria-hidden="true" class="white-text">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                Modal Body
            </div>
        </div>
    </div>
</div>

<!--Modal Terms_y_Conditions-->
<div mdbModal #Terms_y_Conditions="mdbModal" class="modal fade top" id="frameModalTop" tabindex="-1" role="dialog"
    aria-labelledby="myModalLabel" aria-hidden="true" style="overflow-y: auto">
    <!--Dialog-->
    <div class="modal-dialog modal-notify modal-info" role="document">
        <div class="modal-content">
            <div class="modal-header text-center">
                <h4 class="modal-title white-text w-100 font-weight-bold py-2">
                    {{"Terms_y_Conditions"| translate}}
                </h4>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="Terms_y_Conditions.hide()">
                    <span aria-hidden="true" class="white-text">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                Modal Body
            </div>
        </div>
    </div>
</div>

<router-outlet></router-outlet>
<div class="clearfix"></div>
<div class="container step-2">
    <footer class="bd-footer py-5 my-5">
        <ul class="nav justify-content-center nav-fill border-bottom pb-3 mb-3">
            <li class="nav-item">
                <a href="https://www.conwastepr.com/es/contactanos/" target="_blank" class="nav-link px-2 text-muted">{{"Support"| translate}}</a>
            </li>
            <li class="nav-item">
                <a href="https://www.conwastepr.com/es/politica-de-privacidad/" target="_blank" class="nav-link px-2 text-muted">{{"Privacy" | translate}}</a>
            </li>
            <li class="nav-item">
                <a href="https://www.conwastepr.com/es/condiciones-y-terminos-de-uso/" target="_blank"  class="nav-link px-2 text-muted" >{{"Terms_y_Conditions" | translate}}</a>
            </li>
            <li class="nav-item">
                <a href="https://conwastepr.com" target="_blank" class="nav-link px-2 text-muted"><strong>Conwastepr.com</strong></a>
            </li>
        </ul>
        <p class="text-center text-muted">©{{year}}, {{"CopyR" | translate}}</p>
    </footer>
</div>