<div class="container">
  <div class="row justify-content-center">
    <div class="card bg-gradient-primary" style="height: 700px;">
      <div class="card-body">
        <h5>{{ "Credit Card Register" | translate }}</h5>
        <div class="col-md-6">
          <form class="form-primary" role="form">
            <h5>{{ "Tarjetas" | translate }}</h5>
            <!--<ngx-skeleton-loader count="3" *ngIf="cargandoTarjetas" appearance="line"></ngx-skeleton-loader>-->
            <div style="overflow: auto; height: 397px" *ngIf="creditCards && creditCards.length > 0; else empty">
              <ul class="nospace" *ngFor="let cc of creditCards">
                <li class="card">
                  <div class="row" style="margin: 0;padding: 5px;">
                    <div class="col-md-2">
                      <img src="../../assets/{{ cc.card.brand }}.png" style="width: 100%;" />
                    </div>
                    <div class="col-md-8">
                      <div>{{ cc.card.brand | uppercase }}</div>
                      <div>**** **** **** {{ cc.card.last4 }}</div>
                      <div>{{ cc.card.exp_month }} / {{ cc.card.exp_year }}</div>
                    </div>
                    <div class="col-md-2">
                      <button style="background: #bb2d3b;" class="btn btn-block btn-danger" (click)="deleteCard(cc.id, cc.customer)">
                        <span class="material-icons">delete_outline</span>
                      </button>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
            <ng-template #empty>
              <div style="overflow: auto; height: 397px">
                <ul class="nospace">
                  <li class="card" style="padding: 10px;color: #007c48;">{{'NoPayment' | translate }}</li>
                </ul>
              </div>
           </ng-template >
          </form>
        </div>

        <div class="col-md-6" style="height: 0;">
          <!-- registerForm.resetForm()-->
          <form 
            #registerForm="ngForm"
            (ngSubmit)="createCard(registerForm.value); "
            class="form-primary, checkout"
            autocomplete="off"
          >
            <div class="form-group">
              <h5>{{ "Entra Una Nueva Tarjeta" | translate }}</h5>
              <label>{{ "Email" | translate }}</label>
              <div class="input-group input-group-alternative mb-3">
                <input
                  ngModel
                  #email
                  class="form-control"
                  placeholder="{{ 'Email' | translate }}"
                  type="text"
                  name="email"
                />
              </div>
              <label>{{ "Name on card" | translate }}</label>
              <div class="input-group input-group-alternative mb-3">
                <input
                  class="form-control"
                  placeholder="{{ 'Name on card' | translate }}"
                  type="text"
                  ngModel
                  #cardName
                  name="cardName"
                />
              </div>
            </div>

            <div class="form-group">
              <label>{{ "Card number" | translate }}</label>
              <div class="input-group input-group-alternative mb-3">
                <input
                  #cardNumber
                  ngModel
                  class="form-control"
                  placeholder="{{ 'Card number' | translate }}"
                  type="text"
                  name="cardNumber"
                  maxlength="16"
                  oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');"
                />
              </div>
            </div>

            <div class="row">
              <div class="col-5">
                <div class="form-group">
                  <label>{{ "Expiration Month" | translate }}</label>

                  <div class="input-group input-group-alternative mb-3">
                    <input
                      #expMonth
                      ngModel
                      class="form-control"
                      placeholder="MM"
                      type="text"
                      name="expMonth"
                      maxlength="2"
                      oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');"
                    />
                  </div>
                </div>
              </div>

              <div class="col-5">
                <div class="form-group">
                  <label>{{ "Expiration Year" | translate }}</label>
                  <div class="input-group input-group-alternative mb-3">
                    <input
                      #expYear
                      ngModel
                      class="form-control"
                      placeholder="YYYY"
                      type="text"
                      name="expYear"
                      maxlength="4"
                      oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');"
                    />
                  </div>
                </div>
              </div>

              <div class="col-3">
                <div class="form-group">
                  <label>CVC</label>
                  <div class="input-group input-group-alternative">
                    <input
                      #cvc
                      ngModel
                      name="cvc"
                      class="form-control"
                      placeholder="CVC"
                      type="text"
                      maxlength="4"
                      oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');"
                    />
                  </div>
                </div>
              </div>

              <div class="col-5">
                <div class="form-group">
                  <label>{{ "Phone_Number2" | translate }}</label>

                  <div class="input-group input-group-alternative">
                    <input
                      #telefono
                      ngModel
                      name="telefono"
                      class="form-control"
                      placeholder="{{ 'Phone_Number2' | translate }}"
                      type="text"
                      maxlength="10"
                      oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');"
                    />
                  </div>
                </div>
              </div>
            </div>
            <br />
            <button class="btn btn-block btn-info buttonls" [disabled]="showLoaderSave" type="submit" style="height: 48px;">
              <div *ngIf="this.showLoaderSave; then thenBlock else elseBlock"></div>
              <ng-template #elseBlock>{{ "Save" | translate }}</ng-template>
              <ng-template #thenBlock>
                <div class="loader"></div>
              </ng-template>
            </button>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>
