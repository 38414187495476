import { Component, OnInit } from '@angular/core'
import { Router } from '@angular/router'
import { PaymentService } from '../services/payment.service'
import { CompanyService } from '../services/company.service'
declare const $: any;
@Component({
  selector: 'app-pay-invoice-history',
  templateUrl: './pay-invoice-history.component.html',
  styleUrls: ['./pay-invoice-history.component.css'],
})
export class PayInvoiceHistoryComponent implements OnInit {
  dataToShow: any
  allData: any
  searchedInvoice: any
  value: any
  thisdata: any
  selectedcompanies: any
  selectedcompany: any
  companies: any
  listcompany: any
  page = 1
  pageSize = 10
  collectionSize = 0
  dataAvailable: boolean
  dataUnavailable: boolean

  constructor(
    private paymentService: PaymentService,
    private router: Router,
    private companyService: CompanyService,
  ) { }

  ngOnInit(): void {
    console.log('Entra')
    this.getCompanies()
    this.InvoiceData()
    this.refreshInvoices()
    let rolUser: any = localStorage.getItem('rol')
    if (!localStorage.getItem('token')) {
      this.router.navigate(['/login'])
    } else if (rolUser == 2) {
      this.router.navigate(['/reports-invoice'])
    }
  }

  refreshInvoices() {
    console.table(this.allData)
    this.dataToShow = this.allData
      .map((inv: any, i: any) => ({
        id: i + 1,
        ...inv,
      }))
      .slice(
        (this.page - 1) * this.pageSize,
        (this.page - 1) * this.pageSize + this.pageSize,
      )

    this.collectionSize = this.allData.length
    console.log(this.collectionSize)
  }

  searchInvoice(formdata: any) {
    console.log('Formdata:')
    console.log(formdata)
    var company = JSON.parse(formdata.company)
    this.paymentService
      .findInvoice(company.companyid, formdata.fromdate, formdata.todate)
      .subscribe({
        next: (res) => {
          this.allData = res
          this.refreshInvoices()
          //console.log(data);
          //  console.log('Welcome dood!')
        },
        error: (error) => {
          //This runs when the "isAuth" middleware returns 400 bad request as a result of session being invalid
          console.log('Error: ')
          console.log(error)
          //return this.router.navigate(['/login'])
        },
      })
  }

  InvoiceData() {
    this.paymentService.paymentHistoryInfo().subscribe({
      //next/error: is the modern way of doing requests
      //if you do the old way ".subscribe((res) => {res})" and try to pass more than one response subscribe will appear depricated.
      next: (data) => {
        this.allData = data

        console.log(data)
        if (!data || data == '' || data == '[]') {
          this.dataAvailable = false
          this.dataUnavailable = true
        } else {
          this.dataToShow = data
          console.log('Inside InvoiceData')
          this.dataUnavailable = false
          this.dataAvailable = true
        }
        this.refreshInvoices()
      },
      error: (error) => {
        console.log('Error: ')
        console.log(error)
        //return this.router.navigate(['/login'])
      },
    })
  }

  setActive(id: any) {
    console.log(id + ' idddd')
    //document.getElementById(id).setAttribute('class', 'active');
  }

  Logout() {
    //This is missing a method to destroy session
    return this.router.navigate(['/login'])
  }

  getUserCompany() {
    this.paymentService.getCompaniesFromUser().subscribe({
      next: (data) => {
        console.log('getuserDataCompanies')
        console.log(data)
        this.thisdata = data
        this.selectedcompanies = this.thisdata.map((userComp: any) => {
          return {
            companyid: userComp.companyid,
            telefono: userComp.phonenumber,
            customernumber: userComp.customernumber,
            name: userComp.Company.companyname,
          }
        })
      },
      error: (error) => {
        console.log(error)
        //this.router.navigate(['/login'])
      },
    })
  }

  getCompanies() {
    this.companyService.getCompanies().subscribe({
      //next/error: is the modern way of doing requests
      //if you do the old way ".subscribe((res) => {res})" and try to pass more than one response subscribe will appear depricated.
      next: (data) => {
        //  console.log('Welcome dood!')
        console.log(data)
        this.companies = data
      },
      error: (error) => {
        //This runs when the "isAuth" middleware returns 400 bad request as a result of session being invalid
        console.log('Error: ')
        console.log(error)
        //return this.router.navigate(['/login'])
      },
    })
  }

  exportAsXLSX() {
    this.paymentService.exportAsExcelFile(this.dataToShow, 'Reporte de pagos realizados');
  }
}
