import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { RegisterService } from '../services/register.service';
import Swal from 'sweetalert2'
import { NgForm } from '@angular/forms';
declare const $: any;

@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.css']
})
export class UsersComponent implements OnInit {

  @ViewChild('First_nameVal') First_nameVal: ElementRef<HTMLInputElement>;
  @ViewChild('Last_nameVal') Last_nameVal: ElementRef<HTMLInputElement>;
  @ViewChild('EmailVal') EmailVal: ElementRef<HTMLInputElement>;
  @ViewChild('PasswordVal') PasswordVal: ElementRef<HTMLInputElement>;
  @ViewChild('confirmpasswordVal') confirmpasswordVal: ElementRef<HTMLInputElement>;
  @ViewChild('registerForm', { read: NgForm }) registerForm: any;

  saveMessage: string = 'Your profile has been saved!'
  saveAction: any = 'X'
  userid: any
  returnedData: any
  useremail: string
  userpassword: string
  show: boolean = false
  showConfirm: boolean = false
  dataAvailable: boolean
  dataUnavailable: boolean
  dataToShow: any
  allData: any
  rolValue: number = 1

  datosTable: any = {
    language: { "url": sessionStorage.getItem('lang') == 'es' ? "//cdn.datatables.net/plug-ins/1.10.15/i18n/Spanish.json" : "//cdn.datatables.net/plug-ins/1.10.15/i18n/English.json" },
  };

  constructor(
    private router: Router,
    private snackBar: MatSnackBar,
    private registerService: RegisterService,
    private iconRegistry: MatIconRegistry,
    sanitizer: DomSanitizer,
  ) {
    iconRegistry.addSvgIcon('eye-open', sanitizer.bypassSecurityTrustResourceUrl('/assets/icons/eye.svg'),)
    iconRegistry.addSvgIcon('eye-closed', sanitizer.bypassSecurityTrustResourceUrl('/assets/icons/eye-off.svg'),)
  }

  ngOnInit(): void {
    let rolUser: any = localStorage.getItem('rol');
    let langVery = sessionStorage.getItem('lang');
    let setting = this.datosTable
   
    this.listUser();

    if (!localStorage.getItem('token')) {
      this.router.navigate(['/login']);
    } else if (rolUser == 1) {
      this.router.navigate(['/payment']);
    }

    setTimeout(function () {
      $(function () {
       $('#myTableUsers').DataTable(setting);
      });
    }, 100);
  }
  roles = 2;
  notifySave() {
    let langVery = sessionStorage.getItem('lang');
    this.snackBar.open(langVery == 'es' ? '¡Tu perfil ha sido guardado!' : 'Your profile has been saved!', this.saveAction, {
      duration: 3000,
      panelClass: ['green-snackbar'],
    })
  }

  alertValidate(messageAlert: string) {
    this.snackBar.open(messageAlert, 'X', {
      duration: 3000,
      panelClass: ['red-snackbar'],
      verticalPosition: 'top', // Allowed values are  'top' | 'bottom'
      horizontalPosition: 'center',
    })
  }

  ShowPassword() {
    this.show = !this.show
  }
  ShowPasswordTwo() {
    this.showConfirm = !this.showConfirm
  }

  listUser() {
    this.registerService.getUsersAdmin().subscribe({
      next: (data) => {
        this.allData = data
        console.log(data)
        if (!data || data == '' || data == '[]') {
          this.dataAvailable = false
          this.dataUnavailable = true
        } else {
          this.dataToShow = data
          this.dataUnavailable = false
          this.dataAvailable = true
        }

      }, error: (error) => {
        console.log('Error: ')
        console.log(error)
      }
    })
  }

  create(registerForm: any) {
    let langVery = sessionStorage.getItem('lang');
    let first_nameVal = this.First_nameVal.nativeElement.value
    let last_nameVal = this.Last_nameVal.nativeElement.value
    let EmailVal = this.EmailVal.nativeElement.value
    let PasswordVal = this.PasswordVal.nativeElement.value
    let confirmpasswordVal = this.confirmpasswordVal.nativeElement.value
    let validSet, emailRegex = /^[-\w.%+]{1,64}@(?:[A-Z0-9-]{1,63}\.){1,125}[A-Z]{2,63}$/i;

     console.log('Rol: ' +registerForm.roles+' Name: '+registerForm.firstname)

    if (emailRegex.test(EmailVal)) { validSet = 1; } else { validSet = 2; }
    if (first_nameVal.trim().length < 3) {
      this.alertValidate(langVery == 'es' ? 'Nombre debe de tener un mínimo de 3 caracteres' : 'Name must have a minimum of 3 characters')
    } else if (last_nameVal == '' || last_nameVal.length < 3) {
      this.alertValidate(langVery == 'es' ? 'Apellido debe de tener un mínimo de 3 caracteres' : 'Last name must have a minimum of 3 characters')
    } else if (EmailVal == '' || validSet == 2) {
      this.alertValidate(langVery == 'es' ? 'Este correo electrónico es inválido' : 'This email is invalid')
    } else if (PasswordVal != confirmpasswordVal) {
      this.alertValidate(langVery == 'es' ? 'Las contraseñas no coinciden' : 'Passwords do not match')
    } else {
      this.registerService.createUser(registerForm).subscribe({
        next: (data) => {
          this.returnedData = data
          if (this.returnedData.error == 1) {
            this.alertValidate(langVery == 'es' ? 'Este correo ya existe, por favor utilizar otro correo' : 'This email already exists, please use another email')
          } else {
            this.notifySave()
            console.log('IN CREATE')
            console.log(data)
            console.log(this.returnedData.email)
            this.useremail = this.returnedData.email
            this.userpassword = this.returnedData.password
            this.registerForm.resetForm()
            this.getUser()
            this.listUser();
          }
        },
        error: (error) => {
          console.log('Error: ')
          console.log(error)
        },
      })
    }
  }

  getUser() {
    this.registerService
      .logAfterRegister(this.useremail, this.userpassword)
      .subscribe({
        next: (res) => {
          console.log('LOG_REGISTER: ')
          console.log(res)
          let token: any = res
          if (token) {
            localStorage.setItem('token', token)
            console.log('RES.DATA:')
            console.log(res)
          } else {
            console.log('You shall not pass!!!')
          }
        },
        error: (error) => {
          console.log('Error: ')
          console.log(error)
        },
      })
  }

}
