import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  constructor(private translate: TranslateService) {
    let l = "es"
    if (sessionStorage.getItem("lang") === null || sessionStorage.getItem("lang") === "") {
      l = localStorage.getItem("lang")
      sessionStorage.setItem('lang', "es")
    }
    else{
      sessionStorage.setItem('lang', "es")
    }
    console.log(l);
    
    this.translate.setDefaultLang(l);
    this.translate.use(l);
    this.translate.addLangs(['en', 'es']);
  }
}
