
export class PaymentReport {
    Amount: number;
    CompanyName: string;
    CustomerID: number;
    CustomerName: string;
    Date: Date;
    InvoiceNumber: string;
    PaymentID: number;
    dias_transcurridos: number;
    nombreCliente: string;
}