import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { PaymentService } from '../services/payment.service';
import { Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import Swal from 'sweetalert2';
import { NgForm } from '@angular/forms';


@Component({
  selector: 'payment-method',
  templateUrl: './payment-method.component.html',
  styleUrls: ['./payment-method.component.css'],
})
export class PaymentMethodComponent implements OnInit {

  @ViewChild('email') inputemail: ElementRef<HTMLInputElement>;
  @ViewChild('cardName') inputcardName: ElementRef<HTMLInputElement>;
  @ViewChild('cardNumber') inputcardNumber: ElementRef<HTMLInputElement>;
  @ViewChild('expMonth') inputcardexpMonth: ElementRef<HTMLInputElement>;
  @ViewChild('cvc') inputcvc: ElementRef<HTMLInputElement>;
  @ViewChild('telefono') inputtelefono: ElementRef<HTMLInputElement>;
  @ViewChild('expYear') inputexpYear: ElementRef<HTMLInputElement>;
  @ViewChild('registerForm', { read: NgForm }) registerForm: any;

  dataToShow: any;
  allData: any;
  value: any;
  page = 1;
  pageSize = 10;
  collectionSize = 0;
  creditCards: any;
  fotoSet: any;
  showLoaderSave = false
  cargandoTarjetas: boolean = false

  constructor(
    private paymentService: PaymentService,
    private router: Router,
    private snackBar: MatSnackBar
  ) { }

  ngOnInit(): void {
    //Runs on first render
    this.getCreditCards();
    let rolUser: any = localStorage.getItem('rol')
    if (!localStorage.getItem('token')) {
      this.router.navigate(['/login'])
    } else if (rolUser == 2) {
      this.router.navigate(['/reports-invoice'])
    }
  }

  alertValidate(messageAlert: string) {
    this.snackBar.open(messageAlert, 'X', {
      duration: 3000,
      panelClass: ['red-snackbar'],
      verticalPosition: 'top', // Allowed values are  'top' | 'bottom'
      horizontalPosition: 'center',
    })
  }


  createCard(formData: any) {
    /*
    5555555555554444
    4242424242424242
    6011111111111117
    378282246310005
    */
    /*let formData = {
      cardName: "Fernando Burgos",
      cardNumber: "5555555555554444",
      cvc: "123",
      email: "fernandohbd10@gmail.com",
      expMonth: "12",
      expYear: "2023",
      telefono: "8298638965"
    }*/
    console.log(formData);
    let langVery = sessionStorage.getItem('lang');

    let UserStripeId = '';
    let checkAnswer = false;

    let validSet, emailRegex = /^[-\w.%+]{1,64}@(?:[A-Z0-9-]{1,63}\.){1,125}[A-Z]{2,63}$/i;
    if (emailRegex.test(formData.email)) { validSet = 1; } else { validSet = 2; }

    if (formData.email == '' || validSet == 2) {
      this.alertValidate(langVery == 'es' ? 'Este correo electrónico es inválido' : 'This email is invalid')

    } else if (formData.cardName == '' || formData.cardName == null || formData.cardName.trim().length < 3) {
      this.alertValidate(langVery == 'es' ? 'Nombre debe de tener un minimo de 3 caracteres' : 'Name must have a minimum of 3 characters')

    } else if (formData.cardNumber == '' || formData.cardNumber == null || formData.cardNumber.trim().length < 15) {
      this.alertValidate(langVery == 'es' ? 'El número de tarjeta es inválido' : 'The card number is invalid')

    } else if (formData.expMonth == '' || formData.expMonth == null || formData.expMonth.trim().length < 2 || formData.expMonth > 12) {
      this.alertValidate(langVery == 'es' ? 'El Mes es inválido' : 'The month is invalid')

    } else if (formData.expYear == '' || formData.expYear == null || formData.expYear.trim().length < 4 || formData.expYear < 2022) {
      this.alertValidate(langVery == 'es' ? 'El año es inválido' : 'The year is invalid')

    } else if (formData.cvc == '' || formData.cvc == null || formData.cvc.trim().length < 3) {
      this.alertValidate(langVery == 'es' ? 'El número de cvc es inválido' : 'The cvc number is invalid')
    } else {
      this.showLoaderSave = true;
      //registerForm.resetForm()
      //Check Stripe Id || Cheque si existe el usuario
      this.paymentService.checkStripeId().subscribe({
        next: (res: any) => {
          checkAnswer = res.ans;
          console.log(checkAnswer);
          UserStripeId = res.stripeid;
          if (checkAnswer) {
            console.log('here');
            //Create card
            this.paymentService.createCard(formData).subscribe({
              next: (res: any) => {
                console.log('card');
                console.log(res);
                if (!res.code) {
                  this.showLoaderSave = false;
                  //Attach Card to User Stripe Id
                  this.paymentService.attachCard(UserStripeId, res.id).subscribe({
                    next: (res) => {
                      console.log(res);
                      this.getCreditCards();
                      this.registerForm.resetForm()
                      Swal.fire({
                        icon: 'success',
                        title: langVery == 'es' ? 'Bien!' : 'Good!',
                        text: langVery == 'es' ? '¡Tarjeta agregada exitosamente!' : 'Card added successfully!',
                      })
                    },
                    error: (error) => {
                      console.log('Error: ');
                      console.log('Entre aqui 1')
                      console.log(error);

                      Swal.fire({
                        icon: 'warning',
                        title: langVery == 'es' ? 'Error!' : 'Error!',
                        text: langVery == 'es' ? 'Tu tarjeta no pudo ser agregada' : 'Your card could not be added',
                      })

                      this.showLoaderSave = false;
                    },
                  });
                } else {
                  this.alertValidate(res.raw.message)
                  this.showLoaderSave = false;
                }
              },
              error: (error) => {
                this.alertValidate(error.error.raw.message)
                console.log(error);
                this.showLoaderSave = false;
                console.log('Entre aqui')
              },
            });
          } else {
            let validation = false;
            let cardId = '';
            //If User Has no Id then Create It and save to user account.
            //Si el usuario no tiene identificación, créelo y guárdelo en la cuenta de usuario.
            this.paymentService.createUserStripe(formData).subscribe({
              next: (res: any) => {
                UserStripeId = res.id;
                validation = true;

                this.paymentService.saveStripeId(UserStripeId).subscribe({
                  next: (res) => {
                    console.log(res);
                  },
                  error: (error) => {
                    console.log('Error: ');
                    console.log(error);
                    this.showLoaderSave = false;
                  },
                });

                //Create card
                this.paymentService.createCard(formData).subscribe({
                  next: (res: any) => {
                    console.log('card');
                    console.log(res);
                    this.showLoaderSave = false;
                    //Attach Card to User Stripe Id
                    this.paymentService.attachCard(UserStripeId, res.id).subscribe({
                      next: (res) => {
                        console.log(res);
                        this.getCreditCards();
                        Swal.fire({
                          icon: 'success',
                          title: langVery == 'es' ? 'Bien!' : 'Good!',
                          text: langVery == 'es' ? '¡Tarjeta agregada exitosamente!' : 'Card added with successfully!',
                        })
                        this.registerForm.resetForm()
                      },
                      error: (error) => {
                        console.log('Error: (attachCard)');
                        console.log(error);
                        this.showLoaderSave = false;
                      },
                    });
                  },
                  error: (error) => {
                    console.log('Error: (createCard)');
                    console.log(error);
                    this.showLoaderSave = false;
                  },
                });
              },
              error: (error: any) => {
                console.log('Error: ');
                console.log(error);
                this.showLoaderSave = false;
              },
            });

            //Attach Card
            console.log('there');
            console.log(UserStripeId);
          }
        },
        error: (error) => {
          console.log('Error: ');
          console.log(error);
          this.showLoaderSave = false;
        },
      });
    }
  }

  deleteCard(id: string, customer: any) {
    let langVery = sessionStorage.getItem('lang');
    console.log('Customer: ' + customer + ' id:' + id)
    let datos = {
      id: id,
      customer: customer
    }
    Swal.fire({
      icon: 'warning',
      title: langVery == 'es' ? 'Seguro que quieres eliminar este método de pago?' : 'Are you sure you want to remove this payment method?',
      showDenyButton: true,
      //showCancelButton: true,
      confirmButtonText: 'Si',
      //denyButtonText: `Don't save`,
    }).then((result) => {
      if (result.isConfirmed) {
        this.paymentService.deleteCard(id, customer).subscribe({
          next: (res: any) => {
            console.log(res);
            this.getCreditCards();
          },
          error: (error) => {
            console.log('Error: ');
            console.log(error);
          },
        });
      }
    })
  }

  getCreditCards() {
    console.log('get cards');
    this.paymentService.checkStripeId().subscribe({
      next: (res: any) => {
        console.log(res);
        if (res.ans) {
          this.paymentService.getCardList(res.stripeid).subscribe({
            next: (res: any) => {
              console.log(res);
              this.creditCards = res.data;
            },
            error: (error) => {
              console.log('Error: ');
              console.log(error);
            },
          });
        }
      },
      error: (error) => {
        console.log('Error: ');
        console.log(error);
      },
    });
  }
}