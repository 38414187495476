<div class="container">

  <div mdbModal #detailModal="mdbModal" class="modal fade top" id="frameModalTop" tabindex="-1" role="dialog"
    aria-labelledby="myModalLabel" aria-hidden="true" style="overflow-y: auto" data-backdrop="false">
    <!--Dialog-->
    <div class="modal-dialog modal-lg" role="document">
      <div class="modal-content">
        <div class="modal-header text-center" style="background-color:#0d6efd;">
          <h4 class="modal-title white-text w-100 font-weight-bold py-2" style="color:white">
            {{"viewDetail"| translate}}
          </h4>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="detailModal.hide();clearTable()">
            <span aria-hidden="true" class="white-text">&times;</span>
          </button>
        </div>
        <div class="modal-body" style="padding: 20px">
          <table class="table mytableDos">
            <thead>
              <tr>
                <th>{{ 'PaymentNumber' | translate }}</th>
                <th>{{ 'Invoice_Number' | translate }}</th>
                <th> {{ 'Amount' | translate }}</th>
                <th>{{ 'NoAccount' | translate }}</th>
                <th>{{ 'Company_Name2' | translate }}</th>
                <th>{{ 'CustomerName' | translate }}</th>
                <th>{{ 'Invoice_Date' | translate }}</th>
                <th>{{ 'DayInvoice' | translate }}</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let item of listDetailInvoice">
                <td>{{item.paymentid}}</td>
                <td>{{item.invoicenumber}}</td>
                <td>$ {{item.amounttopay |  number : '1.2-2'}} </td>
                <td>{{item.customernumber}}</td>
                <td>{{item.companyname}}</td>
                <td>{{item.customername}}</td>
                <td>{{item.date | date: "yyyy-MM-dd":"UTC"}}</td>
                <td>{{item.dias_transcurridos}}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>

  <div class="row justify-content-center">
    <h5 class="h5 text-left">{{ 'Reporte-payment' | translate }}</h5>
    <!--<form #filterForm="ngForm" class="row form" autocomplete="off"
      style="margin-top: 10px;margin-bottom: 15px;width: 100%;">
      <div class="col-md-3">
        <label for="cliente">{{ 'filter' | translate }}</label>
        <select #filterBy (change)="filterByOnChange(filterBy.value)" name="company" id="filterBy" class="form-select" aria-label="Selecciona">
          <option selected>Selecciona</option>
          <option value="CustomerName">{{ 'CustomerName' | translate }}</option>
          <option value="CustomerID">{{ 'NoAccount' | translate }}</option>
          <option value="InvoiceNumber">{{ 'Invoice_Number' | translate }}</option>
          <option value="Amount">{{ 'paidNumber' | translate }}</option>
        </select>
      </div>
      <div class="col-md-3">
        <label for="cliente">{{ 'List' | translate }}</label>
        <select #company id="company" [(ngModel)]="listcompany" name="company" class="form-select"
          aria-label="Selecciona">
          <option selected value="null">{{ 'Select' | translate }}</option>
          <option *ngFor="let comp of list" [ngValue]="comp">
            {{ comp }}
          </option>
        </select>
      </div>
      <div class="col-md-2">
        <label for="cliente">{{ 'date' | translate }}</label>
        <input #fromdate name="fromdate"  id="from" class="form-control" ngModel type="date" />
      </div>
      <div class="col-md-2">
        <button (click)="searchInfoOther()" class="btn btn-primary" style="margin-top: 25px;">{{ 'Search' | translate }}</button>
      </div>
      <div class="col-md-2">
        <button (click)="refreshData()" class="btn btn-warning" style="margin-top: 25px;">{{ 'RefreshTable' | translate }}</button>
      </div>
    </form>-->
    <div class="col justify-content-center">
      <div class="card">
        <div class="card-header">
          <!--<input type="date" id="dateadded" timezone="es" class="dateadded form-control" >--->
        </div>
        <div class="card-body text-center">
          <div class="table-responsive">
            <table class="table" id="mytable">
              <thead>
                <tr>
                  <th>{{ 'PaymentNumber' | translate }}</th>
                  <th>{{ 'viewDetail' | translate }}</th>
                  <!--<th>{{ 'Company_Name2' | translate }}</th>-->
                  <th>{{ 'CustomerName' | translate }}</th>
                  <!--<th>{{ 'NoAccount' | translate }}</th>-->
                  <th>{{ 'datePayment' | translate }}</th>
                  <!--<th>{{ 'Invoice_Number' | translate }}</th>-->
                  <th>{{ 'PaymentValue' | translate }}</th>
                  <!--<th>{{ 'ID' | translate }}</th>-->
                  <!--<th>{{ 'DayInvoice' | translate }}</th>-->
                </tr>
              </thead>
              <tbody>
                <th *ngIf="paymentReportModel.length == 0" colspan="7">{{ 'NoEnvoicesToShow' | translate }}</th>
                <tr *ngFor="let info of paymentReportModel">
                  <td>{{ info.PaymentID }}</td>
                  <td><button class="btn btn-primary" (click)="getDetailPayment(info.PaymentID);clearTable();detailModal.show()" mdbWavesEffect>ver detalle</button></td>
                  <!--<td>{{info.CompanyName}}</td>-->
                  <!--<td>{{info.CustomerName}}</td>-->
                  <td>{{ info.nombreCliente}}</td>
                  <!-- <td>{{info.CustomerID}}</td> -->
                  <td>{{info.Date | date: "yyyy-MM-dd":"UTC"}}</td>
                  <!--<td>{{info.InvoiceNumber}}</td>-->
                  <td>{{info.Amount | number : '1.2-2'}}</td>
                  <!--<td>{{ info.PaymentID }}</td>-->
                  <!--<td>{{ info.dias_transcurridos }}</td>-->
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>