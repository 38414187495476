<div class="container">
  <div class="row justify-content-center">
    <h5 class="h5 text-left">{{ 'Payments_History' | translate }}</h5>
    <!--<div>
      <button (click)="exportAsXLSX()" class="btn btn-primary">{{ 'ReportePay' | translate }}</button>
    </div>-->
    
    <br />
    <div width="100%" style="display:none">
      <form
        #registerForm="ngForm"
        (ngSubmit)="searchInvoice(registerForm.value)"
        class="form"
        autocomplete="off"
      >
        <select
          #company
          id="company"
          [(ngModel)]="listcompany"
          name="company"
          class="classic"
        >
          <option *ngFor="let comp of companies" value="{{ comp | json }}">
            {{ comp.companyname }}
          </option>
        </select>
        <label class="dates-from-to">{{ 'Payment from' | translate }}:</label>
        <input
          #fromdate
          name="fromdate"
          ngModel
          type="date"
          style="margin-left: 2%;"
        />
        <label>{{ 'Payment to' | translate }}:</label>
        <input
          #todate
          name="todate"
          ngModel
          type="date"
          style="margin-left: 2%;"
        />
        <button
          style="margin-left: 2%;"
          type="submit"
          class="btn btn-lg btn-primary"
        >
          {{ 'Search' | translate }}
        </button>
      </form>
    </div>
    <br />
    <div class="col justify-content-center">
      <div class="card">
        <div class="card-header">
          <p class="h6">{{ 'My_Payments' | translate }}</p>
        </div>
        <div class="card-body text-center">
          <div class="table-responsive">
            <table class="table" id="mytable">
              <thead>
                <tr>
                  <th>{{ 'paidNumber' | translate }}</th>
                  <th>{{ 'Invoice_Date' | translate }}</th>
                  <th>{{ 'StripeId' | translate }}</th>
                  <th>{{ 'Payment_Date' | translate }}</th>
                  <th>{{ 'Invoice_Amount' | translate }}</th>
                  <!--<th>{{ 'Invoice_Amount' | translate }}</th>--->
                  <th>{{ 'status' | translate }}</th>
                </tr>
              </thead>
              <tbody *ngFor="let item of dataToShow">
                <tr *ngIf="dataAvailable">
                  <td>
                    {{ item.paymentid }}
                  </td>
                  <td>{{ item.dateid | date }}</td>
                  <td>{{ item.stripetransactionid }}</td>
                  <td>{{ item.dateupdated | date }}</td>
                  <td>${{ item.amount | number : '1.2-2' }}</td>
                 <!-- <td>
                    <a *ngIf="item.invoiceLink != null" href="{{ item.invoiceLink }}" target="_blank">{{ 'Views_Invoice' | translate }}</a>
                  </td> -->
                  <td style="color:green">{{ 'pagoTweo' | translate }}</td>
                  <!--<td>${{ item.amount }}</td>-->
                </tr>
              </tbody>
              <tr *ngIf="dataUnavailable">
                <th colspan="6">{{ 'NoEnvoicesToShow' | translate }}</th>
              </tr>
            </table>
            <div
              class="d-flex justify-content-between p-2"
              style="align-items: center; justify-content: center;"
            >
              <ngb-pagination
                [collectionSize]="collectionSize"
                [(page)]="page"
                [pageSize]="pageSize"
                (pageChange)="refreshInvoices()"
                [maxSize]="15"
              ></ngb-pagination>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
