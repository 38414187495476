import {
  HttpClient,
  HttpErrorResponse,
  HttpHeaders,
  HttpParams,
} from '@angular/common/http'
import { Injectable } from '@angular/core'
import { Observable, of, throwError, tap, Subject } from 'rxjs'
import { catchError, retry, map } from 'rxjs/operators'
import { Router } from '@angular/router'
import { Hello } from '../hello'
import { response } from 'express'
import { IP } from '../IP'

@Injectable({
  providedIn: 'root',
})
export class LoginService {
  //It seems that you must name the "global variable" here
  private apiUrl = IP
  loggedIn: boolean

  constructor(private http: HttpClient) {
    //and then declare the type of said variable here
  }

  getTest() {
    let value
    value = this.http.get(this.apiUrl + '/test/hello', {
      responseType: 'text',
    })
    return value
  }

  // getUserID() {
  //   let value
  //   value = this.http.get(this.apiUrl + '/userlog/getUserID', {
  //     headers: {
  //       token: localStorage.getItem('token'),
  //     },
  //   })
  // }

  userLog(email: string, password: string) {
    let userData = { useremail: email, userpassword: password }

    console.log('In userLog function')
    //console.log(userData)
    return this.http.post(this.apiUrl + '/userlog/authenticate', userData, {
      //headers: this.httpOptions,
      withCredentials: true,
    })
    //.pipe(catchError(this.handleError))

    //.pipe()
  }

  handleError(error: HttpErrorResponse) {
    return throwError(error.message)
  }

  httpOptions: HttpHeaders = new HttpHeaders({
    'Content-Type': 'application/json',
  })
}
