export class Model {
  isChecked: boolean;
  disableInput: boolean;
  paymentValue: number;
  inputValuesArray: Array<number>;
  pendingAmount: any;
  
}

export class Item {
  constructor(
    public id: number,
    public invoiceid: number,
    public invoicenumber: string,
    public amount: number,
    //public date: number,
    public checked: boolean,
  ) {}
}
