import {
  HttpClient,
  HttpErrorResponse,
  HttpHeaders,
  HttpParams,
} from '@angular/common/http'
import { Injectable } from '@angular/core'
import { Observable, of, throwError, tap, Subject } from 'rxjs'
import { catchError, retry, map } from 'rxjs/operators'
import { Router } from '@angular/router'

@Injectable({
  providedIn: 'root',
})
export class AdminLayoutService {
  //It seems that you must name the "global variable" here
  private apiUrl = 'https://micuenta.conwastepr.com:8081'

  constructor(private http: HttpClient) {
    //and then declare the type of said variable here
  }

  destroySession() {
    let destroy
    console.log('In destroySession function')
    destroy = this.http.get(
      this.apiUrl + '/userlog/sessionDestruction',

      { withCredentials: true, responseType: 'text' },
    )
    console.log(destroy)
    return destroy
    //.pipe()
  }

  getUserInfo() {
    let userInfo = this.http.get(
      this.apiUrl + '/userlog/getUserInfo',

      { withCredentials: true, responseType: 'text' },
    )

    return userInfo
  }

  getUserData() {
    let value
    value = this.http.get(
      this.apiUrl + '/userlog/getUserData/' + localStorage.getItem('item'),
      {},
    )

    return value
  }

  httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
  }
}
