<div class="container">
  <div class="row justify-content-center">
    <h5 class="h5 text-left">{{ 'stripe-report' | translate }}</h5>
    <div class="col justify-content-center">
      <div class="card">
        <div class="card-header">
        </div>
        <div class="card-body text-center">
          <div class="table-responsive">
            <table class="table mytable display nowrap " id="mytableSpayment">
              <thead>
                <tr>
                  <th>ID</th>
                  <th>Monto</th>
                  <th>{{ 'Invoice_Date' | translate }}</th>
                  <th>Transfer ID</th>
                  <th>Cliente ID</th>
                  <th>Tarjeta</th>
                  <th>Accion</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let info of stripeData">
                  <td>{{info.id}}</td>
                  <td>{{info.amount / 100 | currency }}</td>
                  <td>{{ info.created * 1000 | date}}</td>
                  <td>{{info.payment_intent }}</td>
                  <td>{{info.customer }}</td>
                  <td style="text-transform: uppercase;">{{info.payment_method_details.card.network }} **** {{info.payment_method_details.card.last4 }}</td>
                  <td>
                    <a href="{{info.receipt_url}}" target="_blank">Link</a>
                  </td>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>