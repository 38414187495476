import { Component, ElementRef, OnInit, ViewChild } from '@angular/core'
import { Router, ActivatedRoute } from '@angular/router'
import { CompanyService } from '../services/company.service'
import { RegisterCompanyService } from '../services/registercompany.service'
import { NotifierService } from 'angular-notifier'
import { MatSnackBar } from '@angular/material/snack-bar'

@Component({
  selector: 'app-register',
  templateUrl: './company-register-login.component.html',
  styleUrls: ['./company-register-login.component.css'],
})
export class CompanyRegisterLoginComponent implements OnInit {
  companies: any
  selectedCompanies: any
  userid: any
  defaultOption: any
  newCompanies: any
  availableCompanies: any
  enableEdit: boolean
  enableSave: boolean
  thisdata: any
  compname: any
  phonenumber: any  = ''
  numcliente: any
  userCompID: any
  listcompany: any
  companySelection: any
  compUpdated: any
  companyId: any
  companyPhone: any
  NoComp: boolean
  showLabel: boolean
  idForUpdate: any
  returnedData: any
  idForCreate: any
  siteNumber: any = ''
  siteNumberPhone: any = ''
  checkSite: boolean = false

  checkPhone: boolean = false
  //private readonly notifier: NotifierService;
  saveMessage: string = 'Your companies have been saved!'
  saveAction: any = 'X'
  showSiteNumber: boolean = false
  showPhoneNumber: boolean = false

  @ViewChild('Telefono') Telefono: ElementRef<HTMLInputElement>;
  @ViewChild('NumCliente') NumCliente: ElementRef<HTMLInputElement>;

  constructor(
    private companyService: CompanyService,
    private router: Router,
    private route: ActivatedRoute,
    private registerCompanyService: RegisterCompanyService,
    private readonly notifier: NotifierService,
    private snackBar: MatSnackBar,
  ) { }

  ngOnInit(): void {
    this.showLabel = false
    this.route.paramMap.subscribe((params) => {
    this.userid = params.get('id')
    this.returnedData = []
    })
    this.enableSave = true
    this.getCompanies()
    console.log(this.companies)
    this.selectedCompanies = []
    this.companySelection = []
    console.log(this.userid)
  }

  notifySave() {
    let langVery = sessionStorage.getItem('lang');
    //Notification save
    this.snackBar.open(langVery == 'es' ? '¡Tus cuentas se han guardado!' : 'Your account have been saved!', this.saveAction, {
      //duration that the notification will be on screen
      duration: 3000,
      //class for notification so that you can style it in the global css (ps. you should use the global css file or else styling won't work on it)
      panelClass: ['green-snackbar'],
    })
  }

  alertValidate(messageAlert: string) {
    this.snackBar.open(messageAlert, 'X', {
      duration: 3000,
      panelClass: ['red-snackbar'],
      verticalPosition: 'top', // Allowed values are  'top' | 'bottom'
      horizontalPosition: 'center',
    })
  }

  saveUserCompanies() {
    this.registerCompanyService.createUserCompanies(this.selectedCompanies).subscribe({
        next: (data) => {
          console.log(data)
          console.log('estoy aqui')
          this.notifySave()
          localStorage.removeItem('token')
          this.router.navigate(['/login'])
        },
        error: (error) => {
          this.router.navigate(['/login'])
        },
      })
  }

  onClickEdit(comp: any) {
    this.enableEdit = true
    this.enableSave = false

    //Filters the list of companies so that only the company name of the selected user company appears as an option
    let filteredComp = this.companies.filter((company: any) => {
      return company.companyid == comp.companyid
    })
    console.log(filteredComp)

    //replaces the available companies with the filtered ones
    this.availableCompanies = filteredComp

    this.companySelection = comp.companyid
    this.userCompID = comp.usercompid
    this.phonenumber = comp.telefono
    this.numcliente = comp.customernumber
    this.listcompany = comp
  }

  async addCompany(company: any, phone: any = '', customernum: any) {
    let langVery = sessionStorage.getItem('lang');
    this.checkSite = false

    if (company == '') {
      this.alertValidate(langVery == 'es' ? 'Por favor seleccionar una compañía' : 'Please select a company')
    } else if (customernum == '' || customernum.length < 3) {
      this.alertValidate(langVery == 'es' ? 'El campo numeró cliente no puede ir vacío' : 'The customer number field cannot be empty')
    } else {

      let datos = { company: company, customernum: customernum }
      this.returnedData = []
      this.registerCompanyService.validHaveCompanyInfo(datos).subscribe({
        next: (data) => {
          // console.log(data);
          this.returnedData = data
          if(this.returnedData.error == 1){
            this.alertValidate(langVery == 'es' ? 'Este número cliente no existe' : 'The customer number does not exist')
          } else {
            if(this.returnedData[0].customerbillTo != 3 && this.phonenumber == '' ) {
              this.alertValidate(langVery == 'es' ? 'Debes de agregar un número de teléfono el cual debe tener 10 caracteres' 
              : 'You must add a phone number which must have 10 characters')
              this.showPhoneNumber = true
              return;
            // Cuando lleva SiteNumber
            } else if(this.returnedData[0].customerbillTo == 3 && this.phonenumber == '' ){
              this.alertValidate(langVery == 'es' ? 'Debes de agregar un número de teléfono el cual debe tener 10 caracteres'
               : 'You must add a phone number which must have 10 characters')
              this.showPhoneNumber = true
              this.showSiteNumber = true
              return;
            } else if(this.returnedData[0].customerbillTo == 3 && this.siteNumber == '' ){
              this.alertValidate(langVery == 'es' ? 'Debes de agregar un número sitio válido'
               : 'You must add a valid site number')
              this.showPhoneNumber = true
              this.showSiteNumber = true
              return;
            } else if(this.returnedData[0].customerbillTo == 3  && this.phonenumber != ''){
              // console.log('customerbillTo == 3')
              this.registerCompanyService.validSiteNumber(datos, this.phonenumber, this.siteNumber).subscribe(data => {
                // console.log(data)
                if((<any>data).data.length == 0){
                  this.alertValidate(langVery == 'es' ? 'Debe agregar un número sitio y teléfono de número sitio válido' :
                   'You must add a valid site number and phone number site')
                  this.showPhoneNumber = true
                  this.showSiteNumber = true
                  return;
                } else {
                  this.checkSite = false
                  this.addTemp(company, this.phonenumber, customernum)
                }
              })
            } else if(this.returnedData[0].customerbillTo != 3 && this.phonenumber != ''){
              // console.log('customerbillTo != 3')
              this.registerCompanyService.validPhoneNumber(datos, this.phonenumber).subscribe(response => {
                // console.log(response)
                if((<any>response).data.length == 0){
                  this.alertValidate(langVery == 'es' ? 'Debe agregar un número teléfono  válido' :
                   'You must add a valid  phone number')
                  this.showPhoneNumber = true
                  return;
                } else {
                  this.checkSite = false
                  this.addTemp(company, this.phonenumber, customernum)
                }
              })
            } else {
               this.showPhoneNumber = false
              this.addTemp(company, phone, customernum)
            }
          }
        },
        error: (error) => {
          console.log('Error: ')
          console.log(error)
        },
      })
    }
  }

  siteNumberCheck(datos: any) {
    let langVery = sessionStorage.getItem('lang');
    this.registerCompanyService.validSiteNumber(datos, this.phonenumber, this.siteNumber).subscribe(data => {
      console.log(data)
      if((<any>data).data.length == 0){
        this.checkSite = true
      } else {
        this.checkSite = false
      }
    })
  }

  addTemp(company: any, phone: any, customernum: any){
    let compItem = this.companies.find((item: any) => {
      // console.log(item.companyid == company)
      return item.companyid == company
    })
    // console.log(compItem)

    var newCompCustomer = {
      companyid: company,
      telefono: phone,
      customernumber: customernum,
      name: compItem.companyname,
      site: this.siteNumber
    }

    this.selectedCompanies.push(newCompCustomer)
    //
    this.showSiteNumber = false
    this.showPhoneNumber = false
    this.siteNumber = ''
    this.siteNumberPhone = ''
    this.enableEdit = false
    this.enableSave = true
    this.phonenumber = ''
    this.numcliente = ''

    // console.log(this.availableCompanies)
    const index = this.availableCompanies.findIndex(
      (comp: any) => comp.companyid == company,
    )

    this.availableCompanies = this.availableCompanies.filter((comp: any) => {
      return  comp.customernumber != customernum
    })

    // console.log('This Splice: ')
    // console.log(this.availableCompanies)

    if (this.availableCompanies.length == 0) {
      this.NoComp = true
    } else {
      this.NoComp = false
      this.showLabel = true;
    }
  }

  removeCompany(company: any) {
    //Removes the selected user company from the list
    // console.log('Hello from removeCompany')
    // this.selectedCompanies = this.selectedCompanies.filter(
    //   (comp: any) => comp !== company,
    // )
    console.log(company)
    console.log('--removeCompany--')
    // console.log(company)
    console.log(this.companies)

    var compID: number = +company.companyid
    console.log(compID)

    // Uses the id of the company that is going to be removed and filters
    // the list to get the company
    let removedComp = this.companies.filter((comp: any) => {
      console.log(company)
      console.log('Company filter: ')
      console.log(comp)
      console.log(comp.companyid)
      console.log(company.companyid)

      console.log('Checking company ids: ')
      console.log(comp.companyid == company.companyid)
      return comp.companyid == company.companyid
    })
    console.log('removedCOmp:')
    console.log(removedComp)

    //With the item obtained from removedComp we then find the id in specific to use for the select
    let removedCompID = removedComp.find((comp: any) => {
      console.log('Removed Comp ID: ')
      console.log(comp.companyid)
      return comp.companyid
    })

    console.log('Available Companies: ')
    var checkIdInList = this.companies.find((id: any) => {
      console.log(id.companyid === removedCompID)
      return id.companyid == removedCompID
    })
    console.log(checkIdInList)
    //Here we push the id of the removed company to the select so that it can be used again
    if (!checkIdInList) {
      this.availableCompanies.push(removedCompID)
      console.log(this.availableCompanies)
    }

    //Heres the function to actually remove the user company from the list in the front end
    //make sure to leave this for last or else the push method will give an empty array
    this.selectedCompanies = this.selectedCompanies.filter(
      (comp: any) => comp !== company,
    )
    console.log('Removed Company: ')
    console.log(company)
    console.log(this.companies)

    //Set the "No companies" option when the array has no values
    if (this.availableCompanies.length == 0) {
      this.NoComp = true
    } else {
      this.NoComp = false
    }
  }

  cancelEdit() {
    this.enableEdit = false
    //Enables the save button at the bottom of the container
    this.enableSave = true
    //resets the inputs
    this.phonenumber = ''
    this.numcliente = ''
    //This reapplies all the companies to availableCompanies
    this.availableCompanies = this.companies
  }

  saveEditCompany(company: any, phone: any, customernum: any) {
    console.log('saveEditCompany ran')

    console.log(this.userCompID)
    //updateItem finds the user company that was selected for editing
    let updateItem = this.selectedCompanies.find(
      (item: any) => item === this.listcompany,
    )

    //compItem finds the company name using company id from the list of companies
    let compItem = this.companies.find((item: any) => {
      return item.companyid == company
    })

    //Creates a new user company to overwrite the old one
    var newEditComp = {
      usercompid: this.userCompID,
      companyid: company,
      telefono: phone,
      customernumber: customernum,
      name: compItem.companyname,
    }

    //index gets the index id of the selected user company
    let index = this.selectedCompanies.indexOf(updateItem)
    //This then uses the index obtained to find where the old user company was in the list and replace it with the new one
    this.selectedCompanies[index] = newEditComp

    console.log(this.selectedCompanies[1].name)
    console.log('Blah')

    //Disables edit
    this.enableEdit = false
    //Enables the save button at the bottom of the container
    this.enableSave = true
    //resets the inputs
    this.phonenumber = ''
    this.numcliente = ''
    //This reapplies all the companies to availableCompanies
    this.availableCompanies = this.companies
  }

  saveFinalEdit() {
    console.log('Final Edit:')
    console.log(this.selectedCompanies)

    let userCompID = this.selectedCompanies.usercompid

    let compWithUserCompID = this.selectedCompanies.filter((company: any) => {
      return company.usercompid != null
    })
    console.log(compWithUserCompID)

    let compNullUserCompID = this.selectedCompanies.filter((company: any) => {
      return company.usercompid == null
    })
    console.log(compNullUserCompID)

    this.registerCompanyService
      .editCreateOrDeleteUserCompany(compWithUserCompID, compNullUserCompID)
      .subscribe({
        //next/error: is the modern way of doing requests
        //if you do the old way ".subscribe((res) => {res})" and try to pass more than one response subscribe will appear depricated.
        next: (data) => {
          this.router.navigate(['/login'])
          console.log('estoy aqui')
        },
        error: (error) => {
          //This runs when the "isAuth" middleware returns 400 bad request as a result of session being invalid
          console.log(
            'Error in saveUserCompanies(company-register.component): ',
          )
          console.log(error)
          this.router.navigate(['/login'])
        },
      })
  }

  getCompanies() {
    this.companyService.getCompanies().subscribe({
      //next/error: is the modern way of doing requests
      //if you do the old way ".subscribe((res) => {res})" and try to pass more than one response subscribe will appear depricated.
      next: (data) => {
        console.log('getCompanies ran login:')
        console.log(data)
        this.companies = data
        this.availableCompanies = data
      },
      error: (error) => {
        //This runs when the "isAuth" middleware returns 400 bad request as a result of session being invalid
        console.log('Error: ')
        console.log(error)
      },
    })
  }
}
