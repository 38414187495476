import { Component, ElementRef, OnInit, ViewChild } from '@angular/core'
import { PaymentService } from '../services/payment.service'
import { Router } from '@angular/router'
import { CompanyService } from '../services/company.service'
import { Item, Model } from '../model'
import { DomSanitizer } from '@angular/platform-browser'
import { MatIconRegistry } from '@angular/material/icon'
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap'
import Swal from 'sweetalert2'
import { InvoiceCart } from "./dto/invoiceCart.dto"
import { Invoice } from './dto/invoice.dto'
import { SearchFilterPipe } from '../pipe/searchTable'
declare const $: any;

@Component({
  selector: 'app-payment',
  templateUrl: './payment.component.html',
  styleUrls: ['./payment.component.css'],
})
export class PaymentComponent implements OnInit {
  public model: Model
  private testArray: Array<Item> = []
  dataToShow: Array<Invoice> = [];
  allData: any
  isChecked: boolean
  dataAvailable: boolean
  dataUnavailable: boolean
  totalAmount = 0
  searchedInvoice: any
  value: any
  thisdata: any
  selectedcompanies: any
  selectedcompany: any
  companies: any
  listcompany: any
  inputValue: number
  valueString: string
  payAmountArray: any
  totalSumInvoice: any = 0;
  totalSumInvoiceToCart: any = 0
  totalSumInvoiceTwo: Array<any> = [];
  page = 1
  pageSize = 5 //make sure this is set to 10 when done testing
  collectionSize = 0
  checker: boolean = false
  closeResult: string = ''
  list_length: boolean = false
  isDisabled: boolean = true;
  userId: any = 0;
  conteoCarrito: boolean = false;
  cantidadCarrito: number = 0
  searchText: string
  token: any
  $: any;

  @ViewChild('PayMount') PayMount: ElementRef<HTMLInputElement>;

  constructor(
    private paymentService: PaymentService,
    private router: Router,
    private companyService: CompanyService,
    private iconRegistry: MatIconRegistry,
    private modalService: NgbModal,
    sanitizer: DomSanitizer,
    //private searchFilterPipe: SearchFilterPipe
  ) {
    iconRegistry.addSvgIcon(
      'dollar-sign',
      sanitizer.bypassSecurityTrustResourceUrl(
        '/assets/icons/dollar-sign-svgrepo-com.svg',
      ),
    )

    this.model = new Model()
    this.model.paymentValue = 0
  }

  ngOnInit(): void {
    //Runs on first render
    this.getUserIdFromToken()
    this.getCompanies()
    this.InvoiceData()
    this.cartCount()
    this.allData = []
    this.payAmountArray = []
    let rolUser: any = localStorage.getItem('rol')

    if (!localStorage.getItem('token')) {
      this.router.navigate(['/login'])
    } else if(rolUser == 2) {
      this.router.navigate(['/reports-invoice'])
    }
  }

  open(content: any) {
    this.modalService
      .open(content, { ariaLabelledBy: 'modal-basic-title' })
      .result.then(
        (result) => {
          this.closeResult = `Closed with: ${result}`
        },
        (reason) => {
          this.closeResult = `Dismissed ${this.getDismissReason(reason)}`
        },
      )
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC'
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop'
    } else {
      return `with: ${reason}`
    }
  }

  refreshInvoices() {
    this.cartCount()
    console.log(this.payAmountArray)

    const test: string = 'excuse me sir, am i ugly?'

    this.dataToShow = this.allData
      .map((inv: any, i: any, check: any) => ({
        id: i + 1,
        ...inv,
      }))
      .slice(
        (this.page - 1) * this.pageSize,
        (this.page - 1) * this.pageSize + this.pageSize,
      )

    this.collectionSize = this.allData.length

    console.log('DatatoShow: ')
    console.log(this.dataAvailable)
    console.log(this.dataUnavailable)
    console.log(this.dataToShow)
  }

  cartCount() {
    this.paymentService.cartCount().subscribe({
      next: (data: any) => {
        console.log('Cantidad de producto en el carrito: ' + data.count+' = '+data.total.amountTotal);
        if (data.count > 0) {
          this.conteoCarrito = true
          this.cantidadCarrito = data.count
          this.totalSumInvoiceToCart = data.total.amountTotal
        } else {
          this.conteoCarrito = false
          this.cantidadCarrito = 0
          this.totalSumInvoiceToCart = 0
        }
      }, error: (error) => {
        console.log('Error:')
        console.log(error)
      }
    })
  }

  searchInvoice(formdata: any) {
    console.log('Formdata:')
    console.log(formdata)
    let companyId = formdata.company?.companyid ? formdata.company.companyid : 0;
    this.paymentService
      .searchInvoice(companyId, formdata.fromdate, formdata.todate)
      .subscribe({
        next: (res: any) => {
          //debugger
          this.dataUnavailable = false;
          this.dataAvailable = true;
          this.allData = res
          this.dataToShow = res;
          console.log(this.dataToShow);
          this.refreshInvoices()
          this.cartCount()
        },
        error: (error) => {
          this.dataToShow = [];
          this.dataUnavailable = true;
          this.dataAvailable = false;
          console.log('Error: ')
          console.log(error)
        },
      })
  }

  getUserIdFromToken() {
    this.paymentService.getUserID().subscribe({
      next: (res) => {
        console.log('UserID:' + res)
        this.userId = res;
      },
      error: (error) => {
        console.log('Error: ')
        console.log(error)
      },
    })
  }

  inputAmountToPay(e: any, item: any) {
    this.valueString = e.target.value || 0
    this.inputValue = parseFloat(this.valueString)

    //This limits what can be input in "amount to pay"
    //if the value is below 0 it sets the value to 0
    if (item.paymentValue < 0 || item.paymentValue == '') item.paymentValue = 0
    //if the value is above the invoice amount it sets the value to the invoice amount
    if (item.paymentValue > item.amount) {
      //for front end

      item.paymentValue = item.amount
      //for adding to array
      this.inputValue = item.amount
    }

    if (item.paymentValue > 0) {
      if (this.payAmountArray.length == 0) {
        this.payAmountArray.push({
          invoice_id: item.invoiceid,
          invoice_amount: item.amount,
          amount_to_pay: item.paymentValue,
          invoice_num: item.invoicenumber,
          //is_checked: item.isChecked,
        })

        this.list_length = false

        this.paymentService.amount_to_pay = item.paymentValue
        //this.paymentService.disable_input = item.disableInput

        console.log('The allData:')
        console.log(this.allData)

        var index = this.allData.findIndex((i: any) => {
          console.log(i.invoiceid)
          console.log('******')
          console.log(item.invoiceid)
          return i.invoiceid === item.invoiceid
        })
        console.log(index)
        console.log('******')

        console.log(this.allData[index])

        //search for the specific element that is checked in allData
        //before giving it this value otherwise all rows will be true
        this.allData[index].paymentValue = this.paymentService.amount_to_pay
        //this.allData[index].disableInput = this.paymentService.disable_input

        console.log(this.allData[index])

        console.log('***ARRAY OF VALUES***')
        console.log(this.payAmountArray)

        //Gives the total sum of all the items in the array
        this.totalSumInvoice = this.payAmountArray.reduce(
          (sum: any, current: any) => {
            return sum + current.amount_to_pay
          },
          0,
        )
        console.log('Sum of values: ' + this.totalSumInvoice)
      }
      this.list_length = true
      if (!this.payAmountArray.some((id: any) => id.invoice_id === item.invoiceid)) {
        console.log('Summer')
        this.payAmountArray.push({
          invoice_id: item.invoiceid,
          invoice_amount: item.amount,
          amount_to_pay: item.paymentValue,
          invoice_num: item.invoicenumber,
          // is_checked: item.isChecked,
        })

        this.paymentService.amount_to_pay = item.paymentValue
        //this.paymentService.disable_input = item.disableInput

        console.log('The allData:')
        console.log(this.allData)

        var index = this.allData.findIndex((i: any) => {
          console.log(i.invoiceid)
          console.log('******')
          console.log(item.invoiceid)
          return i.invoiceid === item.invoiceid
        })
        console.log(index)
        console.log('******')

        console.log(this.allData[index])

        //search for the specific element that is checked in allData
        //before giving it this value otherwise all rows will be true
        this.allData[index].paymentValue = this.paymentService.amount_to_pay
        //this.allData[index].disableInput = this.paymentService.disable_input

        console.log(this.allData[index])

        //Gives the total sum of all the items in the array
        this.totalSumInvoice = this.payAmountArray.reduce(
          (sum: any, current: any) => {
            return sum + current.amount_to_pay
          },
          0,
        )
       // console.log('Sum of values: ' + this.totalSumInvoice)
      }
    }
    // if (this.payAmountArray.length != 0) {
    //   if (item.paymentValue == 0 || item.paymentValue == '') {
    //     const index = this.payAmountArray.findIndex(
    //       (inv: any) => inv.invoice_id == item.invoiceid,
    //     )
    //     if (index > -1) {
    //       this.payAmountArray.splice(index, 1) //2nd parameter means to remove one item only in this case
    //     }
    //     console.log('This Splice: ')
    //     console.log(this.payAmountArray)
    //   }
    // }
  }

  removeInvoice(invoice: any) {
    this.payAmountArray = this.payAmountArray.filter((inv: any) => {
      console.log(inv.invoice_num == invoice.invoice_num)
      console.log(invoice.invoice_num)
      return inv !== invoice
    })

    console.log('Invoice:')

    const index = this.payAmountArray.findIndex((inv: any) => {
      console.log('Remove!')
      console.log(inv.invoice_num)
      //console.log(filterInvoice)
      console.log(inv.invoice_num)
      inv.invoice_num
    })
    if (index > -1) {
      this.payAmountArray.splice(index, 1) //2nd parameter means to remove one item only in this case
    }
    console.log('This Splice: ')
    console.log(this.payAmountArray)

    //Gives the total sum of all the items in the array
    this.totalSumInvoice = this.payAmountArray.reduce(
      (sum: any, current: any) => {
        return sum + current.amount_to_pay
      },
      0,
    )
    console.log('Sum of values: ' + this.totalSumInvoice)
    this.checkCart();
  }

  inputInvoiceAmount(e: any, item: any) {
    this.valueString = e.target.value || 0
    this.inputValue = parseFloat(this.valueString)

    //This limits what can be input in "amount to pay"
    //if the value is below 0 it sets the value to 0
    if (item.paymentValue < 0 || item.paymentValue == '') item.paymentValue = 0
    //if the value is above the invoice amount it sets the value to the invoice amount
    if (item.paymentValue > item.amount) {
      //for front end

      item.paymentValue = item.amount
      //for adding to array
      this.inputValue = item.amount
    }

    //if inputted value is above 0
    if (item.paymentValue > 0) {
      // const index = this.payAmountArray.findIndex(
      //   (amount: any) => amount.invoice_id === item.invoiceid,
      // )

      //This disables onmousedown
      //so that the user cannot click on input field when there is a value inside
      //onmousedown = (e) => e.preventDefault()

      console.log('item.paymentValue was above 0 in value')

      item.isChecked = !item.isChecked
      item.inputValue = this.inputValue

      //Array for saving all the values of each invoice selected
      //validate invoiceid

      if (this.payAmountArray.length == 0) {
        this.payAmountArray.push({
          invoice_id: item.invoiceid,
          invoice_amount: item.amount,
          amount_to_pay: item.inputValue,
          invoice_num: item.invoicenumber,
          is_checked: item.isChecked,
        })

        console.log('the payAmountArray had no value inside')

        this.paymentService.is_checked = item.isChecked
        this.paymentService.amount_to_pay = item.paymentValue

        var index = this.allData.findIndex((i: any) => {
          return i.invoiceid === item.invoiceid
        })

        //search for the specific element that is checked in allData
        //before giving it this value otherwise all rows will be true
        this.allData[index].isChecked = this.paymentService.is_checked
        this.allData[index].paymentValue = this.paymentService.amount_to_pay
      } else {
        // var indexFound = this.payAmountArray.findIndex((id: any) => {
        //   console.log('Array time')
        //   console.log((id.invoice_id = item.invoiceid))
        //   return (id.invoice_id = item.invoiceid)
        // })
        // console.log(indexFound)

        // var includedID = this.payAmountArray.includes(item)

        if (
          !this.payAmountArray.some(
            (id: any) => id.invoice_id === item.invoiceid,
          )
        ) {
          console.log('mission success')
          this.payAmountArray.push({
            invoice_id: item.invoiceid,
            invoice_amount: item.amount,
            amount_to_pay: item.inputValue,
            invoice_num: item.invoicenumber,
            is_checked: item.isChecked,
          })

          console.log(
            'the payAmountArray has values in it but is it the chosen invoice?',
          )

          this.paymentService.is_checked = item.isChecked
          this.paymentService.amount_to_pay = item.paymentValue

          var index = this.allData.findIndex((i: any) => {
            return i.invoiceid === item.invoiceid
          })

          //search for the specific element that is checked in allData
          //before giving it this value otherwise all rows will be true
          this.allData[index].isChecked = this.paymentService.is_checked
          this.allData[index].paymentValue = this.paymentService.amount_to_pay
        } else {
          //this.totalSumInvoice -= item.inputValue
        }
      }

      //Gives the total sum of all the items in the array
      this.totalSumInvoice = this.payAmountArray.reduce(
        (sum: any, current: any) => {
          return sum + current.amount_to_pay
        },
        0,
      )
      console.log('Sum of values: ' + this.totalSumInvoice)
    }
    if (item.paymentValue <= 0) {
      //if unchecked substract inputted value
      console.log('In the bottom else')
      item.isChecked = false

      if (this.totalSumInvoice != 0 || this.totalSumInvoice) {
        console.log('Substracting...')
        this.totalSumInvoice -= item.inputValue
      }
      console.log(this.totalSumInvoice)
      console.log('We at the bottom of the bottom else')
    }
  }

  InvoiceData() {
    this.paymentService.getInvoice().subscribe({
      next: (data: any) => {
        //console.log('data nueva')
        //console.log(data)
        data.forEach((element: any) => {
          let invoice = new Invoice();
          invoice.Customer = element.customername;
          invoice.amount = element.amount;
          invoice.companyid = element.companyid;
          invoice.companyname = element.companyname
          invoice.customerid = element.customerid;
          invoice.dateid = element.dateid;
          invoice.dateupdated = element.dateupdated;
          invoice.id = element.id;
          invoice.invcompid = element.invcompid;
          invoice.invoicedate = element.invoicedate;
          invoice.invoiceid = element.invoiceid;
          invoice.invoicekey = element.invoicekey;
          invoice.invoicenumber = element.invoicenumber;
          invoice.ispayed = element.ispayed;
          invoice.status = element.status;
          invoice.amountToPay = 0;

          this.dataToShow.push(invoice);
        });
        this.allData = data
        if (this.allData.length > 0) {
          this.dataAvailable = true
          this.dataUnavailable = false
        } else {
          this.dataAvailable = false
          this.dataUnavailable = true
        }
        this.refreshInvoices()
        this.cartCount()
      },
      error: (error) => {
        //This runs when the "isAuth" middleware returns 400 bad request as a result of session being invalid
        console.log('Error: ')
        console.log(error)
        //return this.router.navigate(['/login'])
      },
    })
  }

  filterInvoice() {
    this.paymentService.getInvoice().subscribe({
      next: (data) => {
        this.allData = data

        if (this.allData.length > 0) {
          this.dataAvailable = true
          this.dataUnavailable = false
        } else {
          this.dataAvailable = false
          this.dataUnavailable = true
        }

        this.refreshInvoices()
        this.cartCount()

      },
      error: (error) => {
        //This runs when the "isAuth" middleware returns 400 bad request as a result of session being invalid
        console.log('Error: ')
        console.log(error)
        //return this.router.navigate(['/login'])
      },
    })
  }

  setActive(id: any) {
    //console.log(id + ' idddd')
    //document.getElementById(id).setAttribute('class', 'active');
  }

  sendInvoiceList() {
    //console.log('IT WAS CLICKED SEND IT!!!!')
    this.paymentService.setInvoiceListData(this.payAmountArray)
    this.paymentService.uploadListOfInvoices(this.payAmountArray).subscribe({
      next: (data) => {
        console.log(data)
        this.router.navigate(['/stripe'])
      },
      error: (error) => {
        console.log('Error: ')
        console.log(error)
      },
    })
  }

  inputOnChange(item: any) {
    if (item.amountToPay > item.amount) { item.amountToPay = item.amount }

    if (this.totalSumInvoiceTwo.length > 0) {
      for (let i = 0; i < this.totalSumInvoiceTwo.length; i++) {
        if (this.totalSumInvoiceTwo[i].id === item.id) {
          this.totalSumInvoiceTwo.splice(i, 1);
        }
      }

      this.totalSumInvoiceTwo.push({ id: item.id, amountToPay: item.amountToPay });
    } else {
      this.totalSumInvoiceTwo.push({ id: item.id, amountToPay: item.amountToPay });
    }

    this.totalSumInvoice = this.totalSumInvoiceTwo.reduce(
      (sum: any, current: any) => {
        return sum + current.amountToPay
      },
      0,
    )
   // console.log('Sum of values: ' + this.totalSumInvoice)
  }

  inputAmountToPayValidation(item: any) {
    let langVery = sessionStorage.getItem('lang');
    if (item.amountToPay > 0 && item.amount > 0) {
      if (item.amountToPay > item.amount) {
        Swal.fire({
          position: 'center',
          icon: 'warning',
          title: langVery == 'es' ? 'La cantidad a pagar ingresada no puede ser mayor a ' + item.amount : 'The amount to pay entered cannot be greater than ' + item.amount,
          showConfirmButton: true,
        });
        //this.totalSumInvoice = item.amount
        item.amountToPay = item.amount;
        item.pendingAmount = 0;
      } else {
        item.pendingAmount = (parseFloat(item.amount) - parseFloat(item.amountToPay)).toFixed(2);
      }
    }
  }

  AddToCart(item: any) {
    let langVery = sessionStorage.getItem('lang');
    let PayMount = this.PayMount.nativeElement.value
    //console.log('PayMount ' + item.amountToPay);
    if (!item.amountToPay || item.amountToPay == '' || item.amountToPay == '0') {
      Swal.fire({
        position: 'center',
        icon: 'warning',
        title: langVery == 'es' ? 'El campo cantidad a pagar debe estar lleno.' : 'The amount to pay field must be filled.',
        showConfirmButton: true,
        //timer: 3000
      });
      return;
    }
    //save into invoiceListToPay
    console.log(item)
    let sendToCart = new InvoiceCart();
    sendToCart.amounttopay = item.amountToPay;
    sendToCart.customerid = item.customerid;
    sendToCart.invoiceamount = item.amount;
    sendToCart.invoicedate = item.invoicedate;
    sendToCart.invoiceid = item.InvoiceID;
    sendToCart.invoicekey = item.InvoiceKey;
    sendToCart.invoicenumber = item.invoicenumber;
    sendToCart.userid = this.userId;
    sendToCart.companyid = item.companyid;
    console.log(sendToCart)

    this.createCartItem(sendToCart);
    this.cartCount()

    this.dataToShow = this.dataToShow.filter(element => element.id !== item.id);
    this.checkCart();
    this.list_length = true;
  }

  checkCart() {
    if (this.payAmountArray.length > 0) {
      this.isDisabled = false;
    } else {
      this.isDisabled = true;
    }
  }

  Logout() {
    //This is missing a method to destroy session
    return this.router.navigate(['/login'])
  }

  getUserCompany() {
    this.paymentService.getCompaniesFromUser().subscribe({
      next: (data) => {
        console.log('getuserDataCompanies')
        console.log(data)
        this.thisdata = data
        this.selectedcompanies = this.thisdata.map((userComp: any) => {
          return {
            companyid: userComp.companyid,
            telefono: userComp.phonenumber,
            customernumber: userComp.customernumber,
            name: userComp.Company.companyname,
          }
        })
      },
      error: (error) => {
        console.log(error)
        //this.router.navigate(['/login'])
      },
    })
  }

  getCompanies() {
    this.companyService.getCompanies().subscribe({
      //next/error: is the modern way of doing requests
      //if you do the old way ".subscribe((res) => {res})" and try to pass more than one response subscribe will appear depricated.
      next: (data) => {
        //  console.log('Welcome dood!')
        console.log(data)
        this.companies = data
      },
      error: (error) => {
        //This runs when the "isAuth" middleware returns 400 bad request as a result of session being invalid
        console.log('Error: ')
        console.log(error)
        //return this.router.navigate(['/login'])
      },
    })
  }

  getCartItems() {
    let langVery = sessionStorage.getItem('lang');
    console.log(this.userId);
    this.paymentService.getInvoicesFromtheCart(this.userId).subscribe({
      next: (data: any) => {
        console.log(data)
        if (!data || data == '' || data == '[]') {
          Swal.fire({
            position: 'center',
            icon: 'warning',
            title: langVery == 'es' ? 'No tienes productos en el carrito.' : 'You have no items in your shopping cart.',
            showConfirmButton: true,
          })
          return 1;
        } else {
          return this.router.navigate(['/stripe'])
        }

      },
      error: (error) => {
        Swal.fire({
          position: 'center',
          icon: 'error',
          title: langVery == 'es' ? 'Ha ocurrido un error' : 'An error has occurred',
          showConfirmButton: true,
        })
        console.log(error)
      }
    });
  }

  createCartItem(sendToCart: InvoiceCart) {
    let langVery = sessionStorage.getItem('lang');
    this.paymentService.CreateInvoicesIntoTheCart(sendToCart).subscribe({
      next: (data) => {
        this.InvoiceData()
        this.cartCount()
        this.totalSumInvoice = 0
        Swal.fire({
          position: 'center',
          icon: 'success',
          title: langVery == 'es' ? 'Factura agregada al carrito exitosamente' : 'Invoice added to cart successfully',
          showConfirmButton: false,
          timer: 1500
        })

      },
      error: (error) => {
        this.cartCount()
        this.InvoiceData()
        Swal.fire({
          position: 'center',
          icon: 'error',
          title: langVery == 'es' ? 'Ocurrio un error al guardar en el carrito' : 'An error occurred while saving to cart',
          showConfirmButton: true,
        })
        console.log(error)
      }
    })
  }

  exportAsXLSX() {
    this.paymentService.exportAsExcelFile(this.dataToShow, 'Reporte de facturas');
  }
}
