<div class="container">
  <h4 class="h4 text-left">
    {{ 'Edit_Register_Company2' | translate }}
  </h4>
  <div class="row justify-content-center">
    <div class="col-md-6">
      <div class="card">
        <div class="card-body">
          <div>
            <label for="company">{{ 'Company_Selection' | translate }}</label>
            <select #company id="company" name="company" [(ngModel)]="companySelection" class="classic">
              <!--<option *ngIf="NoComp" [ngValue]="null" [disabled]="true">
                {{ 'No_Company' | translate }}
              </option>-->
              <option *ngFor="let comp of availableCompanies" [value]="comp.companyid">
                {{ comp.companyname }}
              </option>
            </select>
          </div>
          <div>
            <label for="Telefono">{{ 'Phone_Number' | translate }}</label>
            <input #Telefono id="telefono" type="text" maxlength="10" class="form-control" name="Telefono"
              [(ngModel)]="phonenumber" />
          </div>
          <div>
            <label for="NumCliente">
              {{ 'Client_Number' | translate }}
            </label>
            <input #NumCliente id="numcliente" [disabled]="disable" type="text" class="form-control" name="NumCliente"
              [(ngModel)]="numcliente" />
          </div>
          <br>
          <div>
            <button type="button" style="float: right;" class="btn btn-lg btn-primary" (click)="
                addCompany2(company.value, Telefono.value, NumCliente.value)">
              {{ 'Add_Company2' | translate }}
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="col-md-6 justify-content-center">
      <div class="card">
        <div class="card-body">
          <div class="row">
            <div style="height: 300px; overflow-y: auto;">
              <Label for="list" style="font-size: 22px;">
                {{ 'List_Companies_Client2' | translate }}
              </Label>
              <ol class="biglist space_list" id="list">
                <ng-container *ngFor="let comp of userCompaniesList">
                  <li>
                    <ul class="nospace">
                      <li>{{ 'cuentas2' | translate }} {{ comp.name }}</li>
                      <li>
                        {{ 'Phone_Number2' | translate }} {{ comp.telefono }}
                      </li>
                      <li>
                        {{ 'Customer_Number' | translate }}
                        {{ comp.customernumber }}
                      </li>
                    </ul>
                  </li>
                  <br>
                </ng-container>
              </ol>
            </div>
            <div>
              <br />
              <button type="button" *ngIf="enableSave" style="float: right;" class="btn btn-lg btn-success"
                (click)="saveFinalEdit()">
                {{ 'Save' | translate }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>