import { Component, NgModule } from '@angular/core'
import { RouterModule, Routes } from '@angular/router'
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component'
import { InvoiceHistoryComponent } from './invoice-history/invoice-history.component'
import { AdminLayoutComponent } from './layout/admin-layout/admin-layout.component'
import { AuthLayoutComponent } from './layout/auth-layout/auth-layout.component'
import { LoginComponent } from './login/login.component'
import { PayInvoiceHistoryComponent } from './pay-invoice-history/pay-invoice-history.component'
import { PayComponent } from './pay/pay.component'
import { PaymentComponent } from './payment/payment.component'
import { RegisterComponent } from './register/register.component'
import { ResetPasswordComponent } from './reset-password/reset-password.component'
import { CompanyRegisterComponent } from './company-register/company-register.component'
import { PaymentMethodComponent } from './payment-method/payment-method.component'
import { CompanyRegisterLoginComponent } from './company-register-login/company-register-login.component'
import { StripeComponent } from './stripe/stripe/stripe.component'
import { UserEditComponent } from './user-edit/user-edit.component'
import { ReportsComponent } from './reports/reports.component'
import { ReportPaymentComponent } from './report-payment/report-payment.component'
import { ReportAccountComponent } from './report-account/report-account.component'
import { UsersComponent } from './users/users.component'
import { StripeReportComponent } from './stripe-report/stripe-report.component'

const routes: Routes = [
  {
    path: '',
    redirectTo: 'login',
    pathMatch: 'full',
  },
  {
    path: '',
    component: AuthLayoutComponent,
    children: [
      {
        path: 'login',
        component: LoginComponent,
      },
      {
        path: 'register',
        component: RegisterComponent,
      },
      {
        path: 'companyregister',
        ///:id',
        component: CompanyRegisterLoginComponent,
      },
      {
        path: 'forgot',
        component: ForgotPasswordComponent,
      },
      {
        path: 'reset',
        component: ResetPasswordComponent,
      },
    ],
  },
  {
    path: '',
    component: AdminLayoutComponent,
    children: [
      {
        path: 'payment',
        component: PaymentComponent,
      },
      {
        path: 'pay',
        component: PayComponent,
      },
      {
        path: 'history',
        component: InvoiceHistoryComponent,
      },
      {
        path: 'payhistory',
        component: PayInvoiceHistoryComponent,
      },
      {
        path: 'companyregisterapp',
        component: CompanyRegisterComponent,
      },
      {
        path: 'paymentmethod',
        component: PaymentMethodComponent,
      },
      {
        path: 'stripe',
        component: StripeComponent,
      },
      {
        path: 'user-edit',
        component: UserEditComponent,
      },
      {
        path: 'user-admin',
        component: UsersComponent,
      },
      {
        path: 'reports-invoice',
        component: ReportsComponent,
      },
      {
        path: 'reports-payment',
        component: ReportPaymentComponent,
      },
      {
        path: 'reports-account',
        component: ReportAccountComponent,
      },
      {
        path: 'reports-stripe',
        component: StripeReportComponent,
      },
    ],
  },
]

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
