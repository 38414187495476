
export class Invoice {
    Customer: Customer;
    customername: string;
    amount: number;
    companyid: number;
    companyname: string;
    customerid: number;
    dateid: number;
    dateupdated: string;
    id: number;
    invcompid: Company;
    invoicedate: any;
    invoiceid: number;
    invoicekey: number;
    invoicenumber: string;
    ispayed: number;
    status: string;
    amountToPay: number;
    pendingAmount: number;
}

export class Customer {
    customerid: number;
    customername: string;
    customernumber: number;
    companynumber: number;
    customerphonenumber: string;
}

export class Company {
    companyid: number;
    companynumber: number;
    companycode: string;
    companyname: string;
}

export interface ExcelFileOptions {
    sheetName?: string;
    aditionalSheets?: AditionalSheetsDefinition;
}

export interface AditionalSheetsDefinition {
    data: Array<{ json: any[]; sheetName: string }>;
}