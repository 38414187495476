import { Component, ElementRef, OnInit, Sanitizer, ViewChild } from '@angular/core'
import { Router } from '@angular/router'
import { RegisterService } from '../services/register.service'
import { MatSnackBar } from '@angular/material/snack-bar'
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { PaymentService } from '../services/payment.service';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.css'],
})
export class RegisterComponent implements OnInit {

  @ViewChild('First_nameVal') First_nameVal: ElementRef<HTMLInputElement>;
  @ViewChild('Last_nameVal') Last_nameVal: ElementRef<HTMLInputElement>;
  @ViewChild('EmailVal') EmailVal: ElementRef<HTMLInputElement>;
  @ViewChild('PasswordVal') PasswordVal: ElementRef<HTMLInputElement>;
  @ViewChild('confirmpasswordVal') confirmpasswordVal: ElementRef<HTMLInputElement>;


  saveMessage: string = 'Your profile has been saved!'
  saveAction: any = 'X'
  userid: any
  returnedData: any
  useremail: string
  userpassword: string
  show: boolean = false
  showConfirm: boolean = false


  constructor(
    private router: Router,
    private registerService: RegisterService,
    private snackBar: MatSnackBar,
    private iconRegistry: MatIconRegistry,
    sanitizer: DomSanitizer,
    private paymentService: PaymentService,
  ) {
    iconRegistry.addSvgIcon('eye-open', sanitizer.bypassSecurityTrustResourceUrl('/assets/icons/eye.svg'),
    )
    iconRegistry.addSvgIcon('eye-closed', sanitizer.bypassSecurityTrustResourceUrl('/assets/icons/eye-off.svg'),
    )
  }

  ngOnInit(): void {
    this.userid = 0
    this.returnedData = []
  }

  notifySave() {
    let langVery = sessionStorage.getItem('lang');
    this.snackBar.open(langVery == 'es' ? '¡Tu perfil ha sido guardado!' : 'Your profile has been saved!', this.saveAction, {
      duration: 3000,
      panelClass: ['green-snackbar'],
    })
  }

  ShowPassword() {
    this.show = !this.show
  }

  ShowPasswordTwo() {
    this.showConfirm = !this.showConfirm
  }

  alertValidate(messageAlert: string) {
    this.snackBar.open(messageAlert, 'X', {
      duration: 3000,
      panelClass: ['red-snackbar'],
      verticalPosition: 'top', // Allowed values are  'top' | 'bottom'
      horizontalPosition: 'center',
    })
  }

  create(registerForm: any) {
    let langVery = sessionStorage.getItem('lang');
    let first_nameVal = this.First_nameVal.nativeElement.value
    let last_nameVal = this.Last_nameVal.nativeElement.value
    let EmailVal = this.EmailVal.nativeElement.value
    let PasswordVal = this.PasswordVal.nativeElement.value
    let confirmpasswordVal = this.confirmpasswordVal.nativeElement.value
    let validSet, emailRegex = /^[-\w.%+]{1,64}@(?:[A-Z0-9-]{1,63}\.){1,125}[A-Z]{2,63}$/i;

    if (emailRegex.test(EmailVal)) { validSet = 1; } else { validSet = 2; }

    if (first_nameVal.trim().length < 3) {
      this.alertValidate(langVery == 'es' ? 'Nombre debe de tener un mínimo de 3 caracteres' : 'Name must have a minimum of 3 characters')
    } else if (last_nameVal == '' || last_nameVal.length < 3) {
      this.alertValidate(langVery == 'es' ? 'Apellido debe de tener un mínimo de 3 caracteres' : 'Last name must have a minimum of 3 characters')
    } else if (EmailVal == '' || validSet == 2) {
      this.alertValidate(langVery == 'es' ? 'Este correo electrónico es inválido' : 'This email is invalid')
    } else if (PasswordVal != confirmpasswordVal) {
      this.alertValidate(langVery == 'es' ? 'Las contraseñas no coinciden' : 'Passwords do not match')
    } else {

      this.registerService.createUser(registerForm).subscribe({
        next: (data) => {
          this.returnedData = data
          if (this.returnedData.error == 1) {
            this.alertValidate(langVery == 'es' ? 'Este correo ya existe, por favor utilizar otro correo' : 'This email already exists, please use another email')
          } else {
            this.notifySave()
            console.log('IN CREATE')
            console.log(data)
            console.log(this.returnedData.email)
            this.useremail = this.returnedData.email
            this.userpassword = this.returnedData.password

            this.createStripeId(registerForm)
          }
        },
        error: (error) => {
          console.log('Error: ')
          console.log(error)
        },
      })
    }
  }

  createStripeId(formData: any) {
    let UserStripeId = '';
    this.paymentService.createUserStripeTwo(formData).subscribe({
      next: (res: any) => {
        UserStripeId = res.id;
        this.getUser(UserStripeId)
      }, error: (error) => {
        console.log('Error: ');
        console.log(error);
      }
    })
  }

  getUser(UserStripeId: any) {
    this.registerService
      .logAfterRegister(this.useremail, this.userpassword)
      .subscribe({
        next: (res) => {
          console.log('LOG_REGISTER: ')
          console.log(res)
          var token: any = res
          if (token) {
            console.log(res);
            localStorage.setItem('token', token)
            console.log('RES.DATA:')
            console.log(res)
            this.router.navigate(['/companyregister'])
            this.paymentService.saveStripeId(UserStripeId).subscribe();
          } else {
            console.log('You shall not pass!!!')
          }
        },
        error: (error) => {
          console.log('Error: ')
          console.log(error)
        },
      })
  }
}
