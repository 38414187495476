<div class="card bg-gradient-primary">
  <div class="card-body">
    <div class="row justify-content-between align-items-center">
      <div class="col"></div>
      <div class="col-auto">
        <div class="d-flex align-items-center">
          <small class="text-white font-weight-bold mr-3"> Make default </small>
          <div>
            <label class="custom-toggle custom-toggle-white">
              <span class="custom-toggle-slider rounded-circle" data-label-off="No" data-label-on="Yes"></span>
            </label>
          </div>
        </div>
      </div>
    </div>
    <h5>{{ 'Payment Process' | translate }}</h5>
    <div class="row">
      <div class="col-md-6">
        <form class="form-primary" role="form">
          <h5>{{ 'Selected Invoices' | translate }}</h5>
          <div style="overflow: auto; height: 260px">
            <ul class="nospace" *ngFor="let inv of invoicesToPay" style="padding: 0;">
              <li class="card">
                <div class="row">
                  <div class="col-md-5">
                    <label>
                      <strong>{{ "Invoice_Number" | translate }}</strong>
                    </label>
                    <div>{{ inv.invoicenumber }}</div>
                  </div>
                  <div class="col-md-5">
                    <label>
                      <strong>{{ "Amount" | translate }}</strong>
                    </label>
                    <div>{{ inv.amounttopay | currency }}</div>
                  </div>
                  <div class="col-md-2">
                    <button style="background: #bb2d3b;" class="btn btn-block btn-danger" (click)="deleteInvoiceCarty(inv.invoiceid)">
                      <span class="material-icons">delete_outline</span>
                    </button>
                  </div>
                </div>
              </li>
            </ul>
  
          </div>
          <!--<ng-template [ngIf]="checkIfPayment">-->
          <p style="color:red;margin:0;margin-top: 6%;font-size: 13px;">
            {{ 'selectPaymentSt' | translate }}
          </p>
          <button class="btn btn-block" [disabled]="checkIfPaymentTwo" style="margin-top: 5px" (click)="Pay()" type="button">
            {{ 'Total Payment' | translate }}: {{ totalAmount | currency }}
          </button>
        <!--</ng-template>-->
        </form>
      </div>

      <div class="col-md-6">
        <form class="form-primary" role="form">
          <h5>{{ "Tarjetas" | translate }}</h5>
          <div style="overflow: auto; height: auto">
            <ul class="nospace" *ngFor="let cc of creditCards">
              <li class="card">
                <div class="row" style="margin: 0;padding: 5px;">
                  <div class="col-md-2">
                    <img src="../../assets/{{ cc.card.brand }}.png" style="width: 100%;" />
                  </div>
                  <div class="col-md-8">
                    <div>{{ cc.card.brand | uppercase }}</div>
                    <div>**** **** **** {{ cc.card.last4 }}</div>
                    <div>{{ cc.card.exp_month }} / {{ cc.card.exp_year }}</div>
                  </div>
                  <div class="col-md-2">
                    <!--<button style="background: #bb2d3b;" class="btn btn-block btn-danger"
                      (click)="deleteCard(cc.id, cc.customer)">
                      <span class="material-icons">delete_outline</span>
                    </button>-->
                    <input type="checkbox" id="checkBoxC" (change)="checkedState($event, checkBox, checkBox.value)"
                    #checkBox style="width: 100%;height: 65px;" #{{cc.id}} value="{{cc.id}}" />
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </form>
      </div>
    </div>
    <div class="row" style="margin-top:25px;display:none">
      <div class="col-md-6">
        <form #registerForm="ngForm" (ngSubmit)="createCard(registerForm.value)" class="form-primary, checkout"
          autocomplete="off">
          <h5>{{ "Pagar con una Nueva Tarjeta" | translate }}</h5>
          <div class="form-group">
            <label>Email</label>
            <div class="input-group input-group-alternative mb-3">
              <input ngModel #email class="form-control" placeholder="Email" type="text" name="email" />
            </div>
            <label>{{ "Name on card" | translate }}</label>
            <div class="input-group input-group-alternative mb-3">
              <input class="form-control" placeholder="Name on card" type="text" ngModel #cardName name="cardName" />
            </div>
          </div>
          <div class="form-group">
            <label>{{ "Card number" | translate }}</label>
            <div class="input-group input-group-alternative mb-3">
              <input #cardNumber ngModel class="form-control" placeholder="Card number" type="text" name="cardNumber" />
            </div>
          </div>
          <div class="row">
            <div class="col-5">
              <div class="form-group">
                <label>{{ "Expiration Month" | translate }}</label>
                <div class="input-group input-group-alternative mb-3">
                  <input #expMonth ngModel class="form-control" placeholder="MM" type="text" name="expMonth" />
                </div>
              </div>
            </div>
            <div class="col-5">
              <div class="form-group">
                <label>{{ "Expiration Year" | translate }}</label>
                <div class="input-group input-group-alternative mb-3">
                  <input #expYear ngModel class="form-control" placeholder="YYYY" type="text" name="expYear" />
                </div>
              </div>
            </div>
            <div class="col-3">
              <div class="form-group">
                <label>CVC</label>
                <div class="input-group input-group-alternative">
                  <input #cvc ngModel name="cvc" class="form-control" placeholder="CVC" type="text" />
                </div>
              </div>
            </div>
          </div>
          <br />
          <button class="btn btn-block btn-info" type="submit">
            {{ "Save" | translate }}
          </button>
        </form>
      </div>
    </div>
  </div>
</div>