import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { ReportService } from '../services/reports.service';
import { AccountReport } from './dto/account-report.dto';
import Swal from 'sweetalert2';
declare const $: any;

@Component({
  selector: 'app-stripe-report',
  templateUrl: './stripe-report.component.html',
  styleUrls: ['./stripe-report.component.css']
})
export class StripeReportComponent implements OnInit {

  @ViewChild('company') company: ElementRef<HTMLInputElement>;

  listcompany: any = 'Selecciona';
  activeFilter: any = "";
  list: any;
  accountReportModel: AccountReport[] = [];
  dataAvailable: boolean
  dataUnavailable: boolean
  page = 1
  pageSize = 5 //make sure this is set to 10 when done testing
  collectionSize = 0
  dataToShow: any
  allData: any
  account: any
  other: any
  data: any
  tableTable: any
  stripeData: any

  datosTable: any = {
    dom: 'Bfrtip',
    language: {
      url: sessionStorage.getItem('lang') == 'es' ? "//cdn.datatables.net/plug-ins/1.10.15/i18n/Spanish.json" : "//cdn.datatables.net/plug-ins/1.10.15/i18n/English.json",
      searchBuilder: {
        button: sessionStorage.getItem('lang') == 'es' ? 'Filtrar por' : 'Filter by',
        columns: [3],
        add: sessionStorage.getItem('lang') == 'es' ? 'Agregar condición' : 'Add condition',
        condition: sessionStorage.getItem('lang') == 'es' ? 'condición' : 'condition',
        data: sessionStorage.getItem('lang') == 'es' ? 'Filtrar por' : 'Filter by',
        clearAll: sessionStorage.getItem('lang') == 'es' ? 'Reiniciar' : 'Reset',
        delete: sessionStorage.getItem('lang') == 'es' ? 'Eliminar' : 'Delete',
        left: sessionStorage.getItem('lang') == 'es' ? 'Izquierda' : 'Left',
        logicAnd: sessionStorage.getItem('lang') == 'es' ? 'AND' : 'AND',
        logicOr: sessionStorage.getItem('lang') == 'es' ? 'OR' : 'OR',
        value: sessionStorage.getItem('lang') == 'es' ? 'Listado' : 'List',
        title: {
          0: sessionStorage.getItem('lang') == 'es' ? 'Filtrar por cualquier columna' : 'Filter by any column',
          _: sessionStorage.getItem('lang') == 'es' ? 'Filtrar por cualquier columna (%d)' : 'Filter by any column (%d)'
        },
        conditions: {
          date: {
            after: sessionStorage.getItem('lang') == 'es' ? 'Después de' : 'After',
            before: sessionStorage.getItem('lang') == 'es' ? 'Antes de' : 'Before',
            between: sessionStorage.getItem('lang') == 'es' ? 'Entre' : 'Between',
            empty: sessionStorage.getItem('lang') == 'es' ? 'Vacío' : 'Empty',
            equals: sessionStorage.getItem('lang') == 'es' ? 'Igual a' : 'Equals',
            not: sessionStorage.getItem('lang') == 'es' ? 'Diferente de' : 'Not',
            notBetween: sessionStorage.getItem('lang') == 'es' ? 'No entre' : 'Not Between',
            notEmpty: sessionStorage.getItem('lang') == 'es' ? 'No vacío' : 'Not Empty'
          },
          string: {
            contains: sessionStorage.getItem('lang') == 'es' ? 'Contiene' : 'Contains',
            empty: sessionStorage.getItem('lang') == 'es' ? 'Vacío' : 'empty',
            notEmpty: sessionStorage.getItem('lang') == 'es' ? 'No vacío' : 'Not Empty',
            equals: sessionStorage.getItem('lang') == 'es' ? 'Igual a' : 'equals',
            not: sessionStorage.getItem('lang') == 'es' ? 'No' : 'Not',
            endsWith: sessionStorage.getItem('lang') == 'es' ? 'Termina con' : 'Ends with',
            startsWith: sessionStorage.getItem('lang') == 'es' ? 'Comienza con' : 'Starts With'
          },
          moment: {
            before: sessionStorage.getItem('lang') == 'es' ? 'Antes de' : 'Before',
            after: sessionStorage.getItem('lang') == 'es' ? 'Después de' : 'After',
            equals: sessionStorage.getItem('lang') == 'es' ? 'Igual a' : 'equals',
            not: sessionStorage.getItem('lang') == 'es' ? 'No' : 'Not',
            between: sessionStorage.getItem('lang') == 'es' ? 'Entre' : 'Between',
            notBetween: sessionStorage.getItem('lang') == 'es' ? 'No entre' : 'Not Between',
            empty: sessionStorage.getItem('lang') == 'es' ? 'Vacío' : 'empty',
            notEmpty: sessionStorage.getItem('lang') == 'es' ? 'No vacío' : 'Not Empty'
          },
          number: {
            equals: sessionStorage.getItem('lang') == 'es' ? 'Igual a' : 'equals',
            not: sessionStorage.getItem('lang') == 'es' ? 'Diferente de' : 'Not',
            gt: sessionStorage.getItem('lang') == 'es' ? 'Mayor a' : 'Greater than',
            gte: sessionStorage.getItem('lang') == 'es' ? 'Mayor o igual a' : 'Greater than equal to',
            lt: sessionStorage.getItem('lang') == 'es' ? 'Menor que' : 'Smaller than',
            lte: sessionStorage.getItem('lang') == 'es' ? 'Menor o igual a' : 'less than equal to',
            between: sessionStorage.getItem('lang') == 'es' ? 'Entre' : 'Between',
            notBetween: sessionStorage.getItem('lang') == 'es' ? 'No vacío' : 'Not Empty',
            empty: sessionStorage.getItem('lang') == 'es' ? 'Vacío' : 'empty',
            notEmpty: sessionStorage.getItem('lang') == 'es' ? 'No vacío' : 'Not Empty'
          },
        }
      }
    },
    buttons: [
      'searchBuilder',
      {
        extend: 'pdfHtml5',
        messageTop: 'Reporte de pagos',
      },
      {
        extend: 'csv',
        text: 'Excel',
        charset: 'UTF-16LE',
        bom: true
      },
      'copy', 'print'
    ]
  };

  constructor(
    private router: Router,
    private service: ReportService
  ) { }

  ngOnInit(): void {
    let rolUser: any = localStorage.getItem('rol');
    this.data = [];
    if (!localStorage.getItem('token')) {
      this.router.navigate(['/login'])
    } else if (rolUser == 1) {
      this.router.navigate(['/payment'])
    }

    let langVery = sessionStorage.getItem('lang');
    this.listcompany = langVery == 'es' ? 'Seleccionar' : 'Select';
    let setting = this.datosTable
    this.dataStripePayment();

    setTimeout(function () {
      $(function () {
        $('#mytableSpayment').DataTable(setting);
      });
    }, 9000);
  }


  dataStripePayment() {
    this.service.stripeReport().subscribe({
      next: (data: any) => {
        console.log('Stripe Data')
        console.log(data)
        console.log(data.data)
        this.stripeData = data.data
      },
      error: (error) => {
        console.log(error);
      }
    })
  }

  convertDateAndTime(timestamp: any){
    return new Date(timestamp).getTime();
    //console.log(date.getTime())
  }


}
