import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { PaymentService } from '../services/payment.service';
import { CompanyService } from '../services/company.service';
import * as jspdf from 'jspdf';
import { NgbDateStruct, NgbModal } from '@ng-bootstrap/ng-bootstrap';
/*import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
pdfMake.vfs = pdfFonts.pdfMake.vfs;
import htmlToPdfmake from 'html-to-pdfmake';*/
import html2canvas from 'html2canvas';
import Swal from 'sweetalert2';

declare const $: any;

@Component({
  selector: 'app-invoice-history',
  templateUrl: './invoice-history.component.html',
  styleUrls: ['./invoice-history.component.css'],
})
export class InvoiceHistoryComponent implements OnInit {
  dataToShow: any;
  allData: any;
  searchedInvoice: any;
  value: any;
  thisdata: any;
  selectedcompanies: any;
  selectedcompany: any;
  companies: any;
  listcompany: any;
  page = 1;
  pageSize = 10;
  collectionSize = 0;
  msjAlert: string;
  title = 'ExportPDF';
  numberInvoicePrint: string

  constructor(
    private paymentService: PaymentService,
    private router: Router,
    private modalService: NgbModal,
    private companyService: CompanyService
  ) {}

  ngOnInit(): void {
    console.log('Entra');
    let langVery = sessionStorage.getItem('lang');
    this.msjAlert =
      langVery == 'es'
        ? 'Las facturas aquí reflejadas corresponden a facturas del año 2021 al presente.'
        : 'The invoices reflected here correspond to invoices from the year 2021 to the present.';
    this.getCompanies();
    this.InvoiceData();
    this.refreshInvoices();
    let rolUser: any = localStorage.getItem('rol');
    if (!localStorage.getItem('token')) {
      this.router.navigate(['/login']);
    } else if (rolUser == 2) {
      this.router.navigate(['/reports-invoice']);
    }

    /*Swal.fire({
      icon: "info",
      title:langVery == 'es' ? 'Las facturas aquí reflejadas corresponden a facturas del año 2021 al presente.' : 'The invoices reflected here correspond to invoices from the year 2021 to the present.',
      //text: 'El pago realizado se reflejará al próximo día.',
      confirmButtonText: 'OK',
    }).then((result) => {
      if (result.isConfirmed) {}
    });*/
  }

  openLg(content: any, size = 'lg') {
    this.modalService.open(content, { size: 'lg' });
  }

  refreshInvoices() {
    this.dataToShow = this.allData
      .map((inv: any, i: any) => ({
        id: i + 1,
        ...inv,
      }))
      .slice(
        (this.page - 1) * this.pageSize,
        (this.page - 1) * this.pageSize + this.pageSize
      );

    this.collectionSize = this.allData.length;
  }

  searchInvoice(formdata: any) {
    console.log('Formdata:');
    console.log(formdata);
    var company = JSON.parse(formdata.company);
    this.paymentService
      .searchInvoice(company.companyid, formdata.fromdate, formdata.todate)
      .subscribe({
        next: (res) => {
          this.allData = res;
          this.refreshInvoices();
        },
        error: (error) => {
          console.log('Error: ');
          console.log(error);
          //return this.router.navigate(['/login'])
        },
      });
  }

  InvoiceData() {
    this.paymentService.getInvoiceAll().subscribe({
      next: (data) => {
        //debugger
        this.allData = data;
        console.log(
          'Something something InvoiceData - invoiceHistory.component!'
        );
        console.log(data);
        console.log(this.allData);
        this.dataToShow = data;
        this.refreshInvoices();
      },
      error: (error) => {
        console.log('Error: ');
        console.log(error);
      },
    });
  }

  enviarData(data: any) {
    //console.log(data)
    this.paymentService.getDataDetailInvoice(data.InvoiceID).subscribe({
      next: (response) => {
        console.log(response);
        localStorage.setItem('info', JSON.stringify(response));
        window.open('../../assets/invoice.html?id=' + data.InvoiceID);
        //window.location.href = "../../assets/invoice.html?id="+data.InvoiceID;
      },
      error: (error) => {
        console.log(error);
      },
    });
  }

  public convetToPDF() {
    var data = document.getElementById('imprimirTodo');
    //var width = document.getElementById('uploadDocument').offsetWidth;
    //var data: any = "../../assets/invoice.html?id="+item.InvoiceID
    html2canvas(data).then((canvas) => {
      var imgWidth = 208;
      var imgHeight = (canvas.height * imgWidth) / canvas.width;
      const contentDataURL = canvas.toDataURL('image/png');
      let pdf = new jspdf.jsPDF('p', 'mm', 'a4');
      var position = 5;
      pdf.addImage(contentDataURL, 'PNG', 5, position, imgWidth - 7, imgHeight);
      pdf.save(`invoice-${this.numberInvoicePrint}.pdf`);
    });
  }

  setActive(id: any) {
    console.log(id + ' idddd');
    //document.getElementById(id).setAttribute('class', 'active');
  }

  Logout() {
    //This is missing a method to destroy session
    return this.router.navigate(['/login']);
  }

  getUserCompany() {
    this.paymentService.getCompaniesFromUser().subscribe({
      next: (data) => {
        console.log('getuserDataCompanies');
        console.log(data);
        this.thisdata = data;
        this.selectedcompanies = this.thisdata.map((userComp: any) => {
          return {
            companyid: userComp.companyid,
            telefono: userComp.phonenumber,
            customernumber: userComp.customernumber,
            name: userComp.Company.companyname,
          };
        });
      },
      error: (error) => {
        console.log(error);
        //this.router.navigate(['/login'])
      },
    });
  }

  getCompanies() {
    this.companyService.getCompanies().subscribe({
      //next/error: is the modern way of doing requests
      //if you do the old way ".subscribe((res) => {res})" and try to pass more than one response subscribe will appear depricated.
      next: (data) => {
        //  console.log('Welcome dood!')
        console.log(data);
        this.companies = data;
      },
      error: (error) => {
        //This runs when the "isAuth" middleware returns 400 bad request as a result of session being invalid
        console.log('Error: ');
        console.log(error);
        //return this.router.navigate(['/login'])
      },
    });
  }

  exportAsXLSX() {
    this.paymentService.exportAsExcelFile(
      this.dataToShow,
      'Reporte de facturas'
    );
  }

  readData(data: any) {
    this.paymentService.getDataDetailInvoice(data.InvoiceID).subscribe({
      next: (response) => {
        this.numberInvoicePrint = data.invoicenumber
        //console.log(response);
        this.invoiceData(response)
      },
      error: (error) => {
        console.log(error);
      },
    });
  }

  printDiv(divName: any) {
    var printContents = document.getElementById(divName).innerHTML;
    var originalContents = document.body.innerHTML;

    document.body.innerHTML = printContents;

    window.print();

    document.body.innerHTML = originalContents;
}

  invoiceData(item: any) {

    let formatter = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
    });

    //let object = localStorage.getItem('info');
    let parse = item
    console.log(parse);
    console.log(parse[0]);

    if (parse[0] == undefined) {
      Swal.fire({
        icon: 'error',
        allowOutsideClick: false,
        allowEscapeKey: false,
        title: 'Oops...',
        text: 'No hay registro en esta factura para ver',
        footer: '<a href="#">Conwaste</a>',
        confirmButtonText: 'OK',
        backdrop: `rgba(0,0,0,0.9)`,
      }).then((result) => {
        if (result.isConfirmed) {
          window.close();
        }
      });
    }

    let html = '', html2 = '';
    let count = 0;
    for (let item of parse) {
      count++;
      html += `<tr>
      <td>${new Date(item.fecha).toLocaleDateString('es-US', {
        month: 'short',
        day: 'numeric',
        timeZone: 'UTC',
      })}</td>
      <td style="font-size:12px">
      ${item.SiteName}
      ${item.InvoiceDescriptionLine}
      ${item.sitebillto1}
      </td>
      <td>${item.ReferenceNumber == null ? '' : item.ReferenceNumber}</td>
      <td>${formatter.format(item.Price)}</td>
      <td>${item.Quantity}</td>
      <td>${formatter.format(item.Amount)}</td>
      </tr>`;
    }

    //$('#page-number').html()
    $('.listTable').html(html);
    $('.listTable2').html(html2);
    //Numero de factura
    $('.noInvoice').html(parse[0].InvoiceNumber);
    //fecha de factura
    let fechaObject = parse[0].InvoiceDate;
    $('.dateInvoice').html(
      new Date(fechaObject).toLocaleDateString('es-us', {
        year: 'numeric',
        month: 'short',
        day: 'numeric',
        timeZone: 'UTC',
      })
    );
    //fecha de factura
    $('.dateInvoiceYear').html(
      new Date(fechaObject).toLocaleDateString('es-us', {
        year: 'numeric',
        month: 'long',
        timeZone: 'UTC',
      })
    );
    // Numero de cliente
    $('.CustomerNumberInvoice').html(parse[0].CustomerNumber);
    $('.SiteNumberSet').html(parse[0].SiteNumber);
    // Custoemr Name
    $('.nameCustomer').html('Customer: '+parse[0].CustomerName)
    // Status de la factura
    let paidOne = parse[0].status.trim()
    let paidTwo = parse[0].status_dbo.trim()
    let paid = paidOne == 'PAID' || paidTwo == 'PAID' ? 'PAID' : 'OPEN'
    $('.payOrPending').html(paid);
    // Contractor de la factura
    $('.contractor').html(parse[0].ContractNumber);
    // Numero de telefono
    $('.numeroTelefono').html(parse[0].phone);
    // Subtotal Factura
    $('.subTotalFactura').html(formatter.format(parse[0].SubTotal));
    // ivu Municipal
    $('.ivuMunicipal').html(formatter.format(parse[0].MunicipalIVU));
    // ivu Estatal
    $('.ivuEstatal').html(formatter.format(parse[0].StateIVU));
    // ivu Total
    $('.ivuTotal').html(formatter.format(parse[0].TotalIVU));
    // Total Amount
    $('.totalAmount').html(formatter.format(parse[0].TotalAmount));
    // Bill To
    $('.billToAdress').html(
      parse[0].billto1 + ', ' + parse[0].billto2 + ', ' + parse[0].billto3
    );
    //$('.billToAdress2').html(parse[0].billto2)
    //$('.billToAdress3').html(parse[0].billto3)
    // STEP 1
    $('.corriente').html('0.00');
    // STEP 2
    $('.step1').html(parse[0]['31-60'] == null ? '0.00' : parse[0]['31-60']);
    // STEP 3
    $('.step2').html(parse[0]['61-90'] == null ? '0.00' : parse[0]['61-90']);
    // STEP 4
    $('.step3').html(parse[0]['91-120'] == null ? '0.00' : parse[0]['91-120']);
  }
}
