
export class InvoiceCart {
    invoicenumber: string;
    amounttopay: number;
    customerid: number;
    invoiceamount: number;
    invoicedate: Date;
    invoiceid: number;
    invoicekey: number;
    userid: number;
    companyid: number;
}