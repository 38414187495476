<div class="container">
  <div class="row justify-content-center">
    <!--<h1 class="h1 text-primary text-center">{{ 'Login' | translate }}</h1>
    <h5 class="h5 text-center">
      {{ 'Please_sign_in_to_account_to_continue' | translate }}
    </h5>-->
    <h1 class="h1 text-primary text-center">
      <img src="../../assets/logo_conwaste_new.png" class="card-img-top" style="width: 300px !important;"alt=""/>
    </h1>
    <br />
    <br />
    <br />
    <div class="col"></div>
    <div class="col justify-content-center">
      <div class="card" style="min-width: 330px;">
        <!--<div class="card-header text-center">
          <img
            src="../../assets/logo_conwaste_new.png"
            class="card-img-top"
            style="width: 300px !important;"
            alt=""
          />
        </div>-->
        <div class="card-body">
          <form class="form" action="https://localhost:4200/register" method="POST" autocomplete="on">
            <label *ngIf="loginFail" style="color: crimson;">
              {{'Incorrect email or password' | translate }}
            </label>
            <div class="form-group">
              <label for="Title">{{ 'E-mail' | translate }}</label>
              <div class="input-group">
                <span class="input-group-text" id="basic-addon5">
                  <i class="bi bi-envelope-fill"></i>
                </span>
                <input
                  type="email"
                  class="form-control"
                  name="useremail"
                  [(ngModel)]="useremail"
                />
              </div>
            </div>
            
            <div class="form-group">
              <label for="Title">{{ 'Password' | translate }}</label>
              <div class="input-group">
                <span class="input-group-text" id="basic-addon6">
                  <i class="bi bi-lock-fill"></i>
                </span>

                <input
                  [type]="show ? 'text' : 'password'"
                  class="form-control"
                  name="userpassword"
                  [(ngModel)]="userpassword"
                />
                <span class="input-group-text" id="basic-addon6">
                  <mat-icon
                    type="button"
                    svgIcon="{{ show ? 'eye-open' : 'eye-closed' }}"
                    (click)="ShowPassword()"
                  ></mat-icon>
                </span>
              </div>
              <a href="/forgot">{{ 'Forgot_your_password' | translate }}</a>
            </div>
            <div class="form-check">
              <br />
              <input
                class="form-check-input"
                type="checkbox"
                id="flexCheckIndeterminate"
              />
              <label class="form-check-label" for="flexCheckIndeterminate">
                {{ 'Remember_me' | translate }}
              </label>
            </div>
            <!--Other fields-->
            <div class="text-center">
              <br />
              <!-- <button type="button" (click)="getHello()">B</button> -->
              <label>{{ content }}</label>
              <input
                type="button"
                (click)="SignIn()"
                class="btn btn-r btn-lg btn-primary"
                value="{{ 'Conectame' | translate }}"
              />
              <br />
              <br />
              {{ 'Not_a_member_yet' | translate }}
              <a href="/register" class="text">
                <strong>{{ 'Register' | translate }}</strong>
              </a>
            </div>
          </form>
        </div>
      </div>
    </div>
    <div class="col"></div>
  </div>
</div>
