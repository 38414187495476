<div class="container">
    <div class="row justify-content-center">
        <h5 class="h5 text-left">{{"Pay_my_invoice" | translate}}</h5>
        <br>
        <br>
        <div class="col">
            <div class="card">
                <div class="card-header">
                    <p class="h6">{{"Payment_Process" | translate}}</p>
                </div>
                <div class="card-body">
                    <div class="row">
                        <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4">
                            <p class="h6">{{"Selected_Invoices"| translate}}</p>
                            <div class="card">
                                <div class="card-body">
                                    <div class="row">
                                        <div class="col-xs-10 col-sm-10 col-md-10 col-lg-10">
                                            <strong>{{"Invoice_Number"| translate}}</strong>
                                            <br>
                                            #8828555
                                            <div class="row">
                                                <div class="col">
                                                    <strong>{{"Amount" | translate}}</strong>
                                                    <br>
                                                    $7200.22
                                                </div>
                                                <div class="col">
                                                    <strong>{{"Amount_to_pay"| translate}}</strong>
                                                    $7200.22
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-xs-2 col-sm-2 col-md-2 col-lg-2">
                                            <h1 class="h1 mt-4">
                                                <i class="bi bi-check-lg text-success"></i>
                                            </h1>
                                        </div>
                                    </div>
                                    <hr>

                                    <div class="row">
                                        <div class="col-xs-10 col-sm-10 col-md-10 col-lg-10">
                                            <strong>{{"Invoice_Number"| translate}}</strong>
                                            <br>
                                            #8828555
                                            <div class="row">
                                                <div class="col">
                                                    <strong>{{"Amount" | translate}}</strong>
                                                    <br>
                                                    $7200.22
                                                </div>
                                                <div class="col">
                                                    <strong>{{"Amount_to_pay" | translate}}</strong>
                                                    $7200.22
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-xs-2 col-sm-2 col-md-2 col-lg-2">
                                            <h1 class="h1 mt-4">
                                                <i class="bi bi-check-lg text-success"></i>
                                            </h1>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="d-grid pt-4 gap-2 text-center">
                                <span class="h4">
                                    {{"Total_Payment" | translate}} <strong>$7,200.22</strong>
                                </span>
                            </div>
                        </div>
                        <div class="col-xs-12 col-sm-12 col-md-8 col-lg-8">
                            <form action="#" method="post">
                                <div class="form-group">
                                    <label for="Title">{{"Card_Name"| translate}}</label>
                                    <div class="input-group">
                                        <span class="input-group-text" id="basic-addon5">
                                            <i class="bi bi-credit-card-fill"></i>
                                        </span>
                                        <input type="text" class="form-control" name="cardName">
                                    </div>
                                </div>
                                <div class="form-group">
                                    <label for="Title">{{"Card_Number" | translate}}</label>
                                    <div class="input-group">
                                        <span class="input-group-text" id="basic-addon5">
                                            <i class="bi bi-hash"></i>
                                        </span>
                                        <input type="numbre" class="form-control" name="cardNumber">
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col">
                                        <div class="form-group">
                                            <label for="Title">{{"Exp_date" | translate}}</label>
                                            <div class="input-group">
                                                <span class="input-group-text" id="basic-addon5">
                                                    <i class="bi bi-calendar-minus-fill"></i>
                                                </span>
                                                <input type="month" min="" class="form-control" name="Exp">
                                            </div>
                                        </div>

                                    </div>
                                    <div class="col">
                                        <div class="form-group">
                                            <label for="Title">CVC</label>
                                            <div class="input-group">
                                                <span class="input-group-text" id="basic-addon5">
                                                    <i class="bi bi-asterisk"></i>
                                                </span>
                                                <input type="number" maxlength="3" class="form-control" name="cvc">
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="d-grid pt-4 gap-2">
                                    <button type="button" class="btn btn-primary btn-lg">{{"Pay" | translate}}</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
            <div class="input-group mt-2 pt-3 justify-content-end">
                <button routerLink="/payment" type="button" class="btn text-primary btn-lg">
                    <i class="bi bi-arrow-left-circle-fill h4"></i>
                    {{"Back" | translate}}
                </button>
            </div>
        </div>
    </div>
</div>