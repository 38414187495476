<div class="container">
    <div class="row justify-content-center">
        <h5 class="h5 text-left">{{ 'adminUser' | translate }}</h5>
        <div class="col-md-7">
            <div class="card">
                <div class="card-body text-center">
                    <div class="table-responsive">
                        <table class="table" id="myTableUsers">
                            <thead>
                                <tr>
                                    <th>ID</th>
                                    <th>{{ 'name' | translate}}</th>
                                    <th>{{ 'Email' | translate}}</th>
                                </tr>
                            </thead>
                            <tbody *ngFor="let item of allData">
                                <tr>
                                    <td>{{ item.userid }}</td>
                                    <td>{{ item.userfirstname }} {{item.userlastname}}</td>
                                    <td>{{ item.useremail }}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-md-5 card">
            <form #registerForm="ngForm" (ngSubmit)="create(registerForm.value)" class="form card-body" autocomplete="off">
                <div class="form-group">
                    <label for="Title">{{ 'First_name' | translate }}</label>
                    <div class="input-group">
                        <span class="input-group-text" id="basic-addon3">
                            <i class="bi bi-person-fill"></i>
                        </span>
                        <input type="text" class="form-control First_nameVal" name="firstname" ngModel #First_nameVal />
                        <input [(ngModel)]='roles' type="hidden" name="roles"  id="roles">
                    </div>
                </div>
                <div class="form-group">
                    <label for="Title">{{ 'Last_name' | translate }}</label>
                    <div class="input-group">
                        <span class="input-group-text" id="basic-addon4">
                            <i class="bi bi-person-fill"></i>
                        </span>
                        <input type="text" class="form-control Last_nameVal" name="lastname" ngModel #Last_nameVal />
                    </div>
                </div>
                <div class="form-group">
                    <label for="Title">{{ 'E-mail' | translate }}</label>
                    <div class="input-group">
                        <span class="input-group-text" id="basic-addon5">
                            <i class="bi bi-envelope-fill"></i>
                        </span>
                        <input type="email" placeholder="someone@example.com" class="form-control" name="email" ngModel
                            #EmailVal />
                    </div>
                </div>
                <div class="form-group">
                    <label for="Title">{{ 'Password' | translate }}</label>
                    <div class="input-group">
                        <span class="input-group-text" id="basic-addon6">
                            <i class="bi bi-lock-fill"></i>
                        </span>
                        <input [type]="show ? 'text' : 'password'" matTooltip="{{ 'toltipPass1' | translate}}
                      {{ 'toltipPass2' | translate}}
                      {{ 'toltipPass3' | translate}}
                      {{ 'toltipPass4' | translate}}
                      {{ 'toltipPass5' | translate}}
                      {{ 'toltipPass6' | translate}}" matTooltipClass="my-tooltip" matTooltipPosition="right"
                            class="form-control" name="password" #PasswordVal ngModel />
                        <span class="input-group-text" id="basic-addon6">
                            <mat-icon type="button" svgIcon="{{ show ? 'eye-open' : 'eye-closed' }}"
                                (click)="ShowPassword()">
                            </mat-icon>
                        </span>
                    </div>
                </div>
                <div class="form-group">
                    <label for="Title">{{ 'Confirm_password' | translate }}</label>
                    <div class="input-group">
                        <span class="input-group-text" id="basic-addon7">
                            <i class="bi bi-lock-fill"></i>
                        </span>
                        <input [type]="showConfirm ? 'text' : 'password'" class="form-control" name="confirmpassword"
                            #confirmpasswordVal ngModel />
                        <span class="input-group-text" id="basic-addon6">
                            <mat-icon type="button" svgIcon="{{ showConfirm ? 'eye-open' : 'eye-closed' }}"
                                (click)="ShowPasswordTwo()"></mat-icon>
                        </span>
                    </div>
                </div>
                <div class="text-center">
                    <br />
                    <input type="submit" class="btn btn-r btn-lg btn-primary" value="{{ 'Register' | translate }}" />
                </div>
            </form>
        </div>
    </div>
</div>