import {
  HttpClient,
  HttpHeaders,
} from '@angular/common/http';
import { Injectable, EventEmitter } from '@angular/core';
import { BasicAutorizationStripe, IP, IPSTRIPE } from '../IP';
import * as XLSX from 'xlsx';
import { ExcelFileOptions } from '../payment/dto/invoice.dto';
import * as FileSaver from 'file-saver'


const EXCEL_TYPE =
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
const EXCEL_EXTENSION = '.xlsx';
@Injectable({
  providedIn: 'root',
})
export class PaymentService {
  //It seems that you must name the "global variable" here
  private apiUrl = IP;
  //variables for reassigning values in table rows when using pagination
  is_checked: boolean = false;
  amount_to_pay: number;
  disable_input: boolean = false;
  listOfSelectedInvoices: EventEmitter<any> = new EventEmitter();

  constructor(private http: HttpClient) {
    //and then declare the type of said variable here
  }

  setInvoiceListData(data: any) {
    this.listOfSelectedInvoices.emit(data);
  }

  uploadListOfInvoices(listinvoicetopay: any) {
    let value;
    console.log('LIST:');
    console.log(listinvoicetopay);

    value = this.http.post(
      this.apiUrl + '/invoice/uploadListOfInvoices',
      {
        invoicelist: listinvoicetopay,
      },
      {
        headers: {
          token: localStorage.getItem('token'),
        },
      }
    );
    return value;
  }

  getUserID() {
    let value;
    value = this.http.get(IP + '/userlog/getUserID', {
      headers: {
        token: localStorage.getItem('token'),
      },
    });
    console.log(value);
    return value;
  }

  getCompaniesFromUser() {
    let value;
    console.log('In getCompaniesFromUser function');
    value = this.http.get(
      this.apiUrl +
      '/company/getCompaniesFromUser/' +
      localStorage.getItem('item'),
      {}
    );

    console.log(value);
    return value;
  }

  saveTableChanges() {
    let value;
    console.log('Storing changes...');
    value = ['hey', 'yo', 'whatup'];
    return value;
  }

  findInvoice(companyid: any, fromdate: any, todate: any) {
    let value;
    console.log('Finding invoice...');
    //let userid = localStorage.getItem('item')
    value = this.http.post(
      IP + '/invoice/searchInvoice',
      {
        companyid: companyid,
        fromdate: fromdate,
        todate: todate,
      },
      {}
    );
    return value;
  }

  getInvoicesToPay() {
    let value;
    value = this.http.get(IP + '/payment/invoicesToPay', {
      headers: {
        token: localStorage.getItem('token'),
      },
    });
    console.log(value);
    return value;
  }

  deleteInvoicesToPay(totalAmount: any, invoiceamount: any, invoicenumber: any, invoiceid: any) {
    let value;
    value = this.http.delete(IP + `/payment/deleteInvoicesToPay/${totalAmount}/${invoiceamount}/${invoicenumber}/${invoiceid}`, {
      headers: {
        token: localStorage.getItem('token'),
      },
    });
    console.log(value);
    return value;
  }

  payInvoicesWithStripeId(amount: any, creditCardId: any, userStripeId: any) {
    let value;
    value = this.http.post(
      IPSTRIPE + '/charges', {
      amount: amount,
      cardStripeId: creditCardId,
      UserStripeId: userStripeId,
    },
      {
        headers: {
          Authorization: BasicAutorizationStripe,
          token: localStorage.getItem('token'),
        },
      }
    );
    console.log(value);
    return value;
  }

  getInvoicesFromtheCart(userId: any) {
    let value = this.http.get(IP + `/cart/getInvoicestoPay?Id=${userId}`, {
      headers: {
        'Content-Type': 'application/json',
        token: localStorage.getItem('token'),
      },
    });
    console.log(value);
    return value;
  }

  CreateInvoicesIntoTheCart(Invoice: any) {
    //debugger
    let value = this.http.post(IP + '/cart/create',
      {
        invoice: Invoice
      },
      {
        headers: {
          token: localStorage.getItem('token'),
        },
      });
    console.log(value);
    return value;
  }

  searchInvoice(companyid: any, fromdate: any, todate: any) {
    let value;
    console.log('Finding invoice...');
    value = this.http.post(
      this.apiUrl + '/invoice/searchInvoice',
      {
        companyid: companyid,
        fromdate: fromdate,
        todate: todate,
      },
      {
        headers: {
          token: localStorage.getItem('token'),
        },
      }
    );
    return value;
  }

  deleteCardHere(id: string, customer: any) {
    let value;
    value = this.http.get(IPSTRIPE + `/deletePaymentMethods/${customer}/${id}`, {
      headers: {
        Authorization: BasicAutorizationStripe,
        token: localStorage.getItem('token'),
      },
    });
    console.log(value);
    return value;
  }

  checkStripeId() {
    let value;
    value = this.http.get(IP + '/payment/checkStripeUser', {
      headers: {
        Authorization: BasicAutorizationStripe,
        token: localStorage.getItem('token'),
      },
    });
    console.log(value);
    return value;
  }

  createUserStripe(userInfo: any) {
    let value;
    value = this.http.post(
      IPSTRIPE + '/crearcustomer',
      { user: userInfo },
      {
        headers: {
          Authorization: BasicAutorizationStripe,
          token: localStorage.getItem('token'),
        },
      }
    );
    console.log(value);
    return value;
  }

  createUserStripeTwo(userInfo: any) {
    let value;
    value = this.http.post(
      IPSTRIPE + '/crearcustomerTwo',
      { user: userInfo },
      {
        headers: {
          Authorization: BasicAutorizationStripe
        },
      }
    );
    console.log(value);
    return value;
  }

  createCard(card: any) {
    let value;
    value = this.http.post(
      IPSTRIPE + '/paymentMethod',
      { card: card },
      {
        headers: {
          Authorization: BasicAutorizationStripe,
          token: localStorage.getItem('token'),
        },
      }
    );
    console.log(value);
    return value;
  }

  getDetailPayment(paymentId: any){
    let send = this.http.get(IP + '/payment/getDetailPayment/'+paymentId,
    {
      headers: {
        token: localStorage.getItem('token'),
      },
    })
    console.log('getDetailPayment '+paymentId)
    console.log(send)
    return send;
  }

  getCardList(stripeid: any) {
    let value;
    value = this.http.get(IPSTRIPE + '/listPaymentMethods/' + stripeid,
      {
        headers: {
          Authorization: BasicAutorizationStripe,
          token: localStorage.getItem('token'),
        }
      });

    console.log(value);
    return value;
  }

  saveStripeId(stripeid: any) {
    console.log('save stripeid ' + stripeid);
    let value;
    value = this.http.post(
      IP + '/payment/saveStripeId',
      { stripeid: stripeid },
      {
        headers: {
          Authorization: BasicAutorizationStripe,
          token: localStorage.getItem('token'),
        },
      }
    );
    console.log(value);
    return value;
  }

  saveStripeIdTwo(stripeid: any) {
    console.log('save stripeid ' + stripeid);
    let value;
    value = this.http.post(
      IP + '/payment/saveStripeId',
      { stripeid: stripeid },
      {
        headers: {
          Authorization: BasicAutorizationStripe,
          token: localStorage.getItem('token'),
        },
      }
    );
    console.log(value);
    return value;
  }

  attachCard(cusStripeId: any, cardStripeId: any) {
    let value;
    value = this.http.post(
      IPSTRIPE + '/paymentMethodsAttach',
      { cardStripeId: cardStripeId, CustomerStripeId: cusStripeId },
      {
        headers: {
          Authorization: BasicAutorizationStripe,
          token: localStorage.getItem('token'),
        },
      }
    );
    console.log(value);
    return value;
  }

  deleteCard(stripeid: any, cardid: any) {
    let value;
    value = this.http.delete(
      IPSTRIPE + '/deletePaymentMethods/' + stripeid + '/' + cardid,
      {
        headers: {
          Authorization: BasicAutorizationStripe,
          token: localStorage.getItem('token'),
        },
      }
    );
    console.log(value);
    return value;
  }

  deleteInvoice(values: any) {
    let value;
    value = this.http.post(IP + '/payment/deleteInvoice',
      { values: values },
      {
        headers: {
          token: localStorage.getItem('token')
        },
      }
    );
    console.log(value);
    return value;
  }

  paymentHistoryInfo() {
    let value;
    console.log('In getInvoice function');
    value = this.http.get(this.apiUrl + '/invoice/getpayments', {
      headers: {
        token: localStorage.getItem('token'),
      },
    });
    console.table(value);
    return value;
  }

  createInvoiceStripe(datos: any, invoicesInfo: any) {
    console.log(datos)
    let value = this.http.post(IP + '/payment/createInvoice',
      { values: datos,
        invoicesInfo: invoicesInfo },
      {
        headers: {
          token: localStorage.getItem('token'),
        },
      })
    return value
  }

  getInvoice() {
    let value;
    console.log('In getInvoice function');
    value = this.http.get(
      //this.apiUrl + '/invoice/getInvoice',
      this.apiUrl + '/invoice/getInvoiceTwo',
      {
        headers: {
          token: localStorage.getItem('token'),
        },
      }
    );

    console.log(value);
    return value;
  }

  cartCount() {
    let value;
    value = this.http.get(
      this.apiUrl + '/cart/cartCount',
      {
        headers: {
          token: localStorage.getItem('token'),
        },
      }
    );
    console.log(value);
    return value;
  }

  getInvoiceAll() {
    let value;
    console.log('In getInvoice function');
    value = this.http.get(
      this.apiUrl + '/invoice/getInvoiceAllTwo',
      {
        headers: {
          token: localStorage.getItem('token'),
        },
      }
    );
    console.log(value);
    return value;
  }

  public exportAsExcelFile(
    json: any[],
    excelFileName: string,
    options?: ExcelFileOptions
  ): void {
    let myworksheet: XLSX.WorkSheet;
    let myworkbook: XLSX.WorkBook = { Sheets: {}, SheetNames: [] };

    if (options) {
      if (options.aditionalSheets) {
        options.aditionalSheets.data.forEach((d) => {
          myworkbook.Sheets[d.sheetName] = XLSX.utils.json_to_sheet(d.json);
          myworkbook.SheetNames.push(d.sheetName);
        });
      } else if (options.sheetName) {
        myworksheet = XLSX.utils.json_to_sheet(json);
        myworkbook = {
          Sheets: { [options.sheetName]: myworksheet },
          SheetNames: [options.sheetName],
        };
      }
    } else {
      myworksheet = XLSX.utils.json_to_sheet(json);
      myworkbook = {
        Sheets: { data: myworksheet },
        SheetNames: ['data'],
      };
    }

    const excelBuffer: any = XLSX.write(myworkbook, {
      bookType: 'xlsx',
      type: 'array',
    });
    this.saveAsExcelFile(excelBuffer, excelFileName);
  }

  private saveAsExcelFile(buffer: any, fileName: string): void {
    const data: Blob = new Blob([buffer], {
      type: EXCEL_TYPE,
    });
    FileSaver.saveAs(data, fileName + '_exported' + EXCEL_EXTENSION);
  }

  httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
  };

  getDataDetailInvoice(id: number) {
    let value;
    value = this.http.get(IP + `/payment/getdetailInvoice/${id}`, {
      headers: {
        token: localStorage.getItem('token'),
      },
    });
    console.log(value);
    return value;
  }
}
