import { Component, ElementRef, OnInit, ViewChild } from '@angular/core'
import { Router } from '@angular/router'
import Swal from 'sweetalert2'
import { ReportService } from '../services/reports.service'
import { PendingInvoiceReport } from './dto/pending-invoice-report.dto';
declare const $: any;

@Component({
  selector: 'app-reports',
  templateUrl: './reports.component.html',
  styleUrls: ['./reports.component.css']
})
export class ReportsComponent implements OnInit {
  listcompany: any = 'Selecciona';
  companies: any;
  pendingInvoiceReportModel: PendingInvoiceReport[] = [];
  activeFilter: string = "";
  list: any[] = [];
  tableTable: any

  datosTable: any = {
    dom: 'Bfrtip',
    language: {
      url: sessionStorage.getItem('lang') == 'es' ? "//cdn.datatables.net/plug-ins/1.10.15/i18n/Spanish.json" : "//cdn.datatables.net/plug-ins/1.10.15/i18n/English.json",
      searchBuilder: {
        button: sessionStorage.getItem('lang') == 'es' ? 'Filtrar por' : 'Filter by',
        columns: [3],
        add: sessionStorage.getItem('lang') == 'es' ? 'Agregar condición' : 'Add condition',
        condition: sessionStorage.getItem('lang') == 'es' ? 'condición' : 'condition',
        data: sessionStorage.getItem('lang') == 'es' ? 'Filtrar por' : 'Filter by',
        clearAll: sessionStorage.getItem('lang') == 'es' ? 'Reiniciar' : 'Reset',
        delete: sessionStorage.getItem('lang') == 'es' ? 'Eliminar' : 'Delete',
        left: sessionStorage.getItem('lang') == 'es' ? 'Izquierda' : 'Left',
        logicAnd: sessionStorage.getItem('lang') == 'es' ? 'AND' : 'AND',
        logicOr: sessionStorage.getItem('lang') == 'es' ? 'OR' : 'OR',
        value: sessionStorage.getItem('lang') == 'es' ? 'Listado' : 'List',
        title: {
          0: sessionStorage.getItem('lang') == 'es' ? 'Filtrar por cualquier columna' : 'Filter by any column',
          _: sessionStorage.getItem('lang') == 'es' ? 'Filtrar por cualquier columna (%d)' : 'Filter by any column (%d)'
        },
        conditions: {
          date: {
            after: sessionStorage.getItem('lang') == 'es' ? 'Después de' : 'After',
            before: sessionStorage.getItem('lang') == 'es' ? 'Antes de' : 'Before',
            between: sessionStorage.getItem('lang') == 'es' ? 'Entre' : 'Between',
            empty: sessionStorage.getItem('lang') == 'es' ? 'Vacío' : 'Empty',
            equals: sessionStorage.getItem('lang') == 'es' ? 'Igual a' : 'Equals',
            not: sessionStorage.getItem('lang') == 'es' ? 'Diferente de' : 'Not',
            notBetween: sessionStorage.getItem('lang') == 'es' ? 'No entre' : 'Not Between',
            notEmpty: sessionStorage.getItem('lang') == 'es' ? 'No vacío' : 'Not Empty'
          },
          string: {
            contains: sessionStorage.getItem('lang') == 'es' ? 'Contiene' : 'Contains',
            empty: sessionStorage.getItem('lang') == 'es' ? 'Vacío' : 'empty',
            notEmpty: sessionStorage.getItem('lang') == 'es' ? 'No vacío' : 'Not Empty',
            equals: sessionStorage.getItem('lang') == 'es' ? 'Igual a' : 'equals',
            not: sessionStorage.getItem('lang') == 'es' ? 'No' : 'Not',
            endsWith: sessionStorage.getItem('lang') == 'es' ? 'Termina con' : 'Ends with',
            startsWith: sessionStorage.getItem('lang') == 'es' ? 'Comienza con' : 'Starts With'
          },
          moment: {
            before: sessionStorage.getItem('lang') == 'es' ? 'Antes de' : 'Before',
            after: sessionStorage.getItem('lang') == 'es' ? 'Después de' : 'After',
            equals: sessionStorage.getItem('lang') == 'es' ? 'Igual a' : 'equals',
            not: sessionStorage.getItem('lang') == 'es' ? 'No' : 'Not',
            between: sessionStorage.getItem('lang') == 'es' ? 'Entre' : 'Between',
            notBetween: sessionStorage.getItem('lang') == 'es' ? 'No entre' : 'Not Between',
            empty: sessionStorage.getItem('lang') == 'es' ? 'Vacío' : 'empty',
            notEmpty: sessionStorage.getItem('lang') == 'es' ? 'No vacío' : 'Not Empty'
          },
          number: {
            equals: sessionStorage.getItem('lang') == 'es' ? 'Igual a' : 'equals',
            not: sessionStorage.getItem('lang') == 'es' ? 'Diferente de' : 'Not',
            gt: sessionStorage.getItem('lang') == 'es' ? 'Mayor a' : 'Greater than',
            gte: sessionStorage.getItem('lang') == 'es' ? 'Mayor o igual a' : 'Greater than equal to',
            lt: sessionStorage.getItem('lang') == 'es' ? 'Menor que' : 'Smaller than',
            lte: sessionStorage.getItem('lang') == 'es' ? 'Menor o igual a' : 'less than equal to',
            between: sessionStorage.getItem('lang') == 'es' ? 'Entre' : 'Between',
            notBetween: sessionStorage.getItem('lang') == 'es' ? 'No vacío' : 'Not Empty',
            empty: sessionStorage.getItem('lang') == 'es' ? 'Vacío' : 'empty',
            notEmpty: sessionStorage.getItem('lang') == 'es' ? 'No vacío' : 'Not Empty'
          },
        }
      }
    },
    buttons: [
      'searchBuilder',
      {
        extend: 'pdfHtml5',
        messageTop: 'Reporte de pagos',
      },
      {
        extend: 'csv',
        text: 'Excel',
        charset: 'UTF-16LE',
        bom: true
      },
      'copy', 'print'
    ]
  };


  constructor(
    private router: Router,
    private service: ReportService
  ) { }

  ngOnInit(): void {
    let rolUser: any = localStorage.getItem('rol');

    if (!localStorage.getItem('token')) {
      this.router.navigate(['/login']);
    } else if (rolUser == 1) {
      this.router.navigate(['/payment']);
    }
    let langVery = sessionStorage.getItem('lang');
    this.listcompany = langVery == 'es' ? 'Seleccionar' : 'Select';
    let setting = this.datosTable
    this.filterByOnChange()
    this.getPendingInvoiceReport();


    setTimeout(function () {
      $(function () {
        $('#mytableInvoice').DataTable(setting);
      });
    }, 2000);
  }

  searchInfoOther() {
    let langVery = sessionStorage.getItem('lang');
    let filterBy = $('#filterBy').val()
    let companyInput = this.listcompany
    let fecha = $('#from').val()
    let setting = this.datosTable
    let [dayFrom, monthFrom, yearFrom] = fecha.split('-');
    let dateFrom = `${yearFrom}-${monthFrom}-${dayFrom}`;
    console.log(this.listcompany)
    console.log('companyInput: ' + companyInput + ' fecha ' + fecha + ' filterBy: ' + filterBy)

    if (companyInput == null && fecha == '') {
      Swal.fire({
        position: 'center',
        icon: 'warning',
        title: langVery == 'es' ? 'Debes seleccionar un filtro para hacer la búsqueda' : 'You must select a filter to search',
        showConfirmButton: true,
      });
    } else if (filterBy == 'CustomerName' && companyInput != null && fecha == '') {
      console.log('1 by CustomerName: ' + companyInput)
      this.clearnDataRefresh()
      setTimeout(function () {
        $(function () {
          $('#mytableInvoice').DataTable(setting).columns(1).search(companyInput).draw();
        });
      }, 100);
    } else if (filterBy == 'CustomerID' && companyInput != null && fecha == '') {
      console.log('2 by CustomerID: ' + companyInput)
      this.clearnDataRefresh()
      setTimeout(function () {
        $(function () {
          $('#mytableInvoice').DataTable(setting).columns(2).search(companyInput).draw();
        });
      }, 100);
    } else if (filterBy == 'InvoiceNumber' && companyInput != null && fecha == '') {
      console.log('3 by InvoiceNumber: ' + companyInput)
      this.clearnDataRefresh()
      setTimeout(function () {
        $(function () {
          $('#mytableInvoice').DataTable(setting).columns(5).search(companyInput).draw();
        });
      }, 100);
    } else if (companyInput == null && fecha != '') {
      this.clearnDataRefresh()
      setTimeout(function () {
        $(function () {
          $('#mytableInvoice').DataTable(setting).columns(3).search(dateFrom).draw();
        });
      }, 900);
    } else if (filterBy == 'CustomerName' && companyInput != null && fecha != '') {
      this.clearnDataRefresh()
      setTimeout(function () {
        $(function () {
          $('#mytableInvoice').DataTable(setting).column(1).search(companyInput).column(3).search(dateFrom).draw();
        })
      }, 100);
    } else if (filterBy == 'CustomerID' && companyInput != null && fecha != '') {
      this.clearnDataRefresh()
      setTimeout(function () {
        $(function () {
          $('#mytableInvoice').DataTable(setting).column(2).search(companyInput).column(3).search(dateFrom).draw();
        })
      }, 100);
    } else if (filterBy == 'InvoiceNumber' && companyInput != null && fecha != '') {
      this.clearnDataRefresh()
      setTimeout(function () {
        $(function () {
          $('#mytableInvoice').DataTable(setting).column(5).search(companyInput).column(3).search(dateFrom).draw();
        })
      }, 100);
    } else {
      $('#mytableInvoice').DataTable(setting).search('').draw();
    }
  }

  clearnDataRefresh() {
    $('#mytableInvoice').DataTable().destroy();
  }
  refreshData() {
    let setting = this.datosTable
    $('#mytableInvoice').DataTable().destroy();
    this.clearnDataRefresh()
    setTimeout(function () {
      $(function () {
        $('#mytableInvoice').DataTable(setting)
      })
    }, 9000);
  }

  getPendingInvoiceReport() {
    this.pendingInvoiceReportModel = []
    this.service.pendingInvoiceReport().subscribe({
      next: (data: any) => {
        for (const element of data) {
          let pendingInvoice = new PendingInvoiceReport();
          pendingInvoice.Amount = element.Amount;
          pendingInvoice.CompanyName = element.CompanyName;
          pendingInvoice.CustomerID = element.CustomerID;
          pendingInvoice.CustomerName = element.CustomerName;
          pendingInvoice.Date = element.Date;
          pendingInvoice.InvoiceNumber = element.InvoiceNumber;
          pendingInvoice.dias_transcurridos = element.dias_transcurridos;
          this.pendingInvoiceReportModel.push(pendingInvoice);
        }

        console.log(this.pendingInvoiceReportModel);
      },
      error: (error) => {
        //debugger
        console.log(error);
      }
    })
  }

  pendingInvoiceReportByDate(formdata: any) {
    let langVery = sessionStorage.getItem('lang');
    console.log(formdata)
    console.log('Date: ' + formdata.todate + '  ' + formdata.fromdate)
    if (formdata.todate == '' || formdata.fromdate == '') {
      Swal.fire({
        position: 'center',
        icon: 'warning',
        title: langVery == 'es' ? 'Debes de seleccionar ambas fechas' : 'You must select both dates',
        showConfirmButton: true,
      });
    } else {
      let timerInterval

      Swal.fire({
        title: langVery == 'es' ? 'Cargando...' : 'charging...',
        html: langVery == 'es' ? 'Cerraré en milisegundos.' : 'I will close in milliseconds.',
        timer: 9000,
        timerProgressBar: true,
        didOpen: () => {
          Swal.showLoading()
        }
      }).then((result) => {
        if (result.dismiss === Swal.DismissReason.timer) {
          console.log('I was closed by the timer')
        }
      })

      this.pendingInvoiceReportModel = []
      this.refreshData();
      this.service.pendingInvoiceReportByDate(formdata.fromdate, formdata.todate, formdata.company).subscribe({
        next: (data: any) => {
          for (const element of data) {
            let pendingInvoice = new PendingInvoiceReport();
            pendingInvoice.Amount = element.Amount;
            pendingInvoice.CompanyName = element.CompanyName;
            pendingInvoice.CustomerID = element.CustomerID;
            pendingInvoice.CustomerName = element.CustomerName;
            pendingInvoice.Date = element.Date;
            pendingInvoice.InvoiceNumber = element.InvoiceNumber;
            pendingInvoice.dias_transcurridos = element.dias_transcurridos;
            this.pendingInvoiceReportModel.push(pendingInvoice);
          }

          console.log(this.pendingInvoiceReportModel);
        },
        error: (error) => {
          //debugger
          console.log(error);
        }
      })
    }
  }

  filterByOnChange() {
    this.activeFilter = 'CustomerName';
    this.service.pendingInvoiceFilterby('CustomerName').subscribe({
      next: (data: any) => {
        console.log(data);
       /* switch (this.activeFilter) {
          case "CustomerName":*/
            this.list = [];
            for (const element of data.data as Array<any>) {
              this.list.push(element.CustomerName);
            }
           /* break;
          case "CustomerID":
            this.list = [];
            for (const element of data.data as Array<any>) {
              this.list.push(element.CustomerID);
            }
            break;
          case "InvoiceNumber":
            this.list = [];
            for (const element of data.data as Array<any>) {
              this.list.push(element.InvoiceNumber);
            }
            break;
          default:
            this.list = [];
            break;
        }*/
        console.log(this.list);
      },
      error: (error) => {
        console.log(error);
      }
    })
  }

  searchInfo() {
    let item = {
      filter: this.activeFilter,
      value: this.listcompany
    };
    this.service.pendingInvoiceReportWithFilter(item).subscribe({
      next: (data: any) => {
        debugger
        this.pendingInvoiceReportModel = [];
        for (const element of data) {
          let pendingInvoice = new PendingInvoiceReport();
          pendingInvoice.Amount = element.Amount;
          pendingInvoice.CompanyName = element.CompanyName;
          pendingInvoice.CustomerID = element.CustomerID;
          pendingInvoice.CustomerName = element.CustomerName;
          pendingInvoice.Date = element.Date;
          pendingInvoice.InvoiceNumber = element.InvoiceNumber;

          this.pendingInvoiceReportModel.push(pendingInvoice);
        }
        console.log(this.pendingInvoiceReportModel);
      },
      error: (error) => {
        console.log(error);
      }
    })
  }
  /*
  SELECT TOP 100 c.CompanyName, cc.CustomerName, i.CustomerID, d.Date, i.InvoiceNumber, i.Amount
  FROM [CONWASTE_PAYMENT_APP].[ConwasteApp].[Invoice] i
  INNER JOIN [CONWASTE_PAYMENT_APP].[ConwasteApp].[Company] c ON c.CompanyID = i.CompanyID
  INNER JOIN [CONWASTE_PAYMENT_APP].[ConwasteApp].[Customer] cc ON cc.CustomerID = i.customerID
  INNER JOIN [CONWASTE_PAYMENT_APP].[ConwasteApp].[Date] d ON d.DateID = i.DateID
  */

}
